import './newCustomerWrapper.scss'
import cs from "classnames";
import commonStyles from "../../../../sass/base/common.module.scss"
import { CpxTitle } from "../../../../../core/components/title/title.component";
import React from "react";
import { CpxSubtitle } from "../../../../../core/components/title/subtitle.component";
import { useTranslations } from "../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../constants/transitions/uiTranslations";
import { useCurrentOrderType, useStep1Data } from "../../../../redux/store";
import { ORDER_TYPE } from "../../../../constants/configs/config.constants";

type Props = {
  title: string;
  subtitle: string;
  children: JSX.Element;
}

export const NfoCustomerWrapperComponent = ({ title, subtitle, children }: Props) => {
  const internalClassName = 'partner-customer-overview';
  const translation = useTranslations(TRANSLATIONS.stepper.newCustomer);
  const currentOrderType = useCurrentOrderType();
  const step1Data = useStep1Data();


  const getTitle = () => {
    switch (currentOrderType) {
      case ORDER_TYPE.ORDER_NEW_CUSTOMER:
        return (!step1Data?.migrationCustomerId ? translation.title() : `${translation.migration()} (${step1Data?.migrationCustomerId})`);
      case ORDER_TYPE.ORDER_NEW_MAIN_CONTRACT:
        return translation.titleAddMainProduct();
      case ORDER_TYPE.ORDER_NEW_CUSTOMER_QUOTE:
        return translation.titleAddCustomerAndQuote();
    }

  }


  return (
    <>
      <CpxTitle
        title={title}
        name={getTitle()}
        internalClassName={internalClassName}
      />
      <CpxSubtitle
        subtitle={subtitle}
        internalClassName={'partnerCustomerOverview'}
      />
      <div className={cs(commonStyles.columnContainer)}>
        <div className={commonStyles.column}>
          { children }
        </div>
      </div>
    </>
  );
};
