// Languages
export const LANGUAGES = {
  DE: 'Deutsch',
  EN: 'English',
};

export const LANGUAGES_ID = {
  DE: 1,
  EN: 2,
};

// Legal Entities
export const DEFAULT_LEGAL_ENTITY = 'nfon';
export const LEGAL_ENTITIES = {
  NFON: 'nfon',
  DTS: 'dts',
  TEF: 'tef'
};

export const CLIENTS_IDS = {
  NFON: 1,
  AUT: 2,
  IT: 3,
  UK: 4,
  FR: 5,
  ES: 6,
  DTS: 7
}

// Country Codes
export const COUNTRY_CODES: any = {
  1: 'DE', // Deutschland
  2: 'BE', // Belgien
  3: 'AT', // Österreich
  4: 'HU', // Ungarn
  7: 'AX', // Åland
  43: 'BG', // Bulgarien
  54: 'DK', // Dänemark
  62: 'EE', // Estland
  63: 'EU', // Europäische Union
  67: 'FI', // Finnland
  68: 'FR', // Frankreich
  69: 'FX', // Frankreich ohne Übersee-Départements
  70: 'GF', // Französisch-Guayana
  79: 'GR', // Griechenland
  81: 'GP', // Guadeloupe
  97: 'IE', // Irland
  98: 'IS', // Island
  100: 'IT', // Italien
  124: 'HR', // Kroatien
  129: 'LV', // Lettland
  133: 'LI', // Liechtenstein
  134: 'LT', // Litauen
  135: 'LU', // Luxemburg
  142: 'MT', // Malta
  145: 'MQ', // Martinique
  153: 'MC', // Monaco
  165: 'NL', // Niederlande
  166: 'AN', // Niederländische Antillen
  172: 'NO', // Norwegen
  184: 'PL', // Polen
  185: 'PT', // Portugal
  189: 'RO', // Rumänien
  196: 'SM', // San Marino
  199: 'SE', // Schweden
  200: 'CH', // Schweiz
  208: 'SK', // Slowakei
  209: 'SI', // Slowenien
  211: 'ES', // Spanien
  236: 'CZ', // Tschechische Republik
  248: 'VA', // Vatikanstadt
  252: 'GB', // Großbritannien (und Nordirland)
  258: 'CY', // Zypern
};

// Country IDs
export const COUNTRY_IDS = {
  DE: 1,
  AT: 3,
  FR: 68,
  GP: 81,
  GY: 87,
  IE: 97,
  IT: 100,
  HR: 124,
  MQ: 145,
  YT: 148,
  NL: 165,
  PL: 184,
  PT: 185,
  RE: 187,
  RO: 189,
  CH: 200,
  SK: 208,
  SI: 209,
  ES: 211,
  GB: 252,
  FH: 265,
}

// Phone Country Codes
export const PHONE_COUNTRY_CODES = {
  "DE": '+49',
}

// Entities
export const ID_DATA_ENTITY = {
  CUSTOMER: 1,
  SERVICE: 6,
  QUOTE: 1620,
};

// Status IDs - Customer
export const ID_STATUS_QUOTES = {
  EDITED: 13,
  REFUSED_BY_CUSTOMER: 4,
  ORDERED: 1,
  NEW: 0,
  OBSOLETE: 9,
  REJECTED_BY_RM: 10,
  READY_FOR_SUBMISSION: 2,
  ORDERENRICHMENT: 5,
  REJECTED_BY_SALES_MANAGEMENT: 11,
  DRAFT: 12,
};

export const ID_STATUS_CUSTOMER = {
  NEW: 0, // Neu
  ACTIVE: 1, // Aktiv
  REJECTED: 2, // Abgelehnt
  TERMINATED: 3, // Storniert
  NOT_CREATABLE: 4, // Nicht herstellbar
  NEW_IN_REALISATION: 5, // Neu, in Realisierung
  CANCELLED: 6, // Gekündigt
  IN_CANCELLATION: 7, // In Kündigung
  BARRING_REQUESTED: 8, // Sperre angefordert
  BARRED: 9, // Gesperrt
  BARRING_REMOVAL_REQUESTED: 10, // Entsperrung angefordert
  PORTED_AWAY: 11, // Wegportiert
  MARKED_FOR_PORTING_AWAY: 12, // Für Wegportierung vorgemerkt
  MARKED_FOR_DELETION: 98, // Zur Löschung vorgemerkt
  MARKED_AS_DELETED: 99, // Als gelöscht markiert
};

// Status IDs - Service
export const ID_STATUS_SERVICES = {
  NOT_ORDERED: -1, // Nicht gebucht
  ORDERED: 0, // Bestellt
  ACTIVE: 1, // Aktiv
  REJECTED: 2, // Abgelehnt
  BARRED: 3, // Gesperrt
  BARRED_INSTALLATION: -3, // Gesperrt
  BARRING_REQUESTED: 4, // Sperre angefordert
  DEACTIVATION_REQUESTED: 5, // Deaktivierung angefordert
  DEACTIVATED: 6, // Deaktiviert
  CANCELLATION_REQUESTED: 7, // Kündigung vorgemerkt
  BARRING_REMOVAL_REQUESTED: 8, // Entsperrung angefordert
  CANCELLED: 9, // Gekündigt
  ACTIVATION_REQUESTED: 10, // Aktivierung angefordert
  SENT: 11, // Versandt
  TERMINATION_REQUESTED: 12, // Storno angefordert
  TERMINATED: 13, // Storniert
  NOT_AVAILABLE: 14, // Nicht verfügbar
  DEFECTIVE: 15, // Defekt
  EXPORTED: 16, // Exportiert
  USED_UP: 17, // Aufgebraucht
  EXPIRED: 100, // Abgelaufen
  CHANGE_REQUESTED: 18, // wechsel vorgemerkt
};

export const ID_PRODUCT_STATUS = {
  NOT_RELEASED: 0,
  VALID: 1,
}

// Partner
export const ID_PARTNER_CONTACTPERSON_TECHNICAL = '122';
export const ID_PARTNER_CONTACTPERSON_PORTAL_USER = '131';
export const ID_PARTNER_CONTACTPERSON_ACTIVE = '1';

export const ID_PARTNER_ROLES = {
  SERVICE_PARTNER: 133,
  SALES_PARTNER: 233,
}

// MyData
export const CUSTOMER_CONTACT_PERSON_ROLE_IDS = {
  TECHNICAL: 113,
  BUSINESS: 114,
  COMMERCIAL: 134
}
export const ID_PAYMENTMODE_SEPA = '1';
export const ID_PAYMENTMODE_BANKTRANSFER = '2';

// main products
export const CENTREXX_PRODUCT_IDS = [70150];
export const NCONNECT_VOICE_PRODUCT_IDS = [
  711000, 511000, 611000, 411000, 311000, 11000, 211000, 811000, 70250
];

export const NCONNECT_VOICE_PRODUCT_SEGMENT_IDS = [
  2
];

export const CLOUDYA_CENTREXX_PRODUCT_SEGMENT_IDS = [
  7,10
];


export const SIP_TRUNK_PRODUCT_IDS = [
  1970000,21970000,2070000,7070000,6070000,8070000,4070000,970000,5070000,3070000,70000
]
export const OPTION_IDS = {
  SIP_TRUNK: 70000,
  TELEPHONE_CONNECTION_NCONNECT: 60000,
  TELEPHONE_CONNECTION_CLOUDYA: 65000,
}

export const TELEPHONE_CONNECTIONS_IDS = [
  // NFON AG
  17706200,60000,65000,
  // AT / PL
  2020071,217706200,8060000,2060000,
  // IT
  3060000,
  // UK
  4060000,
  // FR
  5060000,
  // ES
  6060000,
  // DTS
  706200,7706200
]

export const CALL_NUMBER_BLOCK_IDS = [
  // NFON AG
  80001, 2000, 20000, 20010, 20020, 20040, 20050,
  // AT
  202006, 202000, 202001, 202002, 202003, 202004, 2020070,
  // PL
  8020000, 8020040,
  // IT
  80003, 3020000, 3020010, 3020020, 3020030, 3020040,
  // UK
  80004, 4020000, 4020010, 4020020, 4020030, 4020040,
  // FR
  80005, 5020000, 5020010, 5020020, 5020030, 5020040,
  // ES
  80006, 6020000, 6020010, 6020020, 6020030, 6020040,
  // DTS
  80007, 7702010, 7702011, 7702012, 7702014, 7702018, 7702017, 7702016, 702010, 702011, 702012, 702014, 702016
];

export const PRODUCT_CATEGORY_CALL_NUMBER_BLOCKS = [
  200, // defined block
  201, // migration defined block
  203, // migration individual block
  205,  // individual block
  206, // single number
  202, // migration single number

]

export const SERVICE_TYPE_PORTING = 2000;

export const PRODUCT_CATEGORY_MIGRATION_DEFINED_BLOCK = 201;

export const PRODUCT_CATEGORY_CALL_NUMBER_BLOCKS_MIGRATION = [
  201, // migration defined block
  203, // migration individual block
  205,  // individual block
]

export const PRODUCT_IDS = {
  NCONNECT: 11000,
  CLOUDYA: 11150,
  CENTREXX: 70150,
  SIP_TRUNK_FLEXX_DTS: 70250,
}

export const TEXT_FIELD_MODAL_PRODUCT_CATEGORIES = [90]; // MSTEAMS
export const QUANTITY_MODAL_PRODUCT_CATEGORIES = [100, 101, 320];
export const NUMBERS_MODAL_PRODUCT_CATEGORIES = [200, 201, 203, 205, 206, 202];
export const NUMBERS_PORTING_PRODUCT_CATEGORIES = [400];

// ContractDetails (S_SERVICE_TYPES)
export const ID_MAIN_CONTRACT = '401';
export const ID_CONTRACT_SIP_TRUNK = '402';
export const ID_CONTRACT_OPTION = '410';
export const ID_CONTRACT_CHANNEL_OPTION = '411';
export const ID_CONTRACT_CLIP_OPTION = '413';
export const ID_CONTRACT_MS_TEAMS = '415';
export const ID_CPRODUCT_MS_TEAMS_DIRECT_ROUTING = 90000;
export const ID_CONTRACT_TELEPHONE_CONNECTION = '500';
export const NAME_CONTRACT_NCONNECT_VOICE = 'Nconnect Voice';

// YourPartner
export const ID_CONTACTPERSON_SUPPORT = '115';
export const ID_CONTACTPERSON_CONTRACT = '116';

// SecuritySettings
export const ID_LIMIT_FRAUD = '101168598';
export const ID_LIMIT_SPENDING = '0';

// Extensions Modal
export const MAX_INCREASE = 200;
export const REDUCE_INFO = 'reduceInfo';
export const INCREASE_INFO = 'increaseInfo';

// address
export const ID_ADDRESS_CONNECTION = '126';
export const ID_ADDRESS_BILLING = '103';
export const ID_ADDRESS_MAIN = '102';
export const ID_ADDRESS_SHIPPING = '2';
// Address IDs as numbers
export const ID_ADDRESSES = {
  SHIPPING: 2,
  MAIN: 102,
  BILLING: 103,
  CONNECTION: 126,
}

export const ROLE_PARTNER = 'ROLE_PARTNER';
export const ROLE_CUSTOMER = 'ROLE_CUSTOMER';
export const ROLE_WHOLESALER_EVN = 'ROLE_WHOLESALER_EVN';
export const ROLE_WHOLESALER = 'ROLE_WHOLESALER';
export const ROLE_TELEPHONY_CUSTOMER = 'ROLE_TELEPHONY_CUSTOMER';

export const ROLE_WHOLESALE_MODEL_AIRTIME_RESELLING = 'ROLE_WHOLESALE_MODEL_AIRTIME_RESELLING';
export const ROLE_WHOLESALE_MODEL_TELEPHONY_CONNECTION = 'ROLE_WHOLESALE_MODEL_TELEPHONY_CONNECTION';
export const ROLE_WHOLESALE_MODEL_TRUNK_MODULE = 'ROLE_WHOLESALE_MODEL_TRUNK_MODULE';
export const ROLE_WHOLESALE_MODEL_TRUNK_MODULE_WITH_RATING = 'ROLE_WHOLESALE_MODEL_TRUNK_MODULE_WITH_RATING';
export const ROLE_WHOLESALE_MODEL_II_TIER_PARTNER = 'ROLE_WHOLESALE_MODEL_II_TIER_PARTNER';
export const USER_PARTNER = 'partner';
export const USER_CUSTOMER = 'customer';
export const USER_WHOLESALER_EVN = 'wholesaler_evn';
export const USER_CUSTOMER_TELEPHONY = 'customer_telephony';
export const USER_WHOLESALER = 'wholesaler';

export const ID_VIRTUELLE_TK_ANLAGE = '706100';
export const ID_MOBILITY_OPTION = 706107;
export const ID_EXTENSION_OPTION = 706101;

export const ID_SALUTATIONS = {
  MR: 1,
  MRS: 2,
  COMPANY: 5
}


export const ID_SERVICE_GROUPS = {
  VOICE_CHANNELS: 2,
  CALL_NUMBERS: 3,
  OPTIONS: 7,
}

export const ID_CHARGE_MODES = {
  ONE_TIME: 1,
  MONTHLY: 2,
  USAGE_BASED: 3,
  PER_INVOICE: 4,
};

export const ID_CHARGE_TYPES = {
  BASIC_FEE: 2,
  PROVISIONING_FEE: 4,
};

export const COMMUNICATION_STATUS_ICON_MAPPING = {
  OK: 1,
  OPEN: 2,
};

export const COMMUNICATION_ICON_MAPPING = {
  ONLINE: 1,
  MAIL: 2,
  POST: 3,
  SMS: 4,
  TELEFON: 5,
  INTERN: 6,
  MIGRATION: 7,
  CUSTOMERPORTAL: 8,
  CHAT: 12,
  FAX: 13,
  MAIL_INCOMING: 15,
  SMS_OUTGOING: 16,
};

export const WORKFLOW_STATUS_MAPPING = {
  NEW: 1,
  COMPLETED: 2,
  DECLINED: 3,
  OPEN: 4,
  OBSOLETE: 5,
  TEST_DATA: 6,
};

export const WORKFLOWITEMS = {
  WFI_3001: 3001,
  WFI_2500: 2500,
  WFI_1046: 1046,
  WFI_1005: 1005,
  WFI_1095: 1095,
  WFI_1080: 1080,
  WFI_3007: 3007,
};

export const SERVICE_GROUP = {
  TAKTUNG: 10,
  TARIF: 11,
}

export const SERVICE_PROPERTIES = {
  ANZAHL_RUFNUMMERN: "Anzahl Rufnummern",
}

export const TAKTUNG_MIN_PRODUCT_IDS = [
  //NFON
  61000,
  //NFON Österreich
  8061000,
  2061000,
  //NFON IT
  3061000,
  //NFON UK
  4061000,
  //NFON FR
  5061000,
  //NFON ES
  6061000
]

export const TARIF_3000_PRODUCT_IDS = [
  //NFON
  80000,
  //NFON AT (PL)
  8080000,
  //NFON AT
  2080000,
  //NFON IT
  3080000,
  //NFON UK
  4080000,
  //NFON FR
  5080000,
  //NFON ES
  6080000,
  //DTS
  7080000

]

export const PRODUCT_CATEGORY = {
  telephoneConnection: 600,
  phoneNumber: 206,
  phoneNumberBlock: 200,
  phoneNumberMigration: 202,
}

export const CURRENCY_MAPPING = {
  EUR: "€",
  ZLOTY: "zł",
  POUND: "£",
};

export const ICONS = {
  ADD: 'add',
  ACTIVE_DARK: 'activeDark',
  ACTIVE_WHITE: 'activeDark',
  CLOSE: 'close',
  EDIT: 'edit',
  DELETE: 'delete',
  LINK: 'link',
  SETTINGS: 'settings',
  CONTRACT: 'contract',
  CUSTOMER: 'customer',
  CROSS: 'cross',
  SEARCH: 'search',
  ATTENTION: 'attention',
  ATTENTION_WHITE: 'attentionWhite',
  STATUS: {
    NOT_ORDERED: 'statusNotOrdered',
    ORDERED: 'statusOrdered',
    ORDERED_DARK: 'statusOrderedDark',
    ORDERED_WHITE: 'statusOrderedWhite',
    ACTIVE: 'statusActive',
    CANCELLED: 'statusCancelled',
    TERMINATED: 'statusTerminated',
  },
  SORT_ARROWS: {
    UNSORTED: 'sortArrowUnsorted',
    ASC: 'sortArrowAsc',
    DESC: 'sortArrowDesc',
  },
  CALENDAR: 'calendar',
  PLACE: 'place',
  MINUS: 'minus',
  USER: 'user',
  TECH: 'tech',
  PHONE: 'phone',
  ARROW_UP: 'arrowUp',
  ARROW_DOWN: 'arrowDown',
  NOTE: 'note',
  EYE_OPEN: 'eyeOpen',
  EYE_CLOSED: 'eyeClosed',
  CHECK: 'check',
  TRUCK: 'truck',
  INFO: 'info',
}

export const PROVISIONING_WORKFLOW_ID = 10000;

export const ERROR_NAME_RENEWTOKEN_FAILED = "RENEWTOKEN_FAILED";
export const ERROR_NAME_NO_REFRESH_TOKEN = "NO_REFRESH_TOKEN";

export const LOCALSTORAGE_REFRESHTOKEN = "refreshToken";
export const LOCALSTORAGE_ACCESSTOKEN = "accessToken";
export const LOCALSTORAGE_CLIENTTOKEN = "clientToken";

export const ID_USERNAME = "username";
export const ID_PASSWORD = "password";
export const ID_PASSWORD_CONFIRM = "password-confirm";

// NIPP specific
export const CUSTOMER_QUICKSEARCH_RESULTS_LIMIT = 4;

// Adminportal
export const ADMIN_PORTAL_URL = {
  NFON: {
    DEFAULT: "https://portal.nfon.com/de/login",
    N_CONNECT_VOICE: "https://siptrunk.nfon.com/",
    CLOUDYA: "https://start.cloudya.com",
  },
  DTS: {
    DEFAULT: "https://admin.dt-standard.de/",
    CENTREXX: "https://app.dt-standard.de/auth/login ",
    CENTREXX_SIP: "https://siptrunk.dt-standard.de/auth/login",
  }
}

// Wholesale Models
export const WHOLESALE_MODELS = {
  AIRTIME_RESELLING: 1,
  TRUNK_MODULE: 3,
}

export const HIDE_COMPONENT_FOR_WHOLESALE_MODEL = [
  WHOLESALE_MODELS.AIRTIME_RESELLING,
  WHOLESALE_MODELS.TRUNK_MODULE
]

export const FEATURES_FLAG = {
  PORTAL_ORDER_ENTRY: 'PORTAL_ORDER_ENTRY',
  PORTAL_ORDER_ENTRY_MIGRATION: 'PORTAL_ORDER_ENTRY_MIGRATION',
  PORTAL_ORDER_ENTRY_GENERATOR: 'PORTAL_ORDER_ENTRY_GENERATOR',
  PORTAL_PARTNER_EXTENSIONS_ORDER: 'PORTAL_PARTNER_EXTENSIONS_ORDER',
  PORTAL_CUSTOMER_EXTENSIONS_ORDER: 'PORTAL_CUSTOMER_EXTENSIONS_ORDER',
  PORTAL_PARTNER_CUSTOM_INFO: 'PORTAL_PARTNER_CUSTOM_INFO',
  PORTAL_ACCOUNT_DATA_CHANGE_CUSTOMER: 'PORTAL_ACCOUNT_DATA_CHANGE_CUSTOMER',
  PORTAL_ACCOUNT_DATA_CHANGE_PARTNER: 'PORTAL_ACCOUNT_DATA_CHANGE_PARTNER',
  PORTAL_PARTNER_CUSTOMER_ADMINSTRATION_MENU: 'PORTAL_PARTNER_CUSTOMER_ADMINSTRATION_MENU',
  ENABLE_REQUEST_DATE: 'ENABLE_REQUEST_DATE',
  PORTAL_PRODUCT_CHANGE: 'PORTAL_PRODUCT_CHANGE',
  PORTAL_LICENSE_EDITABLE: 'PORTAL_LICENSE_EDITABLE',
  PORTAL_ORDER_NEW_MAIN_CONTRACT: 'PORTAL_ORDER_NEW_MAIN_CONTRACT',
}

export const USER_FUNCTIONS = {
  PORTAL_CREATE_CUSTOMER: 'PORTAL_CREATE_CUSTOMER',
  PORTAL_MIGRATE_CUSTOMER: 'PORTAL_MIGRATE_CUSTOMER',
  PORTAL_QUOTE_CUSTOMER: 'PORTAL_QUOTE_CUSTOMER',
}

export enum ORDER_TYPE {
  ORDER_NEW_CUSTOMER,
  ORDER_NEW_MAIN_CONTRACT,
  ORDER_NEW_CUSTOMER_QUOTE
}

export const INPUT_MAX_LENGTHS = {
  default: 255,
  customContactPerson: 45,
  /* Customer Data */
  customerData: {
    firstName: 50,
    lastName: 75,
    companyName1: 255,
    companyName2: 255,
    companyNumber: 20,
  },
  /* Address */
  address: {
    firstName: 50,
    lastName: 75,
    companyName1: 255,
    companyName2: 255,
    street: 100,
    houseNumber: 25,
    additionalAddress: 255,
    zip: 25,
    city: 75,
    city2: 75,
    city3: 75,
    district: 75,
    province: 75,
    county: 75,
  },
  /* Contact */
  contact: {
    email: 255,
    countryCode: 4,
    areaCode: 6,
    cli: 50,
  },
  /* Account */
  account: {
    taxIdentificationNumber: 50,
    accountName: 150,
    bic: 25,
    iban: 34,
  },
  /* Contact Persons */
  contactPersons: {
    firstName: 50,
    lastName: 75,
    login: 255,
  },
  callNumber: 10,
  console: 5,
  currentCarrierNonDe: 50,
  contractNotes: 4000,
}

export const DOCUMENT_DROPDOWN_TYPES = {
  CONTRACT: "contract",
  SITE: "site",
  SEPA: "sepa"
}

export const TAKTUNGEN_OPTION_ROLE_ID = 9;

// footer legal notice
export const FOOTER_LEGAL_NOTICE = {
  NFON: {
    IMPRINT: "https://www.nfon.com/legal-notice",
    PRIVACY: "https://www.nfon.com/data-disclaimer",
    AGB_SLA: "https://www.nfon.com/gtc",
  },
  DTS: {
    IMPRINT: "https://www.dt-standard.de/impressum.html",
    PRIVACY: "https://www.dt-standard.de/datenschutzhinweise.html",
    AGB_SLA: "https://www.dt-standard.de/allgemeine-geschaeftsbedingungen.html",
  },
  TEF: {
    IMPRINT: "https://www.telefonica.de/impressum.html",
    PRIVACY: "https://www.telefonica.de/unternehmen/datenschutz.html",
    AGB_SLA: "https://www.telefonica.de/agb.html",
  },
}

export const SELECTED_PARTNER_CUSTOMER_ID = "selectedPartnerCustomerId"

export const MS_TEAMS_DIRECT_ROUTING_SUFFIX = ".msteams-suffix";

export const PRODUCT_CATEGORY_QUANTITY_ID = 320;