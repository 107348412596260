export const TRANSLATIONS = {
  common: {
    cancel: () => ({
      DE: 'Abbrechen',
      EN: 'Cancel',
    }),
    cancelText: () => ({
      DE: 'Sind Sie sicher, dass Sie abbrechen möchten?',
      EN: 'Are you sure you want to cancel?',
    }),
    apply: () => ({
      DE: 'Anwenden',
      EN: 'Apply',
    }),
    confirm: () => ({
      DE: 'Bestätigen',
      EN: 'Confirm',
    }),
    edit: () => ({
      DE: 'Bearbeiten',
      EN: 'Edit',
    }),
    delete: () => ({
      DE: 'Löschen',
      EN: 'Delete',
    }),
    back: () => ({
      DE: 'Zurück',
      EN: 'Back',
    }),
    next: () => ({
      DE: 'Weiter',
      EN: 'Next',
    }),
    close: () => ({
      DE: 'Schließen',
      EN: 'Close',
    }),
    unlock: () => ({
      DE: 'Entsperren',
      EN: 'Unlock',
    }),
    lockedWarningHeading: (contractName: string) => ({
      DE: `Ihr ${contractName} wurde gesperrt.`,
      EN: `Your ${contractName} has been locked.`,
    }),
    inputMaxLengthText: (maxLength: string) => ({
      DE: `Die Eingabe darf nicht länger als ${maxLength} sein.`,
      EN: `The input may not be longer than ${maxLength}.`,
    }),
    lockedWarningHeading2: () => ({
      DE: 'Einige Ihrer Anlagen wurden gesperrt.',
      EN: 'Some of your installations have been locked.',
    }),
    lockedWarning: () => ({
      DE: 'Klicken Sie hier um die Anlagen zu entsperren.',
      EN: 'Click here to unlock your installation.',
    }),
    lockedWarningLink: () => ({
      DE: 'Security Einstellungen',
      EN: 'security settings',
    }),
    add: () => ({
      DE: 'Hinzufügen',
      EN: 'Add',
    }),
    search: () => ({
      DE: 'Suche',
      EN: 'Search',
    }),
    quickSearch: () => ({
      DE: 'Schnellsuche',
      EN: 'Quick search',
    }),
    moreLink: () => ({
      DE: 'Mehr Details',
      EN: 'More details',
    }),
    showMore: () => ({
      DE: 'Mehr anzeigen',
      EN: 'Show more',
    }),
    showAll: () => ({
      DE: 'Alle anzeigen',
      EN: 'Show all',
    }),
    showLess: () => ({
      DE: 'Weniger anzeigen',
      EN: 'Show less',
    }),
    included: () => ({
      DE: 'Inkludiert',
      EN: 'Included',
    }),
    select: () => ({
      DE: 'Auswählen',
      EN: 'Select',
    }),
    deselect: () => ({
      DE: 'Abwählen',
      EN: 'Deselect',
    }),
    defaultSelect: () => ({
      DE: 'Bitte wählen',
      EN: 'Please select',
    }),
    current: () => ({
      DE: 'Aktuell',
      EN: 'Current',
    }),
    adminPortalTitle: () => ({
      DE: 'Adminportal',
      EN: 'Adminportal',
    }),
    adminPortalConfigCloudyaUser: () => ({
      DE: 'Konfiguration Cloudya User',
      EN: 'Configuration Cloudya User',
    }),
    adminPortalConfigCentrexxUser: () => ({
      DE: 'Konfiguration Centrexx3 User',
      EN: 'Configuration Centrexx3 User',
    }),
    adminPortalConfig: () => ({
      DE: 'Konfiguration',
      EN: 'Configuration',
    }),
    myAccount: () => ({
      DE: 'Partner Account',
      EN: 'Partner Account',
    }),
    createQuotes: () => ({
      DE: 'Angebotserstellung',
      EN: 'Create a quote',
    }),
    emptyStateTitle: (pageTitle: string) => ({
      DE: `Keine ${pageTitle} vorhanden`,
      EN: `No ${pageTitle} available`,
    }),
    emptyStateSubTitle: () => ({
      DE: 'Aktuell sind keine Daten verfügbar',
      EN: 'There is currently no data available',
    }),
    paginationSelectLabel: () => ({
      DE: 'Einträge pro Seite',
      EN: 'Items per page',
    }),
    paginationSliceInfo: (from: string, to: string, total: string) => ({
      DE: `${from}-${to} von ${total}`,
      EN: `${from}-${to} of ${total}`,
    }),
    discountedInfoText: (amountWithCurrency: any) => ({
      DE: `Durch Ihren gewährten Rabatt sparen Sie ${amountWithCurrency}.`,
      EN: `Through your granted discount you save ${amountWithCurrency}.`,
    }),
    takeOver: () => ({
      DE: 'Übernehmen',
      EN: 'Take over',
    }),
  },
  charges: {
    oneTime: () => ({
      DE: ' (einmalig)',
      EN: ' (one-time)',
    }),
    monthly: () => ({
      DE: ' / Monat',
      EN: ' / Month',
    }),
  },
  auth: {
    loginTitle: () => ({
      DE: 'Ihr Portal',
      EN: 'Your Portal',
    }),
    loginSubtitle: () => ({
      DE: 'Login',
      EN: 'Login',
    }),
    username: () => ({
      DE: 'Nutzername',
      EN: 'Username',
    }),
    password: () => ({
      DE: 'Passwort',
      EN: 'Password',
    }),
    errorGeneric: () => ({
      DE: 'Ein Fehler ist aufgetreten',
      EN: 'An error has occurred',
    }),
    errorLoginFailed: () => ({
      DE: 'Nutzername oder Passwort ungültig!',
      EN: 'Invalid username or password',
    }),
    errorPasswordConfirm: () => ({
      DE: 'Die eingegebenen Passwörter stimmen nicht überein.',
      EN: 'The entered passwords do not match.',
    }),
    errorPasswordSubmit: () => ({
      DE: 'Das eingegebene Passwort ist ungültig.',
      EN: 'The entered password is invalid.',
    }),
    submit: () => ({
      DE: 'Anmelden',
      EN: 'Log in',
    }),
    resetPasswordSubmit: () => ({
      DE: 'Abschicken',
      EN: 'Send',
    }),
    logout: () => ({
      DE: 'Ausloggen',
      EN: 'Log out',
    }),
    noAccount: () => ({
      DE: 'Noch keine Zugangsdaten?',
      EN: 'No access data yet?',
    }),
    persistSession: () => ({
      DE: 'Angemeldet bleiben',
      EN: 'Stay logged in',
    }),
    resetPasswordLink: () => ({
      DE: 'Passwort vergessen',
      EN: 'Forgot your password?',
    }),
    resetPasswordTitle: () => ({
      DE: 'Passwort vergessen',
      EN: 'Forgot your password?',
    }),
    resetPasswordDescription: () => ({
      DE: 'Bitte geben Sie Ihren Login an. Wir schicken Ihnen einen Link zum Zurücksetzen Ihres Passworts zu.',
      EN: 'Please enter your login details. We will send you a link to reset your password.',
    }),
    resetPasswordFormTitle: () => ({
      DE: 'Nutzername zurücksetzen',
      EN: 'Login credentials for password reset',
    }),
    resetPasswordEmailLabel: () => ({
      DE: 'Nutzername',
      EN: 'Username',
    }),
    resetPasswordBackLink: () => ({
      DE: 'Zurück zum Login',
      EN: 'Back to login',
    }),
    resetPasswordSuccess: (username: string) => ({
      DE: `Eine E-Mail mit Anweisungen um Zurücksetzen des Passworts wurde an ${username} gesendet.`,
      EN: `A reset password email has been sent to ${username}`,
    }),
    newPasswordTitle: () => ({
      DE: 'Willkommen bei Ihrem Kundenportal',
      EN: 'Welcome to your Customer Portal',
    }),
    newPasswordDescription: () => ({
      DE: 'Bitte legen Sie Ihr persönliches Passwort für Ihr Kundenportal fest. Dieses kann später im Nutzerprofil geändert werden.',
      EN: 'Please enter your password for the Customer Portal. You can change it later in your user profile page',
    }),
    newPasswordFormTitle: () => ({
      DE: 'Passwort ändern',
      EN: 'Change password',
    }),
    newPasswordNewPassword: () => ({
      DE: 'Neues Passwort',
      EN: 'New password',
    }),
    newPasswordConfirm: () => ({
      DE: 'Passwort bestätigen',
      EN: 'Confirm password',
    }),
    newPasswordSubmit: () => ({
      DE: 'Abschicken',
      EN: 'Send',
    }),
    newPasswordSuccess: () => ({
      DE: 'Ihr Passwort wurde erfolgreich geändert.',
      EN: 'Your password has been changed successfully.',
    }),
    newPasswordLoginLink: () => ({
      DE: 'Zum Login',
      EN: 'Login now',
    }),
    errorSetNewPasswordFailed: () => ({
      DE: 'Ein Fehler ist aufgetreten',
      EN: 'An error has occurred',
    }),
    pwStrengthPasswordCriteria: () => ({
      DE: 'Passwortkriterien:',
      EN: 'Password requirements',
    }),
    pwStrengthMinChars: () => ({
      DE: 'min 8 Zeichen',
      EN: 'At least 8 characters',
    }),
    pwStrengthUpperLower: () => ({
      DE: 'Groß- und Kleinschreibung',
      EN: 'At least one uppercase and lowercase letter',
    }),
    pwStrengthSpecial: () => ({
      DE: 'min 1 Sonderzeichen',
      EN: 'At least one special character',
    }),
    pwStrengthPasswordStrength: () => ({
      DE: 'Passwortstärke:',
      EN: 'Password strength:',
    }),
  },
  footer: {
    copyNote: (year: string) => ({
      DE: `© NFON ${year} All rights reserved`,
      EN: `© NFON ${year} All rights reserved`,
    }),
    imprint: () => ({
      DE: `Impressum`,
      EN: `Legal Notice`,
    }),
    dataProtection: () => ({
      DE: `Datenschutz`,
      EN: `Data Protection`,
    }),
    AGB: () => ({
      DE: `AGB`,
      EN: `GTC`,
    }),
    AGB_SLA: () => ({
      DE: `AGB & SLA`,
      EN: `GTC & SLA`,
    }),
  },
  footer_dts: {
    copyNote: (year: string) => ({
      DE: `© Deutsche Telefon Standard ${year} All rights reserved`,
      EN: `© Deutsche Telefon Standard ${year} All rights reserved`,
    }),
  },
  header: {
    altLogo: () => ({
      DE: 'Portal',
      EN: 'Portal',
    }),
    title: () => ({
      DE: 'Portal',
      EN: 'Portal',
    }),
    dtsPartnerTitle: () => ({
      DE: 'Meine Deutsche Telefon Standard – Partner-Ansicht',
      EN: 'My Deutsche Telefon Standard – Partner View',
    }),
    customerTitle: () => ({
      DE: 'Kundenportal',
      EN: 'Customer Portal',
    }),
    partnerTitle: () => ({
      DE: 'Partner Portal',
      EN: 'Partner Portal',
    }),
    wholesalerTitle: () => ({
      DE: 'Wholesale Portal',
      EN: 'Wholesale Portal',
    }),
    editProfile: () => ({
      DE: 'Profil bearbeiten',
      EN: 'Edit profile',
    }),
  },
  status: {
    customer: {
      new: () => ({
        DE: 'neu',
        EN: 'new',
      }),
      active: () => ({
        DE: 'aktiv',
        EN: 'active',
      }),
      rejected: () => ({
        DE: 'abgelehnt',
        EN: 'rejected',
      }),
      terminated: () => ({
        DE: 'storniert',
        EN: 'terminated',
      }),
      notCreatable: () => ({
        DE: 'nicht herstellbar',
        EN: 'not creatable',
      }),
      newInRealisation: () => ({
        DE: 'neu, in Realisierung',
        EN: 'new, in realisation',
      }),
      cancelled: () => ({
        DE: 'gekündigt',
        EN: 'cancelled',
      }),
      inCancellation: () => ({
        DE: 'in Kündigung',
        EN: 'in cancellation',
      }),
      barringRequested: () => ({
        DE: 'Sperre angefordert',
        EN: 'barring requested',
      }),
      barred: () => ({
        DE: 'gesperrt',
        EN: 'barred',
      }),
      barringRemovalRequested: () => ({
        DE: 'Entsperrung angefordert',
        EN: 'barring removal requested',
      }),
      portedAway: () => ({
        DE: 'Wegportiert',
        EN: 'ported away',
      }),
      markedForPortingAway: () => ({
        DE: 'Für Wegportierung vorgemerkt',
        EN: 'marked for porting away',
      }),
      markedForDeletion: () => ({
        DE: 'Zur Löschung vorgemerkt',
        EN: 'marked for deletion',
      }),
      markedAsDeleted: () => ({
        DE: 'Als gelöscht markiert',
        EN: 'marked as deleted',
      }),
    },
    service: {
      notOrdered: () => ({
        DE: 'nicht gebucht',
        EN: 'not ordered',
      }),
      ordered: () => ({
        DE: 'bestellt',
        EN: 'ordered',
      }),
      active: () => ({
        DE: 'aktiv',
        EN: 'active',
      }),
      rejected: () => ({
        DE: 'abgelehnt',
        EN: 'rejected',
      }),
      barred: () => ({
        DE: 'gesperrt',
        EN: 'barred',
      }),
      barredInstallation: () => ({
        DE: 'Enthält gesperrte Anlage',
        EN: 'Contains blocked installation',
      }),
      barringRequested: () => ({
        DE: 'Sperre angefordert',
        EN: 'barring requested',
      }),
      deactivationRequested: () => ({
        DE: 'Deaktivierung angefordert',
        EN: 'deactivation requested',
      }),
      deactivated: () => ({
        DE: 'deaktiviert',
        EN: 'deactivated',
      }),
      cancellationRequested: () => ({
        DE: 'Kündigung vorgemerkt',
        EN: 'cancellation requested',
      }),
      barringRemovalRequested: () => ({
        DE: 'Entsperrung angefordert',
        EN: 'barring removal requested',
      }),
      cancelled: () => ({
        DE: 'gekündigt',
        EN: 'cancelled',
      }),
      activationRequested: () => ({
        DE: 'aktivierung angefordert',
        EN: 'activation requested',
      }),
      sent: () => ({
        DE: 'versandt',
        EN: 'sent',
      }),
      terminationRequested: () => ({
        DE: 'storno angefordert',
        EN: 'termination requested',
      }),
      terminated: () => ({
        DE: 'storniert',
        EN: 'terminated',
      }),
      notAvailable: () => ({
        DE: 'nicht verfügbar',
        EN: 'not available',
      }),
      defective: () => ({
        DE: 'defekt',
        EN: 'defective',
      }),
      exported: () => ({
        DE: 'exportiert',
        EN: 'exported',
      }),
      usedUp: () => ({
        DE: 'aufgebraucht',
        EN: 'used up',
      }),
      expired: () => ({
        DE: 'abgelaufen',
        EN: 'expired',
      }),
      change_requested: () => ({
        DE: 'Wechsel vorgemerkt',
        EN: 'change requested',
      }),
    },
    quote: {
      new: () => ({
        DE: 'neu',
        EN: 'new',
      }),
      ordered: () => ({
        DE: 'bestellt',
        EN: 'ordered',
      }),
      readyForSubmission: () => ({
        DE: 'zur Vorlage bereit',
        EN: 'ready for submission',
      }),
      refusedByCustomer: () => ({
        DE: 'abgelehnt',
        EN: 'rejected',
      }),
      orderEnrichment: () => ({
        DE: 'Auftragsanreicherung',
        EN: 'order enrichment',
      }),
      obsolete: () => ({
        DE: 'obsolet',
        EN: 'obsolete',
      }),
      rejectedByRM: () => ({
        DE: 'abgelehnt',
        EN: 'rejected',
      }),
      rejectedBySM: () => ({
        DE: 'abgelehnt',
        EN: 'rejected',
      }),
      draft: () => ({
        DE: 'Entwurf',
        EN: 'draft',
      }),
      edited: () => ({
        DE: 'bearbeitet',
        EN: 'edited',
      }),
    }
  },
  bills: {
    title: () => ({
      DE: 'Rechnungen',
      EN: 'Bills',
    }),
    subheading: () => ({
      DE: 'Hier finden Sie alle Rechnungen',
      EN: 'Here you can find all invoices',
    }),
    pdfLinkName: () => ({
      DE: 'PDF',
      EN: 'PDF',
    }),
    csvLinkName: () => ({
      DE: 'CSV',
      EN: 'CSV',
    }),
    allPdfsName: () => ({
      DE: 'PDF herunterladen',
      EN: 'Download as PDF',
    }),
    allCsvsName: () => ({
      DE: 'CSV herunterladen',
      EN: 'Download as CSV',
    }),
    zipFileNamePdf: () => ({
      DE: 'Rechnungen-pdf',
      EN: 'bills-pdf',
    }),
    tableHeadingDate: () => ({
      DE: 'Datum',
      EN: 'Date',
    }),
    tableHeadingName: () => ({
      DE: 'Name',
      EN: 'Name',
    }),
    tableHeadingBillNumber: () => ({
      DE: 'Rechnungsnummer',
      EN: 'Number',
    }),
    tableHeadingDownload: () => ({
      DE: 'Download',
      EN: 'Download',
    }),
  },
  telephoneDetails: {
    configurationOptions: () => ({
      DE: 'Konfiguration / Option',
      EN: 'Configuration / Option',
    }),
    contractStart: () => ({
      DE: 'Vertragsbeginn',
      EN: 'Start of contract',
    }),
    contractEnd: () => ({
      DE: 'Vertragsende',
      EN: 'End of contract',
    }),
    status: () => ({
      DE: 'Status',
      EN: 'Status',
    }),
  },
  contractDetails: {
    title: () => ({
      DE: 'Vertragsdetails',
      EN: 'Contract details',
    }),
    subheading: () => ({
      DE: 'Erhalten Sie Details zu Ihrem Vertrag',
      EN: 'Get details on your contract',
    }),
    monthlyCharges: () => ({
      DE: 'Monatliche Services',
      EN: 'Monthly services',
    }),
    monthlyChargesToCancel: () => ({
      DE: 'Vorgemerkte Kündigung',
      EN: 'Requested Cancellation',
    }),
    inactive: () => ({
      DE: 'nicht aktiv',
      EN: 'not active',
    }),
    total: () => ({
      DE: 'Gesamt (netto)',
      EN: 'Total (net)',
    }),
    voicePackage: () => ({
      DE: 'Sprachkanal Paket',
      EN: 'Voice channel package',
    }),
    contractStart: () => ({
      DE: 'Vertragsbeginn',
      EN: 'Start of contract',
    }),
    contractEnd: () => ({
      DE: 'Vertragsende',
      EN: 'End of contract',
    }),
    contractNumber: () => ({
      DE: 'K-Nummer',
      EN: 'K-Account',
    }),
    status: () => ({
      DE: 'Status',
      EN: 'Status',
    }),
    languagePackOption: () => ({
      DE: 'Konfiguration / Option',
      EN: 'Concurrent voice channels / Option',
    }),
    start: () => ({
      DE: 'Start',
      EN: 'Start',
    }),
    infoBox: () => ({
      DE: 'Die untenstehenden Beträge beinhalten keine Rabatte, Aktionen und monatsanteilige Gebühren. Diese werden auf der Rechnung ersichtlich sein.',
      EN: 'The amounts below do not include discounts, promotions and monthly prorated fees. These will be evident on the invoice.',
    }),
  },
  itemized: {
    title: () => ({
      DE: 'Einzelverbindungen',
      EN: 'Call data records (CDR)',
    }),
    subheading: () => ({
      DE: 'Hier finden Sie alle Einzelverbindungen',
      EN: 'Here you can find all call data records (CDR) files',
    }),
    pdfLinkName: () => ({
      DE: 'PDF',
      EN: 'PDF',
    }),
    csvLinkName: () => ({
      DE: 'CSV',
      EN: 'CSV',
    }),
    allPdfsName: () => ({
      DE: 'PDF herunterladen',
      EN: 'Download as PDF',
    }),
    allCsvsName: () => ({
      DE: 'CSV herunterladen',
      EN: 'Download as CSV',
    }),
    zipFileNameCsv: () => ({
      DE: 'Einzelverbindungsnachweise-csv',
      EN: 'itemized-csv',
    }),
    tableHeadingDate: () => ({
      DE: 'Datum',
      EN: 'Date',
    }),
    tableHeadingName: () => ({
      DE: 'Name',
      EN: 'Name',
    }),
    tableHeadingBillNumber: () => ({
      DE: 'Nachweisnummer',
      EN: 'Number',
    }),
    tableHeadingDownload: () => ({
      DE: 'Download',
      EN: 'Download',
    }),
    anonymize: () => ({
      DE: 'Anonymisieren des EVN',
      EN: 'Anonymize the CDR',
    })
  },
  helpBox: {
    itemized: () => ({
      DE: `Hier entscheiden Sie, ob die letzten drei Stellen der Rufnummern, die im Einzelverbindungsnachweis angezeigt werden, anonymisiert werden.
      Bitte beachten Sie rechtliche Vorgaben und etwaige betriebsinterne Vereinbarungen, sofern Sie die Rufnummern vollständig anzeigen lassen.`,
      EN: `Here you decide whether the last three digits of the call numbers displayed in the itemized bill are to be anonymized.
      Please observe legal requirements and any internal company agreements if you display the call numbers in full.`,
    }),

    mainAddress: () => ({
      DE: 'Die Hauptadresse ist die Adresse des Firmensitzes und wird für sämtliche Kommunikation verwendet,' +
          ' sofern nichts anderes hinterlegt ist.',
      EN: 'The main address is the address of the company\'s headquarters and will be used for ' +
          'all communications unless otherwise filed.',
    }),

    connectionAddress: () => ({
      DE: 'Die Anschlussadresse ist die Adresse, die für den jeweiligen Telefonanschluss hinterlegt wurde.' +
          ' Diese kann z.B. bei mehreren Standorten in unterschiedlichen Städten von der Hauptadresse abweichen.',
      EN: 'The connection address is the address that has been stored for the respective' +
          ' telephone connection. This may differ from the main address, for example, if there are several locations in different cities.',
    }),

    billingAddress: () => ({
      DE: 'Die Rechnungsadresse ist die Adresse, die für die Rechnungsstellung verwendet wird.',
      EN: 'The billing address is the address that will be used for billing.',
    }),
    shippingAddress: () => ({
      DE: 'Die Lieferadresse ist die Adresse, die für Lieferungen z.B. von Hardware etc. verwendet wird.',
      EN: 'The delivery address is the address that will be used for deliveries of e.g. hardware etc.',
    })
  },

  myAccount: {
    title: () => ({
      DE: 'Partner Account',
      EN: 'Partner Account',
    }),
    subheading: () => ({
      DE: 'Erhalten Sie hier eine Übersicht zu Ihren Kundendaten',
      EN: 'Get an overview of your customer data here',
    }),
  },
  createQuote: {
    title: () => ({
      DE: 'Angebotserstellung',
      EN: 'Create a quote',
    }),
    subheading: () => ({
      DE: 'Erhalten Sie hier eine Übersicht zu Ihren erstellten Angeboten',
      EN: 'Get an overview of your created quotes here',
    }),
    emptyStateSubTitle: () => ({
      DE: 'Aktuell sind keine Daten verfügbar',
      EN: 'There is currently no data available',
    }),
    tableHeaderCustomer: () => ({
      DE: 'Kunde',
      EN: 'Customer',
    }),
    tableHeaderCustomerId: () => ({
      DE: 'Kundennr.',
      EN: 'Customer no.',
    }),
    tableHeaderQuoteId: () => ({
      DE: 'Angebotsnr.',
      EN: 'Quote no.',
    }),
    tableHeaderCreateDt: () => ({
      DE: 'Angelegt am',
      EN: 'Created on',
    }),
    tableHeaderStatus: () => ({
      DE: 'Status',
      EN: 'Status',
    }),
    paginationSelectLabel: () => ({
      DE: 'Einträge pro Seite',
      EN: 'Items per page',
    }),
    addQuote: () => ({
      DE: 'Angebot',
      EN: 'Quote',
    }),
    all: () => ({
      DE: 'alle',
      EN: 'all',
    }),
    product: () => ({
      DE: 'Produkt',
      EN: 'Product',
    }),
    quantity: () => ({
      DE: 'Menge',
      EN: 'Quantity',
    }),
    oneTimeCharge: () => ({
      DE: 'Preis einmalig',
      EN: 'Onetime price',
    }),
    discount: () => ({
      DE: 'Rabatt',
      EN: 'Discount',
    }),
    monthlyCharge: () => ({
      DE: 'Preis monatlich',
      EN: 'Monthly price',
    }),
    paginationSliceInfo: (from: string, to: string, total: string) => ({
      DE: `${from}-${to} von ${total}`,
      EN: `${from}-${to} of ${total}`,
    }),
  },
  commission: {
    title: () => ({
      DE: 'Provisionsabrechnungen',
      EN: 'Commission invoice',
    }),
    pdfLinkName: () => ({
      DE: 'PDF',
      EN: 'PDF',
    }),
    csvLinkName: () => ({
      DE: 'CSV',
      EN: 'CSV',
    }),
    allPdfsName: () => ({
      DE: 'PDF herunterladen',
      EN: 'Download as PDF',
    }),
    allCsvsName: () => ({
      DE: 'CSV herunterladen',
      EN: 'Download as CSV',
    }),
    zipFileNameCsv: () => ({
      DE: 'provisionsabrechnung-csv',
      EN: 'provisionsabrechnung-csv',
    }),
    zipFileNamePdf: () => ({
      DE: 'provisionsabrechnung-pdf',
      EN: 'provisionsabrechnung-pdf',
    }),
    tableHeadingDate: () => ({
      DE: 'Datum',
      EN: 'Date',
    }),
    tableHeadingName: () => ({
      DE: 'Name',
      EN: 'Name',
    }),
    tableHeadingBillNumber: () => ({
      DE: 'Rechnungsnummer',
      EN: 'Number',
    }),
    tableHeadingDownload: () => ({
      DE: 'Download',
      EN: 'Download',
    }),
  },
  sdr: {
    title: () => ({
      DE: 'SDRs',
      EN: 'SDRs',
    }),
    csvLinkName: () => ({
      DE: 'CSV',
      EN: 'CSV',
    }),
    allCsvsName: () => ({
      DE: 'CSV herunterladen',
      EN: 'Download as CSV',
    }),
    zipFileNameCsv: () => ({
      DE: 'sdr-csv',
      EN: 'sdr-csv',
    }),
    tableHeadingDate: () => ({
      DE: 'Datum',
      EN: 'Date',
    }),
    tableHeadingName: () => ({
      DE: 'Name',
      EN: 'Name',
    }),
    tableHeadingBillNumber: () => ({
      DE: 'Rechnungsnummer',
      EN: 'Number',
    }),
    tableHeadingDownload: () => ({
      DE: 'Download',
      EN: 'Download',
    }),
    titleCDRs: () => ({
      DE: 'CDRs',
      EN: 'CDRs',
    }),
  },
  securitySettings: {
    title: () => ({
      DE: 'Security-Einstellungen',
      EN: 'Security Settings',
    }),
    subheading: () => ({
      DE: 'Setzen Sie für Ihre Anschlüsse Limits, um sich vor Missbrauch zu schützen.',
      EN: 'Set limits for your connections to protect against misuse.',
    }),
    fraudDetection: () => ({
      DE: 'Fraud Detection',
      EN: 'Fraud Detection',
    }),
    limitPerInterval: () => ({
      DE: 'Fraud Limit je 60 Minuten Zeitintervall',
      EN: 'Fraud Limit per 60 minutes time interval',
    }),
    infoBoxText: () => ({
      DE: 'Die Betrugserkennung basiert auf Kostenlimits je 60 Minuten Zeitintervall. Ihre Anlage ist auf eine Mindesthöhe konfiguriert, jedoch können Sie die Kostenhöhe nach oben verändern. Dadurch werden jedoch mögliche Betrugsfälle später erkannt. Sie sollten daher eine Wert wählen der ihre gewöhnliches Telefonieverhalten absichert.',
      EN: 'Fraud detection is based on cost limits per 60 minutes time interval. Your installation is configured to a minimum level, but you can change the cost level upwards. However, this will detect possible fraud later. You should therefore choose a value that protects your usual telephony behavior.',
    }),
    unlockButton: () => ({
      DE: 'Entsperren',
      EN: 'Unlock',
    }),
    spendingLimit: () => ({
      DE: 'Spending Limit',
      EN: 'Spending Limit',
    }),
    limit: () => ({
      DE: 'Monatliches Limit',
      EN: 'Monthly Limit',
    }),
    active: () => ({
      DE: 'Aktiv',
      EN: 'Active',
    }),
    inactive: () => ({
      DE: 'Inaktiv',
      EN: 'Inactive',
    }),
    usage: () => ({
      DE: 'Verbrauch',
      EN: 'Usage',
    }),
    asOf: () => ({
      DE: 'Stand:',
      EN: 'Date:',
    }),
    save: () => ({
      DE: 'Speichern',
      EN: 'Save',
    }),
    lockedHeading: () => ({
      DE: 'Ihre Anlage wurde gesperrt',
      EN: 'Your installation has been locked',
    }),
    lockedBody: () => ({
      DE: 'Ihr Fraud-Limit wurde überschritten was auf einen Missbrauch hindeuten kann. Aus Sicherheitsgründen haben wir Ihren Anschluss gesperrt. Bitte prüfen Sie die Sicherheitseinstellungen Ihres Anschlusses im Adminportal, bevor sie die Sperre entfernen.',
      EN: 'Your fraud limit has been exceeded, which may indicate misuse or fraud. For security reasons, we have blocked your line. Please check the security settings of your connection in the admin portal before removing the block.',
    }),
    unlockInProgressHeading: () => ({
      DE: 'Sperre aufgehoben',
      EN: 'Lock removed',
    }),
    unlockInProgressBody: () => ({
      DE: 'Sie haben die Sperre Ihres Anschlusses zurückgesetzt. Möglicherweise kann es einige Minuten dauern, bis der Anschluss wieder verfügbar ist. Sie werden per Mail verständigt, sobald die Sperre aufgehoben wurde.',
      EN: 'You have requested to remove the connection lock. This might take several minutes to take effect. You will receive an email as soon as the lock has been removed',
    }),
    confirmUnlockPopupHeading: () => ({
      DE: '',
      EN: '',
    }),
    confirmUnlockPopupBody: () => ({
      DE: 'Die Entsperrung wurde initiiert. Dieser Vorgang kann einige Minuten dauern.',
      EN: 'Unlocking has been initiated. This process may take a few minutes.',
    }),
    popupHeading: () => ({
      DE: 'Fraud Detection',
      EN: 'Fraud Detection',
    }),
    popupBody1: () => ({
      DE: 'Bitte bestätigen Sie folgende Änderungen:',
      EN: 'Please confirm the following changes:',
    }),
    popupBody2: () => ({
      DE: 'Fraud Limit je 60 Minuten Zeitintervall:',
      EN: 'Fraud Limit per 60 minutes time interval:',
    }),
    popupBody3: () => ({
        DE: 'Fraud Limit wird deaktiviert',
        EN: 'Fraud Limit would be deactivated',
    }),
    success: () => ({
      DE: 'Änderung erfolgreich',
      EN: 'Change successful',
    }),
    error: () => ({
      DE: 'Änderung fehlgeschlagen',
      EN: 'Change failed',
    }),
    tryAgain: () => ({
      DE: 'Bitte versuchen Sie es erneut.',
      EN: 'Please try again.',
    }),
  },
  myContract: {
    title: () => ({
      DE: 'Mein Vertrag',
      EN: 'My contract',
    }),
    duration: () => ({
      DE: 'Läuft bis',
      EN: 'Until',
    }),
    subheading: () => ({
      DE: 'Erhalten Sie hier eine Übersicht zu Ihren aktuellen Vertragsdaten',
      EN: 'Get an overview of your current contract data here.',
    }),
    voicePackage: () => ({
      DE: 'Sprachkanal Paket',
      EN: 'Voice channel package',
    }),
    overviewConnections: () => ({
      DE: 'Anschlüsse',
      EN: 'Connections',
    }),
    overviewLocations: () => ({
      DE: 'Standorte',
      EN: 'Locations',
    }),
    overviewNumbers: () => ({
      DE: 'Rufnummern',
      EN: 'Numbers',
    }),
    moreLink: () => ({
      DE: 'Mehr Details',
      EN: 'More details',
    }),
    customerDataHeading: () => ({
      DE: 'Kundendaten',
      EN: 'Customer details',
    }),
    customerId: () => ({
      DE: 'Kundennr.',
      EN: 'Customer ID',
    }),
    address: () => ({
      DE: 'Adresse',
      EN: 'Address',
    }),
    contact: () => ({
      DE: 'Kontakt',
      EN: 'Contact',
    }),
    lastBill: () => ({
      DE: 'Letzte Rechnung',
      EN: 'Last bill',
    }),
    lastItemized: () => ({
      DE: 'Letzte Einzelverbindungen',
      EN: 'Last detailed billing record',
    }),
    orderStatus: () => ({
      DE: 'Auftragsstatus',
      EN: 'Order status',
    }),
    orderStatusOrdered: () => ({
      DE: 'bestellt',
      EN: 'ordered',
    }),
    customerStatus: () => ({
      DE: 'Kundenstatus',
      EN: 'Customer Status',
    }),
  },
  myContracts: {
    addButton: () => ({
      DE: 'Hinzufügen',
      EN: 'Add',
    }),
    title: () => ({
      DE: 'Meine Verträge',
      EN: 'My contracts',
    }),
    subheading: () => ({
      DE: 'Erhalten Sie hier eine Übersicht zu Ihren aktuellen Vertragsdaten',
      EN: 'Get an overview of your current contract data here.',
    }),
    kNumber: () => ({
      DE: 'K-Nummer',
      EN: 'K-Account',
    }),
    productName: () => ({
      DE: 'Produkt',
      EN: 'Product',
    }),
    notes: () => ({
      DE: 'Notizen',
      EN: 'Notes',
    }),
    status: () => ({
      DE: 'Status',
      EN: 'Status',
    }),
    emptyStateSubTitle: () => ({
      DE: 'Aktuell sind keine Daten verfügbar',
      EN: 'There is currently no data available',
    }),
    contracts: () => ({
      DE: 'Verträge',
      EN: 'contracts',
    }),
    paginationSelectLabel: () => ({
      DE: 'Einträge pro Seite',
      EN: 'Items per page',
    }),
    paginationSliceInfo: (from: string, to: string, total: string) => ({
      DE: `${from}-${to} von ${total}`,
      EN: `${from}-${to} of ${total}`,
    }),
    save: () => ({
      DE: 'Speichern',
      EN: 'Save',
    }),
    emptyNotes: () => ({
      DE: 'Keine Notizen vorhanden',
      EN: 'No notes available',
    }),
    changeNotesSuccess: () => ({
      DE: 'Notizen erfolgreich aktualisiert',
      EN: 'Notes updated successfully',
    }),
    unlockInstallationsTitle: () => ({
      DE: 'Anlagen entsperren',
      EN: 'Unlock installations',
    }),
    unlockInstallationsText: () => ({
      DE: 'Wählen Sie die Anlage aus, die Sie entsperren möchten.',
      EN: 'Select the installation you want to unlock.',
    }),
    unlock: () => ({
      DE: 'Sperrung ansehen',
      EN: 'View blocking',
    }),
  },
  myData: {
    title: () => ({
      DE: 'Meine Daten',
      EN: 'My data',
    }),
    subheading: () => ({
      DE: 'Erhalten Sie hier eine Übersicht zu Ihren Kundendaten',
      EN: 'Get an overview of your customer data here',
    }),
    personsInChargeTitle: () => ({
      DE: 'Ansprechpartner',
      EN: 'Contact',
    }),
    technicalContactPerson: () => ({
      DE: 'Technischer Ansprechpartner',
      EN: 'Technical contact person',
    }),
    businessContactPerson: () => ({
      DE: 'Geschäftspartner',
      EN: 'Business partner',
    }),
    commercialContactPerson: () => ({
      DE: 'Kaufmännischer Portal-User',
      EN: 'Commercial portal user',
    }),
    PartnerPortalUser: () => ({
      DE: 'Partner Portal Kunde',
      EN: 'Customer Partner Portal',
    }),
    customerId: () => ({
      DE: 'Kundennummer',
      EN: 'Customer ID',
    }),
    customerStatus: () => ({
      DE: 'Kundenstatus',
      EN: 'Customer Status',
    }),
    contact: () => ({
      DE: 'Kontakt',
      EN: 'Contact',
    }),
    address: () => ({
      DE: 'Adresse',
      EN: 'Address',
    }),
    banktransfer: () => ({
      DE: 'Überweisung',
      EN: 'Bank transfer',
    }),
    billAccount: () => ({
      DE: 'Rechnungskonto',
      EN: 'Billing account',
    }),
    account: () => ({
      DE: 'Vertragskonto',
      EN: 'account',
    }),
    editBillAccount: () => ({
      DE: 'Vertragskonto bearbeiten',
      EN: 'Edit account',
    }),
    editBillAccountSuccess: () => ({
      DE: 'Das Rechnungskonto wurde erfolgreich geändert.',
      EN: 'The billing account has been edited successfully.',
    }),
    editBillAccountSuccessHeading: () => ({
      DE: 'Änderung erfolgreich',
      EN: 'Change successful',
    }),
    editBillAccountFailed: () => ({
      DE: 'Bitte versuchen Sie es erneut.',
      EN: 'Please try again.',
    }),
    editBillAccountFailedHeading: () => ({
      DE: 'Änderung fehlgeschlagen',
      EN: 'Change failed',
    }),
    payment: () => ({
      DE: 'Zahlung',
      EN: 'Payment',
    }),
    billAddress: () => ({
      DE: 'Rechnungsadresse',
      EN: 'Billing address',
    }),
    mainContract: () => ({
      DE: 'Hauptvertrag',
      EN: 'Main Contract',
    }),
    billedContracts: () => ({
      DE: 'Abgerechnete Verträge',
      EN: 'Billed contracts',
    }),
    accountType: () => ({
      DE: 'Kontoart',
      EN: 'Account type',
    }),
    debitAccount: () => ({
      DE: 'Debitor',
      EN: 'Debitor',
    }),
    creditAccount: () => ({
      DE: 'Creditor',
      EN: 'Creditor',
    }),
    paymentTarget: () => ({
      DE: 'Zahlungsziel',
      EN: 'Payment Target',
    }),
    partnerBilledContracts: () => ({
      DE: 'Partnerverträge',
      EN: 'Partner contracts',
    }),
    partnerCreditAccount: () => ({
      DE: 'Konto für Auszahlungen',
      EN: 'Account for withdrawals',
    }),
    partnerDebitAccount: () => ({
      DE: 'Konto für Lastschriften',
      EN: 'Direct debit account',
    }),
    subheadingLocations: () => ({
      DE: 'Standorte',
      EN: 'Locations',
    }),
    subheadingShippingAddresses: () => ({
      DE: 'Lieferadressen',
      EN: 'Shipping addresses',
    }),
    tableHeadingName: () => ({
      DE: 'Firmenname, Name',
      EN: 'Company name, name',
    }),
    tableHeadingStreet: () => ({
      DE: 'Straße',
      EN: 'Street',
    }),
    tableHeadingPostalCode: () => ({
      DE: 'PLZ',
      EN: 'Postal code',
    }),
    tableHeadingCity: () => ({
      DE: 'Ort',
      EN: 'City',
    }),
    tableHeadingCountry: () => ({
      DE: 'Land',
      EN: 'Country',
    }),
    tableHeadingType: () => ({
      DE: 'Anschlussart',
      EN: 'Connection type',
    }),
    anonymousItemized: () => ({
      DE: 'Anonymisieren des EVN',
      EN: 'Anonymize the itemized bill',
    }),
    anonymize: () => ({
      DE: 'Anonymisieren',
      EN: 'Anonymize',
    }),
    notAvailable: () => ({
      DE: 'Nicht vorhanden',
      EN: 'Not available',
    })
  },
  numbers: {
    title: () => ({
      DE: 'Rufnummern',
      EN: 'Numbers',
    }),
    emptyStateSubTitle: () => ({
      DE: 'Fügen Sie Rufnummern hinzu',
      EN: 'Add Numbers',
    }),
    subheading: () => ({
      DE: 'Verwalten Sie Ihre Rufnummern',
      EN: 'Manage your phone numbers',
    }),
    number: () => ({
      DE: 'Rufnummer',
      EN: 'Number',
    }),
    cli: () => ({
      DE: 'In Bearbeitung',
      EN: 'In Progress',
    }),
    site: () => ({
      DE: 'Standort',
      EN: 'Location',
    }),
    inOutbound: () => ({
      DE: 'In/Outbound',
      EN: 'In/outbound',
    }),
    status: () => ({
      DE: 'Status',
      EN: 'Status',
    }),
    active: () => ({
      DE: 'aktiv',
      EN: 'active',
    }),
    ordered: () => ({
      DE: 'bestellt',
      EN: 'ordered',
    }),
    numberDetailsLabel: () => ({
      DE: 'Details zu',
      EN: 'Details regarding',
    }),
    package: () => ({
      DE: 'Minutenpaket',
      EN: 'Minute package',
    }),
    closeNumberDetails: () => ({
      DE: 'schließen',
      EN: 'Close',
    }),
    options: () => ({
      DE: 'Optionen',
      EN: 'Options',
    }),
  },
  changeProductModal: {
    title: () => ({
      DE: 'Auswahl',
      EN: 'Selection',
    }),
    subTitle: () => ({
      DE: 'Wählen Sie das Produkt, auf das Sie upgraden möchten',
      EN: 'Select the product you wish to upgrade to',
    }),
    optionsEmptyState: () => ({
      DE: 'Aktuell können keine Upgrades durchgeführt.',
      EN: 'No upgrades can currently be carried out..',
    }),
    successInfo: () => ({
      DE: 'Ihr Produkt wird mit dem Ersten des nächsten Monats geändert.',
      EN: 'Your product will be changed with the first of the next month.',
    }),
    upgrade: () => ({
      DE: 'Upgrade',
      EN: 'Upgrade',
    }),
    truckInfo: () => ({
      DE: 'Option wird auf das neue Produkt übertragen',
      EN: 'Option is carried over to the new product',
    }),
  },
  confirmationModal: {
    increaseSuccess: () => ({
      DE: 'Bestellung erfolgreich',
      EN: 'Order completed',
    }),
    increaseSuccessText: () => ({
      DE: 'Die Anzahl der Nebenstellen wurde vergrößert',
      EN: 'The number of extensions has been increased',
    }),
    quoteSuccess: () => ({
      DE: 'Angebotserstellung erfolgreich',
      EN: 'Quote creation completed',
    }),
    pleaseWait: () => ({
      DE: 'Bitte warten',
      EN: 'Please wait',
    }),
    yourOrderIsBeingProcessed: () => ({
      DE: 'Ihre Bestellung wird verarbeitet.',
      EN: 'Your order is being processed.',
    }),
    reduceSuccess: () => ({
      DE: 'Änderung erfolgreich',
      EN: 'Change successful',
    }),
    reduceSuccessText: () => ({
      DE: 'Die Anzahl der Nebenstellen wurde verringert',
      EN: 'The number of extensions has been reduced',
    }),
    addedSuccess: () => ({
      DE: 'Erfolgreich hinzugefügt',
      EN: 'Added successfully',
    }),
    orderError: () => ({
      DE: 'Fehlgeschlagen',
      EN: 'Failed',
    }),
    close: () => ({
      DE: 'Sie können dieses Fenster nun schließen.',
      EN: 'You can now close this popup.',
    }),
    tryAgain: () => ({
      DE: 'Bitte versuchen Sie es erneut.',
      EN: 'Please try again.',
    }),
    correctData: () => ({
      DE: 'Sind die Kundendaten korrekt?',
      EN: 'Are the customer details correct?',
    }),
    migrationLine1: () => ({
      DE: 'Sobald Sie die Bereitstellungsbestätigung erhalten haben, sind die nächsten Schritte:',
      EN: 'Once you have received the provisioning confirmation, the next steps are as follows:',
    }),
    migrationLine2: () => ({
      DE: 'Bitte lassen Sie Ihren Administrator im SIP-Trunk-Flexx Portal den entsprechenden PBX Endpunkt anlegen.',
      EN: 'Please contact your administrator to create the corresponding PBX endpoint in the SIP-Trunk-Flexx portal.',
    }),
    migrationLine3: () => ({
      DE: 'Anschließend kann die PBX auf den neuen Endpunkt ergänzend konfiguriert werden.',
      EN: 'The PBX can then be additionally configured for the new endpoint.',
    }),
    migrationLine4: () => ({
      DE: 'Testen Sie die ausgehende Telefonie.',
      EN: 'Test the outgoing telephony.',
    }),
    migrationLine5: () => ({
      DE: 'Routen Sie im PowerMenü die eingehenden Anrufe auf den neuen Trunk.',
      EN: 'Route the incoming calls to the new trunk in PowerMenu.',
    }),
    migrationLine6: () => ({
      DE: 'Nun ist die Migration abgeschlossen.',
      EN: 'The migration is now complete.',
    }),
    confirmGoBack: () => ({
      DE: 'Sind Sie sicher, dass sie die Konfiguration löschen sollen?',
      EN: 'Are you sure, you want to delete this configuration?',
    }),

  },
  orderCallNumberModal: {
    title: () => ({
      DE: 'Rufnummer bestellen',
      EN: 'Order phone number',
    }),
    titlePage2: () => ({
      DE: 'Rufnummernblock',
      EN: 'Telephony Connection',
    }),
    titlePage2Porting: () => ({
      DE: 'Portierung',
      EN: 'Porting',
    }),
    titleSummary: () => ({
      DE: 'Bestätigung',
      EN: 'Summary',
    }),
    subText: () => ({
      DE: 'Wählen Sie aus, ob Sie eine neue oder eine vorhandene Rufnummer hinzufügen möchten.',
      EN: 'Please select whether you want to add a new or an existing number.',
    }),
    subTextPage2: () => ({
      DE: 'Bitte wählen Sie den Anschluss und Standort aus.',
      EN: 'Please select your connection and your location.',
    }),
    subTextSummary: () => ({
      DE: 'Bitte bestätigen Sie Ihre Auswahl.',
      EN: 'Please confirm your selection.',
    }),
    errorLegalEntity: () => ({
      DE: 'Bitte wählen Sie einen Telefonanschluss.',
      EN: 'Please select a connection.',
    }),
    errorAddress: () => ({
      DE: 'Bitte wählen Sie einen Standort.',
      EN: 'Please select a location.',
    }),
    errorAreaCode: () => ({
      DE: 'Bitte wählen Sie eine Vorwahl.',
      EN: 'Please select an area code.',
    }),
    errorProduct: () => ({
      DE: 'Bitte wählen Sie ein Produkt.',
      EN: 'Please select a product.',
    }),
    errorAreaCodeLength: () => ({
      DE: 'Bitte geben Sie eine Vorwahl an.',
      EN: 'Please enter an area code.',
    }),
    errorAreaCodeOnlyNumber: () => ({
      DE: 'Die Vorwahl darf nur Zahlen enthalten.',
      EN: 'The area code should only contain numbers',
    }),
    errorAreaCodeNumber: () => ({
      DE: 'Die eingegebene Vorwahl enthält keine Zahlen.',
      EN: 'The area code should contain numbers.',
    }),
    existingNumber: () => ({
      DE: 'Mitnahme einer bestehenden Rufnummer',
      EN: 'Transfer of an existing phone number',
    }),
    newNumber: () => ({
      DE: 'Neue Rufnummer bestellen',
      EN: 'Order a new phone number',
    }),
    net: () => ({
      DE: 'Netto',
      EN: 'net',
    }),
    next: () => ({
      DE: 'Weiter',
      EN: 'Next',
    }),
    telephoneConnection: () => ({
      DE: 'Telefonanschluss',
      EN: 'Connection',
    }),
    connection: () => ({
      DE: 'Anschluss',
      EN: 'Connection',
    }),
    location: () => ({
      DE: 'Standort',
      EN: 'Location',
    }),
    areaCode: () => ({
      DE: 'Vorwahl',
      EN: 'Area code',
    }),
    callnumber: () => ({
      DE: 'Rufnummern',
      EN: 'Call numbers',
    }),
    callNumberBlock: () => ({
      DE: 'Rufnummernblock',
      EN: 'Number block',
    }),
    products: () => ({
      DE: 'Produkte',
      EN: 'Products',
    }),
    order: () => ({
      DE: 'Bestellung',
      EN: 'Order',
    }),
    orderSuccess: () => ({
      DE: 'Bestellung erfolgreich',
      EN: 'Order completed',
    }),
    orderError: () => ({
      DE: 'Bestellung fehlgeschlagen',
      EN: 'Order failed',
    }),
    close: () => ({
      DE: 'Sie können dieses Fenster nun schließen.',
      EN: 'You can now close this popup.',
    }),
    tryAgain: () => ({
      DE: 'Bitte versuchen Sie es erneut.',
      EN: 'Please try again.',
    }),
  },
  packages: {
    title: () => ({
      DE: 'Minutenpakete',
      EN: 'Minute packages',
    }),
    titleDTS: () => ({
      DE: 'Flatrate',
      EN: 'Flatrate',
    }),
    subheading: () => ({
      DE: 'Verwalten Sie Ihre Pakete',
      EN: 'Manage your packages',
    }),
    validFromTo: () => ({
      DE: 'Gültig von - bis',
      EN: 'Valid from - to',
    }),
    valid: () => ({
      DE: 'aktiv',
      EN: 'active',
    }),
    invalid: () => ({
      DE: 'bestellt',
      EN: 'ordered',
    }),
    balance: () => ({
      DE: 'Guthaben',
      EN: 'Balance',
    }),
    asOf: () => ({
      DE: 'Stand:',
      EN: 'Date:',
    }),
  },
  profile: {
    title: () => ({
      DE: 'Ihre Daten',
      EN: 'Your Data',
    }),
    subheading: () => ({
      DE: 'Verwalten Sie hier Ihre Nutzerdaten, Portaleinstellungen und Passwort',
      EN: 'Managing your user details, portal settings and password',
    }),
    userDataHeading: () => ({
      DE: 'Benutzerdaten',
      EN: 'User details',
    }),
    fullName: () => ({
      DE: 'Vollständiger Name',
      EN: 'Full name',
    }),
    userName: () => ({
      DE: 'Nutzername (Login)',
      EN: 'Username (Login)',
    }),
    userNameInfoText: () => ({
      DE: `<b>Achtung:</b> Bei Änderung des Nutzernamens wird Ihr Login geändert.<br><br>Sie können sich fortan nicht mehr mit Ihrem bisherigen Nutzernamen anmelden.`,
      EN: `<b>Please note:</b> If you change your username, your login will be changed.<br><br>You will no longer be able to log in with your previous username.`,
    }),
    email: () => ({
      DE: 'E-Mail',
      EN: 'Email',
    }),
    phoneNumber: () => ({
      DE: 'Telefonnummer',
      EN: 'Number',
    }),
    portalSettingsHeading: () => ({
      DE: 'Portal Einstellung',
      EN: 'Portal settings',
    }),
    timeZone: () => ({
      DE: 'Zeitzone',
      EN: 'Time zone',
    }),
    language: () => ({
      DE: 'Sprache',
      EN: 'Language',
    }),
    passwordHeading: () => ({
      DE: 'Passwort',
      EN: 'Password',
    }),
    currentPassword: () => ({
      DE: 'Aktuelles Passwort',
      EN: 'Current password',
    }),
    newPassword: () => ({
      DE: 'Neues Passwort',
      EN: 'New password',
    }),
    repeatNewPassword: () => ({
      DE: 'Neues Passwort wiederholen',
      EN: 'Confirm password',
    }),
    confirmButtonText: () => ({
      DE: 'speichern',
      EN: 'save',
    }),
    successTitle: () => ({
      DE: 'Änderung erfolgreich',
      EN: 'Change successful',
    }),
    close: () => ({
      DE: 'Sie können dieses Fenster nun schließen.',
      EN: 'You can now close this popup.',
    }),
    contactDataName: () => ({
      DE: 'Firmenname / Vor- und Nachname *',
      EN: 'Company name / First and Last Name',
    }),
    contactDataContactPerson: () => ({
      DE: 'Ansprechpartner',
      EN: 'Contact Person',
    }),
    contactDataStreetAndHouseNumber: () => ({
      DE: 'Straße und Hausnummer',
      EN: 'Street and Housenumber',
    }),
    contactDataCityAndZip: () => ({
      DE: 'PLZ und Ort',
      EN: 'City and ZIP',
    }),
    contactDataContactNumber: () => ({
      DE: 'Kontaktrufnummer',
      EN: 'Contact Number',
    }),
    contactDataEmail: () => ({
      DE: 'E-Mail-Adresse',
      EN: 'Email Address',
    }),
    contactDataInternetAddress: () => ({
      DE: 'Internetadresse',
      EN: 'Internet Address',
    }),
    contactDataTechnicalHotline: () => ({
      DE: 'Technische Hotline',
      EN: 'Technical Hotline',
    }),
    contactDataLabelPortal: () => ({
      DE: 'Portal',
      EN: 'Portal',
    }),
    contactDataLabelBill: () => ({
      DE: 'Rechnung (max. 4)',
      EN: 'Bill (max. 4)',
    }),
    contactDataSaveButton: () => ({
      DE: 'Speichern',
      EN: 'Save',
    }),
    contactDataCancelButton: () => ({
      DE: 'Abbrechen',
      EN: 'Cancel',
    }),
    error: () => ({
      DE: 'Passwort ungültig',
      EN: 'Invalid password',
    }),
    success: () => ({
      DE: 'Passwort erfolgreich geändert',
      EN: 'Your password has been successfully changed',
    }),
    contactDataHeading: () => ({
      DE: 'Kontaktdaten - Portal und Rechnung',
      EN: 'Contact data - Portal and Bill',
    }),
    changeSuccess: () => ({
      DE: 'Änderung erfolgreich',
      EN: 'Change successfull',
    }),
  },
  settings: {
    title: () => ({
      DE: 'Verwaltung',
      EN: 'Administration',
    }),
    subheading: () => ({
      DE: 'Verwalten Sie hier Ihre Anschlüsse, Standort und Vertragsoptionen',
      EN: 'Manage your connections, location and contract options here',
    }),
    moreLink: () => ({
      DE: 'Mehr Details',
      EN: 'More details',
    }),
    sites: () => ({
      DE: 'Standorte',
      EN: 'Locations',
    }),
    numbers: () => ({
      DE: 'Rufnummern',
      EN: 'Numbers',
    }),
    packages: () => ({
      DE: 'Minutenpakete',
      EN: 'Minute packages',
    }),
    flatrates: () => ({
      DE: 'Flatrates',
      EN: 'Flatrates',
    }),
    limits: () => ({
      DE: 'Spendinglimits',
      EN: 'Spending limits',
    }),
    extensions: () => ({
      DE: 'Nebenstellen',
      EN: 'Extensions',
    }),
    licenses: () => ({
      DE: 'Lizenzen & Services',
      EN: 'Licenses & Services',
    }),
  },
  extensions: {
    title: () => ({
      DE: 'Nebenstellen',
      EN: 'Extensions',
    }),
    emptyStateSubTitle: () => ({
      DE: 'Fügen Sie Nebenstellen hinzu',
      EN: 'Add Extensions',
    }),
    extension: () => ({
      DE: 'Nebenstelle',
      EN: 'Extension',
    }),
    DisplayName: () => ({
      DE: 'Displayname',
      EN: 'Display name',
    }),
    address: () => ({
      DE: 'Standort',
      EN: 'Site',
    }),
    occupied: () => ({
      DE: 'Belegt',
      EN: 'occupied',
    }),
    yes: () => ({
      DE: 'JA',
      EN: 'YES',
    }),

    no: () => ({
      DE: 'NEIN',
      EN: 'NO',
    }),
    ContractAccount: () => ({
      DE: 'Vertragskonto',
      EN: 'Contract account',
    }),
    status: () => ({
      DE: 'Status',
      EN: 'Status',
    }),
    macAddress: () => ({
      DE: 'MAC-Adresse',
      EN: 'MAC-Address',
    }),
    customize: () => ({
      DE: 'Anpassen',
      EN: 'Customize',
    }),
    extensionInUse: () => ({
      DE: 'Genutzte Nebenstellen',
      EN: 'Extensions in use',
    }),
    totalExtensions: () => ({
      DE: 'Nebenstellen gesamt',
      EN: 'Total extensions',
    }),
    used: () => ({
      DE: 'Genutzt',
      EN: 'Used',
    }),
    free: () => ({
      DE: 'Frei',
      EN: 'Free',
    }),
    termination_multiple_1: (amount: string) => ({
      DE: ` ${amount} Nebenstellen `,
      EN: ` ${amount} extensions `,
    }),
    termination_multiple_2: (date: string) => ({
      DE: ` werden mit ${date} gekündigt`,
      EN: ` will be terminated by ${date}`,
    }),
    termination_single_1: (amount: string) => ({
      DE: ` ${amount} Nebenstelle `,
      EN: ` ${amount} extension `,
    }),
    termination_single_2: (date: string) => ({
      DE: ` wird mit ${date} gekündigt`,
      EN: ` will be terminated by ${date}`,
    }),
    ordered_multiple_1: (amount: string) => ({
      DE: ` ${amount} Nebenstellen `,
      EN: ` ${amount} extensions `,
    }),
    ordered_multiple_2: () => ({
      DE: ` wurden bestellt`,
      EN: ` have been ordered`,
    }),
    ordered_single_1: (amount: string) => ({
      DE: ` ${amount} Nebenstelle `,
      EN: ` ${amount} extension `,
    }),
    ordered_single_2: () => ({
      DE: ` wurde bestellt`,
      EN: ` has been ordered`,
    }),
    paginationSelectLabel: () => ({
      DE: 'Einträge pro Seite',
      EN: 'Items per page',
    }),
    paginationSliceInfo: (from: string, to: string, total: string) => ({
      DE: `${from}-${to} von ${total}`,
      EN: `${from}-${to} of ${total}`,
    }),
  },
  extensionsModal: {
    editExtensions: () => ({
      DE: 'Nebenstellen bearbeiten',
      EN: 'Edit Extensions',
    }),
    modalText: () => ({
      DE: 'Bitte wählen Sie aus, ob Sie die Anzahl der Nebenstellen mehren oder mindern möchten.',
      EN: 'Please select whether you want to increase or decrease the number of extensions.',
    }),
    reduce: () => ({
      DE: 'Mindern',
      EN: 'Reduce',
    }),
    reduceText: () => ({
      DE: 'Die Anzahl der Nebenstellen wird verringert',
      EN: 'The number of extensions will be reduced',
    }),
    number: () => ({
      DE: 'Anzahl',
      EN: 'Amount',
    }),
    increase: () => ({
      DE: 'Mehren',
      EN: 'Increase',
    }),
    increaseText: () => ({
      DE: 'Die Anzahl der Nebenstellen wird vergrößert',
      EN: 'The number of extensions will be increased',
    }),
    extensionsTotal: () => ({
      DE: 'Nebenstellen gesamt',
      EN: 'Extensions total',
    }),
    used: () => ({
      DE: 'Genutzt',
      EN: 'Used',
    }),
    free: () => ({
      DE: 'Frei',
      EN: 'Available',
    }),
    errorIncrease: () => ({
      DE: 'Die Anzahl der zu mehrenden Nebenstellen muss zwischen 1 und 200 liegen.',
      EN: 'To increase the amount of your extension licenses, please chose a number between 1 and 200.',
    }),
    errorReduce: (openExtensions: string) => ({
      DE: `Die Anzahl der zu mindernden Nebenstellen muss zwischen 1 und ${openExtensions} liegen`,
      EN: `To reduce the amount of your extension licenses, please chose a number between 1 and ${openExtensions}.`,
    }),
    errorNoExtensions: () => ({
      DE: `Sie besitzen keine kündigbaren Nebenstellen`,
      EN: `There are no extensions available to reduce`,
    }),
    errorInvalidInput: () => ({
      DE: `Bitte geben sie eine Zahl größer 0 an`,
      EN: `Please input a value greater than 0`,
    }),
    confirmation: () => ({
      DE: 'Bestätigung',
      EN: 'Confirmation',
    }),
    difference: () => ({
      DE: 'Differenz',
      EN: 'Difference',
    }),
    unitPrice: () => ({
      DE: 'Einzelpreis pro Monat (netto)',
      EN: 'Unit price per month (net)',
    }),
    amount: () => ({
      DE: 'Betrag (netto)',
      EN: 'Amount (net)',
    }),
    confirmText: () => ({
      DE: 'Bitte bestätigen Sie Ihre Auswahl',
      EN: 'Please confirm your selection',
    }),
    infoIncreaseTitle: () => ({
      DE: 'Ihre verfügbaren Nebenstellen werden gemehrt',
      EN: 'Your available extensions are increased',
    }),
    infoIncreaseText: () => ({
      DE: 'Es stehen Ihnen sofort freie Nebenstellen-Lizenzen im System zur Verfügung.',
      EN: 'Your free extension licenses are immediately available in the system.',
    }),
    infoReduceTitle: (inputReduceValue: string) => ({
      DE: `Ihre verfügbaren Nebenstellen werden um ${inputReduceValue} gemindert`,
      EN: `Your available extensions will be reduced by ${inputReduceValue}`,
    }),
    infoReduceText: () => ({
      DE: 'Die Nebenstellen-Lizenzen werden mit Monatsende gekündigt.',
      EN: 'The extension licenses will be removed at the end of the current month.',
    }),
    termination: (amount: string) => ({
      DE: ` ${amount} Nebenstellen wurden zur Kündigung vorgemerkt`,
      EN: ` ${amount} extensions have been marked for termination`,
    }),
    termination_single: () => ({
      DE: `1 Nebenstelle wurde zur Kündigung vorgemerkt`,
      EN: `1 extension has been marked for termination`,
    }),
  },
  licenses: {
    title: () => ({
      DE: 'Lizenzen & Services',
      EN: 'Licenses & Services',
    }),
    subheading: () => ({
      DE: 'Verwalten Sie Ihre gebuchten Lizenzen',
      EN: 'Manage your purchased licenses & services',
    }),
    license: () => ({
      DE: 'Lizenz',
      EN: 'License',
    }),
    number: () => ({
      DE: 'Anzahl',
      EN: 'Quantity',
    }),
    invalidQuantity: () => ({
      DE: 'Ungültige Anzahl.',
      EN: 'Invald Quantity.',
    }),
    status: () => ({
      DE: 'Status',
      EN: 'Status',
    }),
    unlimited: () => ({
      DE: 'Unbegrenzt',
      EN: 'Unlimited',
    }),
    options: () => ({
      DE: 'Optionen',
      EN: 'Options',
    }),
    option: () => ({
      DE: 'Option',
      EN: 'Option',
    }),
    add: () => ({
      DE: 'Hinzubuchen',
      EN: 'Add',
    }),
    addSubTitlte: () => ({
      DE: 'Wählen Sie eine Option aus, die Sie hinzubuchen möchten.',
      EN: 'Select an option that you would like to add.',
    }),
    reduce: () => ({
      DE: 'Reduzieren',
      EN: 'Reduce',
    }),
    included: () => ({
      DE: 'Inkludiert',
      EN: 'Included',
    }),
    optionsEmptyState: () => ({
      DE: 'Aktuell können keine Optionen hinzugebucht werden.',
      EN: 'No options can currently be added.',
    }),
    addOption: () => ({
      DE: 'Option hinzubuchen',
      EN: 'Add option',
    }),
    order: () => ({
      DE: 'Kostenpflichtig bestellen',
      EN: 'Order now',
    }),
    titleSummary: () => ({
      DE: 'Bestätigung',
      EN: 'Summary',
    }),
    next: () => ({
      DE: 'Weiter',
      EN: 'Next',
    }),
    subTextSummary: () => ({
      DE: 'Bitte bestätigen Sie Ihre Auswahl.',
      EN: 'Please check and confirm your selected products and options.',
    }),
    dependencyText: (contractName: string) => ({
      DE: 'Nur in Kombination mit ' + contractName + ' buchbar.',
      EN: 'Can only be booked in combination with ' + contractName + '.',
    }),
    reduceConfirmTitle: (option: string, quantity: string) => ({
      DE: `Ihr/e ${option} wird/werden um ${quantity} reduziert.`,
      EN: `Your ${option} will be reduced by ${quantity}.`,
    }),
    reduceConfirmSubtitle: (available: string) => ({
      DE: `Es stehen Ihnen anschließend ${available} zur Verfügung.`,
      EN: `You will then have ${available} available.`,
    }),
    oneTime: () => ({
      DE: 'Einmalig',
      EN: 'One-time',
    }),
    monthly: () => ({
      DE: 'Monatlich',
      EN: 'Monthly',
    }),
    total: () => ({
      DE: 'Gesamt (Netto)',
      EN: 'Total (net)',
    }),
    successInfo: () => ({
      DE: 'Es kann einige Minuten dauern, bis Ihre Änderungen sichtbar werden.',
      EN: 'It may take a few minutes for your changes to become visible.',
    }),
    successTitle: () => ({
      DE: 'Änderung erfolgreich',
      EN: 'Change successful',
    }),
    errorTitle: () => ({
      DE: 'Änderung fehlgeschlagen',
      EN: 'Change failed',
    }),
    reduceSubTitle: () => ({
      DE: 'Bitte wählen Sie die Anzahl, die Sie reduzieren möchten.',
      EN: 'Please choose the number you want to reduce.',
    }),
    activeLicenses: () => ({
      DE: 'aktiven Lizenzen',
      EN: 'active licenses',
    }),
  },
  sites: {
    title: () => ({
      DE: 'Standorte',
      EN: 'Locations',
    }),
    subheading: () => ({
      DE: 'Verwalten Sie Ihre Standorte',
      EN: 'Manage your sites',
    }),
    site: () => ({
      DE: 'Standort',
      EN: 'Location',
    }),
    cityCountry: () => ({
      DE: 'Stadt, Ländererkennung',
      EN: 'City, country code',
    }),
    numberBlock: () => ({
      DE: 'Rufnummernblock',
      EN: 'Number block',
    }),
    status: () => ({
      DE: 'Status',
      EN: 'status',
    }),
    active: () => ({
      DE: 'aktiv',
      EN: 'active',
    }),
    ordered: () => ({
      DE: 'bestellt',
      EN: 'ordered',
    }),
    siteDetailsLabel: () => ({
      DE: 'Details zu',
      EN: 'Details regarding',
    }),
    package: () => ({
      DE: 'Minutenpaket',
      EN: 'Minute package',
    }),
    closeSiteDetails: () => ({
      DE: 'schließen',
      EN: 'close',
    }),
    options: () => ({
      DE: 'Optionen',
      EN: 'options',
    }),
    tableHeadingName: () => ({
      DE: 'Firmenname, Name',
      EN: 'Company name, name',
    }),
    tableHeadingStreet: () => ({
      DE: 'Straße',
      EN: 'Street',
    }),
    tableHeadingPostalCode: () => ({
      DE: 'PLZ',
      EN: 'Postal code',
    }),
    tableHeadingCity: () => ({
      DE: 'Ort',
      EN: 'City',
    }),
    tableHeadingCountry: () => ({
      DE: 'Land',
      EN: 'Country',
    }),
    tableHeadingType: () => ({
      DE: 'Anschlussart',
      EN: 'Connection type',
    }),

  },
  sipTrunk: {
    title: () => ({
      DE: 'SIP-Trunk',
      EN: 'SIP-Trunk',
    }),
    subheading: () => ({
      DE: 'Verwalten Sie Ihre gebuchten SIP-Trunks',
      EN: 'See your SIP-Trunks',
    }),
    name: () => ({
      DE: 'Name',
      EN: 'Name',
    }),
    languagePack: () => ({
      DE: 'Sprachpaket',
      EN: 'Concurrent voice channels',
    }),
    clipNoScreen: () => ({
      DE: 'CLIP no screening',
      EN: 'CLIP no screening',
    }),
    msTeams: () => ({
      DE: 'MS Teams',
      EN: 'MS Teams',
    }),
    addCnameTitle: () => ({
      DE: 'CNAME-Eintrag hinzufügen',
      EN: 'Add CNAME record',
    }),
    addCnameSubtitle: () => ({
      DE: 'Fügen Sie hier Ihren CNAME-Eintrag von MS Teams hinzu.',
      EN: 'Add your MS Teams CNAME record here.',
    }),
    status: () => ({
      DE: 'Status',
      EN: 'Status',
    }),
    markedForModification: () => ({
      DE: 'zur Änderung vorgemerkt',
      EN: 'marked for modification',
    }),
    terminationNotification: (product: string, date: string) => ({
      DE: `Achtung, das Produkt '${product}' läuft nur noch bis zum ${date}.`,
      EN: `Attention, your product '${product}' expires after ${date}.`,
    }),
  },
  editSipTrunk: {
    channelsAndOptions: () => ({
      DE: 'Sprachkanäle und Optionen',
      EN: 'Voice Channels and Options',
    }),
    subheading: () => ({
      DE: 'Bitte wählen Sie die Sprachkanäle und Optionen zu Ihrem SIP-Trunk.',
      EN: 'Please select the Voice Channels and Options for your SIP-Trunk.',
    }),
    voiceChannelPackages: () => ({
      DE: 'Sprachkanalpaket ändern',
      EN: 'Change Voice Channel packages',
    }),
    selectQuantity: () => ({
      DE: 'Sprachkanäle bearbeiten',
      EN: 'Edit your Voice Channels',
    }),
    selectQuantitySubheading: () => ({
      DE: 'Bearbeiten Sie die Anzahl der Sprachkanäle zu Ihrem Vertrag.',
      EN: 'Edit the number of Voice Channels for your contract.',
    }),
    titleSummary: () => ({
      DE: 'Bestätigung',
      EN: 'Summary',
    }),
    subTextSummary: () => ({
      DE: 'Bitte bestätigen Sie Ihre Auswahl.',
      EN: 'Please check and confirm your selected products and options.',
    }),
    singlePrice: () => ({
      DE: 'Einzelpreis',
      EN: 'Single Price',
    }),
    total: () => ({
      DE: 'Gesamt',
      EN: 'Total',
    }),
    totalNet: () => ({
      DE: 'Gesamt (Netto)',
      EN: 'Total (net)',
    }),
    pricePerMonth: () => ({
      DE: 'Monatlich',
      EN: 'Monthly fee',
    }),
    month: () => ({
      DE: 'Monat',
      EN: 'month',
    }),
    options: () => ({
      DE: 'Optionen',
      EN: 'Options',
    }),
    amount: () => ({
      DE: 'Anzahl',
      EN: 'Amount',
    }),
    product: () => ({
      DE: 'Produkt',
      EN: 'Product',
    }),
    net: () => ({
      DE: 'Netto',
      EN: 'net',
    }),
    select: () => ({
      DE: 'Auswählen',
      EN: 'Select',
    }),
    next: () => ({
      DE: 'Weiter',
      EN: 'Next',
    }),
    errorIncrease: (min: string, max: string) => ({
      DE: `Die Anzahl der zu mehrenden Sprachkanäle muss zwischen ${min} und ${max} liegen.`,
      EN: `To increase the amount of your channels, please chose a number between ${min} and ${max}.`,
    }),
    errorReduce: (min: string, max: string) => ({
      DE: `Die Anzahl der zu mindernden Sprachkanäle muss zwischen ${min} und ${max} liegen.`,
      EN: `To reduce the amount of your channels, please chose a number between ${max} and ${max}.`,
    }),
    currentChannelText: () => ({
      DE: 'Ihr aktuelles Sprachpaket',
      EN: 'Your current package',
    }),
    voiceChannels: () => ({
      DE: 'Sprachkanäle',
      EN: 'Channels',
    }),
    changeQuantityOfChannels: () => ({
      DE: 'Anzahl der Sprachkanäle ändern',
      EN: 'Change amount of channels',
    }),
    current: () => ({
      DE: 'Aktuell',
      EN: 'Current',
    }),
    changeChannelTitle: () => ({
      DE: 'Sprachkanäle ändern',
      EN: 'Change channels',
    }),
    changeChannelText: () => ({
      DE: 'Bitte wählen Sie aus, ob Sie die Anzahl der Sprachkanäle mehren oder mindern möchten.',
      EN: 'Please select whether you would like to increase or decrease the number of voice channels.',
    }),
    previousQty: () => ({
      DE: 'Bisherige Anzahl',
      EN: 'Previous Amount',
    }),
    newQty: () => ({
      DE: 'Neue Anzahl',
      EN: 'New Amount',
    }),
    reduce: () => ({
      DE: 'Mindern',
      EN: 'Reduce',
    }),
    reduceText: () => ({
      DE: 'Die Anzahl der Sprachkanäle wird verringert',
      EN: 'The number of channels will be reduced',
    }),
    increase: () => ({
      DE: 'Mehren',
      EN: 'Increase',
    }),
    increaseText: () => ({
      DE: 'Die Anzahl der Sprachkanäle wird vergrößert',
      EN: 'The number of channels will be increased',
    }),
    edit: () => ({
      DE: 'Ändern',
      EN: 'Edit',
    }),
  },
  yourPartner: {
    partnerHeading: () => ({
      DE: 'Mein Partner',
      EN: 'Your partner',
    }),
    support: () => ({
      DE: 'Support',
      EN: 'Support',
    }),
    contract: () => ({
      DE: 'Vertrag',
      EN: 'Contract',
    }),
    servicePartner: () => ({
      DE: 'Servicepartner',
      EN: 'Service Partner',
    }),
    salesPartner: () => ({
      DE: 'Vertriebspartner',
      EN: 'Sales Partner',
    }),
    serviceAndSalesPartner: () => ({
      DE: 'Service- & Vertriebspartner',
      EN: 'Service & Sales Partner',
    }),
    yourContactPerson: () => ({
      DE: 'Mein Ansprechpartner',
      EN: 'Your Contact Person',
    }),
    fieldRequired: () => ({
      DE: 'Das ist ein Pflichtfeld',
      EN: 'This is a mandatory field',
    }),
    myServicePin: () => ({
      DE: 'Meine Service PIN',
      EN: 'My service PIN',
    }),
    showPin: () => ({
      DE: 'PIN anzeigen',
      EN: 'Show PIN',
    }),
    hidePin: () => ({
      DE: 'PIN verbergen',
      EN: 'Hide PIN',
    }),
    invalidPin: () => ({
      DE: 'Ungültiges Format. Bitte verwenden Sie eine andere PIN.',
      EN: 'Invalid format. Please try a different PIN.',
    }),
    noPinAvailable: () => ({
      DE: 'Keine Service PIN vorhanden',
      EN: 'No service PIN available',
    }),
    save: () => ({
      DE: 'Speichern',
      EN: 'Save',
    }),
    add: () => ({
      DE: 'PIN hinzufügen',
      EN: 'Add PIN',
    }),
    pinChangedSuccess: () => ({
      DE: 'Ihre Service PIN wurde erfolgreich geändert',
      EN: 'Your service PIN has been successfully changed',
    }),
    pinChangedFailedTitle: () => ({
      DE: 'Ihre Service PIN konnte nicht geändert werden',
      EN: 'Your service PIN could not be changed',
    }),
    pinChangedFailedTryAgain: () => ({
      DE: 'Bitte versuchen Sie es erneut.',
      EN: 'Please try again.',
    }),
  },
  myContactPerson: {
    contactPersonHeading: () => ({
      DE: 'Mein Ansprechpartner',
      EN: 'My Contact Person',
    }),
    support: () => ({
      DE: 'Support',
      EN: 'Support',
    }),
    contract: () => ({
      DE: 'Vertrag',
      EN: 'Contract',
    }),
  },
  customers: {
    migration: () => ({
      DE: 'Migration',
      EN: 'Migration',
    }),
    customerId: () => ({
      DE: 'Kunden ID',
      EN: 'Customer ID',
    }),
    title: () => ({
      DE: 'Kunden',
      EN: 'Customers',
    }),
    emptyStateSubTitle: () => ({
      DE: 'Fügen Sie Kunden hinzu',
      EN: 'Add Customers',
    }),
    addButton: () => ({
      DE: 'Hinzufügen',
      EN: 'Add',
    }),
    addCustomer: () => ({
      DE: 'Neukunde',
      EN: 'New customer',
    }),
    tableHeaderCustomer: () => ({
      DE: 'Kunde',
      EN: 'Customer',
    }),
    tableHeaderLocation: () => ({
      DE: 'Standort',
      EN: 'Location',
    }),
    tableHeaderCustomerId: () => ({
      DE: 'Kundennr.',
      EN: 'Customer ID',
    }),
    tableHeaderCreatedAt: () => ({
      DE: 'Angelegt am',
      EN: 'Created at',
    }),
    tableHeaderStatus: () => ({
      DE: 'Status',
      EN: 'Status',
    }),
    paginationSelectLabel: () => ({
      DE: 'Einträge pro Seite',
      EN: 'Items per page',
    }),
    paginationSliceInfo: (from: string, to: string, total: string) => ({
      DE: `${from}-${to} von ${total}`,
      EN: `${from}-${to} of ${total}`,
    }),
    partnerVOErrorMessage: () => ({
      DE: 'Keine VO Nummer vorhanden.',
      EN: 'No VO Number found.',
    })
  },
  flatrate: {
    title: () => ({
      DE: 'Flatrate',
      EN: 'Flatrate',
    }),
  },
  workflowTitle: {
    wfiTitle_3001: () => ({
      DE: 'Auftrag erhalten - Bestätigung verschickt',
      EN: 'Order received - confirmation sent',
    }),
    wfiTitle_2500_1: () => ({
      DE: 'Auftragsprüfung erfolgreich',
      EN: 'Order verification successful',
    }),
    wfiTitle_2500_3: () => ({
      DE: 'Auftrag abgelehnt wegen Bonität',
      EN: 'Order rejected due to creditworthiness',
    }),
    wfiTitle_2500_4: () => ({
      DE: 'warte auf Kaution',
      EN: 'waiting for deposit',
    }),
    wfiTitle_1046: () => ({
      DE: 'Technische Anlage erstellt',
      EN: 'Technical system created',
    }),
    wfiTitle_1005: () => ({
      DE: 'Standortnachweis erfolgreich geprüft',
      EN: 'Location verification successfully checked',
    }),
    wfiTitle_1095: () => ({
      DE: 'Rufnummer bereitgestellt',
      EN: 'Phone number provided',
    }),
    wfiTitle_1080: () => ({
      DE: 'Konfiguration abgeschlossen',
      EN: 'Configuration completed',
    }),
    wfiTitle_3007: () => ({
      DE: 'Vertrag aktiviert',
      EN: 'Contract activated',
    }),
  },
  wholesalersEvnNav:{
    wholesaler: () => ({
      DE: 'wholesaler',
      EN: 'wholesaler',
    }),
  },
  partnerNav: {
    customers: () => ({
      DE: 'Kunden',
      EN: 'Customers',
    }),
    orders: () => ({
      DE: 'Bestellungen',
      EN: 'Orders',
    }),
    tasks: () => ({
      DE: 'Aufgaben',
      EN: 'Tasks',
    }),
    contract: () => ({
      DE: 'Vertrag',
      EN: 'Contract',
    }),
    contracts: () => ({
      DE: 'Verträge',
      EN: 'Contracts',
    }),
    data: () => ({
      DE: 'Daten',
      EN: 'Data',
    }),
    extensions: () => ({
      DE: 'Nebenstellen',
      EN: 'Extensions',
    }),
    contactHistory: () => ({
      DE: 'Kontaktverlauf',
      EN: 'Contact History',
    }),
    createQuote: () => ({
      DE: 'Angebotserstellung',
      EN: 'Create a quote',
    }),
    monthlyCharges: () => ({
      DE: 'Monatliche Services',
      EN: 'Monthly services',
    }),
    monthlyChargesToCancel: () => ({
      DE: 'Vorgemerkte Kündigung',
      EN: 'Requested Cancellation',
    }),
  },
  partnerCustomer: {
    customerName: () => ({
      DE: 'Kundenname',
      EN: 'Customer name',
    }),
    customerNumber: () => ({
      DE: 'KdNr.',
      EN: 'Customer number',
    }),
    orderNumber: () => ({
      DE: 'Auftragsnummer',
      EN: 'Order number',
    }),
    contractNumber: () => ({
      DE: 'K-Nummer',
      EN: 'K-Account',
    }),
    overviewCustomerData: () => ({
      DE: 'Erhalten Sie hier eine Übersicht zu Ihrem Kunden',
      EN: 'Get an overview of your customer here',
    }),
    overviewCustomerContractDetails: () => ({
      DE: 'Erhalten Sie Details zu dem Vertrag',
      EN: 'Get details on the contract',
    }),
    contractStart: () => ({
      DE: 'Vertragsbeginn',
      EN: 'Start of contract',
    }),
    contractEnd: () => ({
      DE: 'Vertragsende',
      EN: 'End of contract',
    }),
    customerSite: () => ({
      DE: 'Standort',
      EN: 'Site',
    }),
  },
  partnerPages: {
    data: {
      title: () => ({
        DE: 'Daten',
        EN: 'Data',
      }),
      edit: () => ({
        DE: 'Bearbeiten',
        EN: 'Edit',
      }),
      subheading: () => ({
        DE: 'Erhalten Sie Details zu den Daten',
        EN: 'Get details on the data',
      }),
      preferredContact: () => ({
        DE: 'Bevorzugter Kontakt',
        EN: 'Preferred contact',
      }),
      language: () => ({
        DE: 'Sprache',
        EN: 'Language',
      }),
      preferredMethodOfContact: () => ({
        DE: 'Bevorzugte Kontaktmethode',
        EN: 'Preferred contact method',
      }),
      contractAccount: () => ({
        DE: 'Vertragskonto',
        EN: 'Contract account',
      }),
      payment: () => ({
        DE: 'Zahlung',
        EN: 'Payment method',
      }),
      billingAddress: () => ({
        DE: 'Rechnungsadresse',
        EN: 'Billing address',
      }),
      taxData: () => ({
        DE: 'Steuerdaten',
        EN: 'Tax data',
      }),
      settledContracts: () => ({
        DE: 'Abgerechnete Verträge',
        EN: 'Billed contracts',
      }),
      billingMedium: () => ({
        DE: 'Rechnungsmedium',
        EN: 'Billing medium',
      }),
      currency: () => ({
        DE: 'Währung',
        EN: 'Currency',
      }),
      EVNDeletionPeriod: () => ({
        DE: 'EVN Löschfrist',
        EN: 'CDR deletion period',
      }),
      EVNArt: () => ({
        DE: 'EVN Art',
        EN: 'CDR type',
      }),
      paymentTerm: () => ({
        DE: 'Zahlungsziel',
        EN: 'Payment term',
      }),
      german: () => ({
        DE: 'Deutsch',
        EN: 'German',
      }),
      mainContract: () => ({
        DE: 'Hauptvertrag',
        EN: 'Main contract',
      }),
    },
    sites: {
      title: () => ({
        DE: 'Standorte',
        EN: 'Sites',
      }),
      subheading: () => ({
        DE: 'Erhalten Sie Details zu den Standorten',
        EN: 'Get details on the sites',
      }),
    },
    numbers: {
      title: () => ({
        DE: 'Rufnummern',
        EN: 'Numbers',
      }),
      subheading: () => ({
        DE: 'Erhalten Sie Details zu den Rufnummern',
        EN: 'Get details on the numbers',
      }),
    },
    sipTrunk: {
      title: () => ({
        DE: 'SIP-Trunk',
        EN: 'SIP-Trunk',
      }),
      subheading: () => ({
        DE: 'Erhalten Sie Details zu den SIP-Trunks',
        EN: 'Get details on the SIP-Trunks',
      }),
    },
    packages: {
      title: () => ({
        DE: 'Minutenpakete',
        EN: 'Minute packages',
      }),
      subheading: () => ({
        DE: 'Erhalten Sie Details zu den Minutenpaketen',
        EN: 'Get details on the minute packages',
      }),
    },
    flatrate: {
      title: () => ({
        DE: 'Flatrate',
        EN: 'Flatrate',
      }),
      subheading: () => ({
        DE: 'Erhalten Sie Details zu den Flatrates',
        EN: 'Get details on the flatrates',
      }),
    },
    licenses: {
      title: () => ({
        DE: 'Lizenzen & Services',
        EN: 'Licenses & Services',
      }),
      subheading: () => ({
        DE: 'Erhalten Sie Details zu den Lizenzen & Services',
        EN: 'Get details on the licenses & services',
      }),
    },
    extensions: {
      title: () => ({
        DE: 'Nebenstellen',
        EN: 'Extensions',
      }),
      subheading: () => ({
        DE: 'Erhalten Sie Details zu den Nebenstellen',
        EN: 'Get details on the extensions',
      }),
      customize: () => ({
        DE: 'Anpassen',
        EN: 'Customize',
      }),
    },
    securitySettings: {
      title: () => ({
        DE: 'Security-Einstellungen',
        EN: 'Security-Settings',
      }),
      subheading: () => ({
        DE: 'Erhalten Sie Details zu den Security-Einstellungen',
        EN: 'Get details on the security settings',
      }),
    },
    orders: {
      title: () => ({
        DE: 'Bestellungen',
        EN: 'Orders',
      }),
      subheading: () => ({
        DE: 'Hier finden Sie alle Aufträge und Bestellungen',
        EN: 'Here you can find all contracts and orders',
      }),
    },
    tasks: {
      title: () => ({
        DE: 'Aufgaben',
        EN: 'Tasks',
      }),
      subheading: () => ({
        DE: 'Behalten Sie Ihre Aufgaben im Blick',
        EN: 'Keep your tasks in focus',
      }),
    },
    contactHistory: {
      title: () => ({
        DE: 'Kontaktverlauf',
        EN: 'Contact History',
      }),
      subheading: () => ({
        DE: 'Hier finden Sie den Kontaktverlauf Ihres Kunden',
        EN: 'Here you can find the contact history of your customer',
      }),
    },
  },
  stepper: {
    newCustomer: {
      title: () => ({
        DE: 'Neukunde',
        EN: 'New Customer',
      }),
      migration: () => ({
        DE: 'Migration',
        EN: 'Migration',
      }),
      migrationWaitMsgTitle: () => ({
        DE: 'Daten werden abgerufen',
        EN: 'Data is being retrieved',
      }),
      migrationWaitMsgText: () => ({
        DE: 'Bitte haben Sie einen Augenblick Geduld.',
        EN: 'Please be patient for a moment.',
      }),
      titleAddMainProduct: () => ({
        DE: 'Bestandskunde',
        EN: 'Existing customer',
      }),
      titleAddCustomerAndQuote: () => ({
        DE: 'Angebotserstellung',
        EN: 'Quote Creation',
      }),
      basicData: () => ({
        DE: 'Basisdaten und Hauptadresse',
        EN: 'Main data and main address',
      }),
      billingAndPayment: () => ({
        DE: 'Rechnung und Zahlung',
        EN: 'Billing and payment',
      }),
      customerDataSubTitle: () => ({
        DE: 'Bitte geben Sie hier alle Kontakt- und Rechnungsdaten zum Kunden an.',
        EN: 'Please enter here all contact and billing data for the customer.',
      }),
      mainData: () => ({
        DE: 'Basisdaten',
        EN: 'Main Data',
      }),
      legalForm: () => ({
        DE: 'Rechtsform',
        EN: 'Legal Form',
      }),
      salutation: () => ({
        DE: 'Anrede',
        EN: 'Salutation',
      }),
      companyName1: () => ({
        DE: 'Firmenname 1',
        EN: 'Company Name 1',
      }),
      companyName2: () => ({
        DE: 'Firmenname 2',
        EN: 'Company Name 2',
      }),
      vatNr: () => ({
        DE: 'UST Nr.',
        EN: 'VAT No',
      }),
      email: () => ({
        DE: 'E-Mail',
        EN: 'E-Mail',
      }),
      phoneNumber: () => ({
        DE: 'Festnetznummer',
        EN: 'Phone Number (Fixed Net)',
      }),
      mobileNumber: () => ({
        DE: 'Mobilfunknummer',
        EN: 'Mobile',
      }),
      countryCode: () => ({
        DE: 'Landvorwahl',
        EN: 'Country code',
      }),
      areaCode: () => ({
        DE: 'Vorwahl',
        EN: 'Area code',
      }),
      error: () => ({
        DE: 'Fehler',
        EN: 'Error',
      }),
      errorWhileLoadingCallNumbers: () => ({
        DE: 'Fehler beim Laden der Rufnummern und Tarife.',
        EN: 'Error loading callnumbers and tariffs.',
      }),
      cli: () => ({
        DE: 'Rufnummer',
        EN: 'Phone Number',
      }),
      mobileLabelSMS: () => ({
        DE: 'An diese Nummer wird die Zugangs-SMS versendet',
        EN: 'The access SMS will be sent to this number',
      }),
      contactLanguage: () => ({
        DE: 'Kontaktsprache',
        EN: 'Contact Language',
      }),
      companyNumber: () => ({
        DE: 'Firmennummer',
        EN: 'Company Number',
      }),
      fieldRequired: () => ({
        DE: 'Das ist ein Pflichtfeld',
        EN: 'This is a mandatory field',
      }),
      countryCodeRequired: () => ({
        DE: 'Bitte geben Sie die Landesvorwahl an.',
        EN: 'Please enter the country code.',
      }),
      countryCodeSyntax: () => ({
        DE: 'Die Landesvorwahl muss mit + oder 00 beginnen. Die maximale Länge beträgt 4 Zeichen.',
        EN: 'The country code must start with + or 00. The maximum length is 4 characters.',
      }),
      areaCodeRequired: () => ({
        DE: 'Geben Sie bitte die Vorwahl ein.',
        EN: 'Please enter the area code.',
      }),
      areaCodeSyntax: () => ({
        DE: 'Die maximale Länge beträgt 6 Zeichen.',
        EN: 'The maximum length for the area code is 6 characters.',
      }),
      cliRequired: () => ({
        DE: 'Geben Sie bitte die Rufnummer ein.',
          EN: 'Please enter the phone number.',
      }),
      cliSyntax: () => ({
        DE: 'Die maximale Länge beträgt 50 Zeichen.',
        EN: 'The maximum length for the phone number is 50 characters.',
      }),
      emailSyntax: () => ({
        DE: 'Geben Sie bitte eine gültige E-Mail Adresse ein.',
        EN: 'Please enter a valid e-mail address.',
      }),
      ibanSyntax: () => ({
        DE: 'Ungültiges IBAN-Format',
        EN: 'Invalid IBAN format',
      }),
      validEUSepa: () => ({
        DE: 'Die eingegebene IBAN ist nicht EU-SEPA gültig.',
        EN: 'IBAN is not EU-SEPA valid.',
      }),
      bicSyntax: () => ({
        DE: 'Ungültiges BIC-Format',
        EN: 'Invalid BIC format',
      }),
      registeredAddress: () => ({
        DE: 'Hauptadresse',
        EN: 'Registered Address',
      }),
      country: () => ({
        DE: 'Land',
        EN: 'Country',
      }),
      street: () => ({
        DE: 'Straße',
        EN: 'Street',
      }),
      houseNumberOrName: () => ({
        DE: 'Hausnummer oder -name',
        EN: 'House number or name',
      }),
      houseNumber: () => ({
        DE: 'Hausnummer',
        EN: 'Number',
      }),
      additionalAddress: () => ({
        DE: 'Adresszusatz',
        EN: 'Addition to Address',
      }),
      postcode: () => ({
        DE: 'Postleitzahl',
        EN: 'Postcode',
      }),
      city: () => ({
        DE: 'Ort',
        EN: 'City',
      }),

      city2: () => ({
        DE: 'Gemeinde (Gmina)',
        EN: 'Municipality (Gmina)',
      }),

      city3: () => ({
        DE: 'Verwaltungsbezirk (Województwo)',
        EN: 'Administrative district (Województwo)',
      }),

      district: () => ({
        DE: 'Landkreis/Bezirk (Powiat)',
        EN: 'District (Powiat)',
      }),

      county: () => ({
        DE: 'Ortsteil',
        EN: 'County',
      }),
      province: () => ({
        DE: 'Provinz',
        EN: 'Province',
      }),
      streetInfoBoxText: () => ({
        DE: 'Straße oder Postfach; bei einem Postfach muss \'Postfach\' der Nummer vorangestellt sein.',
        EN: 'Street or P.O. Box; for a P.O. Box, \'Postfach\' must precede the number.'
      }),
      contactPersons: () => ({
        DE: 'Kontaktpersonen',
        EN: 'Contact Persons',
      }),
      billingInformation: () => ({
        DE: 'Rechnung und Zahlung',
        EN: 'Invoice and Payment',
      }),
      paymentOption: () => ({
        DE: 'Zahlungsart',
        EN: 'Payment method',
      }),
      paymentOptionInfoBoxText: () => ({
        DE: 'Sollte in den Originalvertragsdokumenten kein unterschriebenes SEPA Mandat vorhanden sein, muss ein seperates SEPA Mandat hochgeladen werden. (Nur für Zahlungsart SEPA-Lastschrift)',
        EN: 'If there is no signed SEPA mandate in the original contract documents, a separate SEPA mandate must be uploaded. (Only for SEPA Direct Debit payment method)'
      }),
      billingMedium: () => ({
        DE: 'Rechnungsmedium',
        EN: 'Invoice medium',
      }),
      accountOwner: () => ({
        DE: 'Kontoinhaber',
        EN: 'Account holder',
      }),
      iban: () => ({
        DE: 'IBAN',
        EN: 'IBAN',
      }),
      bic: () => ({
        DE: 'BIC',
        EN: 'BIC',
      }),
      differentBillingAddress: () => ({
        DE: 'Abweichende Rechnungsadresse',
        EN: 'Different billing address',
      }),
      differentContactPerson: () => ({
        DE: 'Abweichender kaufmännischer Portal-User',
        EN: 'Different commercial portal user',
      }),
      billingEmail: () => ({
        DE: 'Rechnungsemail',
        EN: 'Billing Email',
      }),
      deliveryAddress: () => ({
        DE: 'Lieferadresse',
        EN: 'Delivery Address',
      }),
      locations: () => ({
        DE: 'Standorte',
        EN: 'Sites',
      }),
      chooseOneSipTrunkIdiot: () => ({
        DE: 'Es muss mindestens ein SIP Trunk ausgewählt werden.',
        EN: 'Please choose at least one SIP Trunk.',
      }),
      titleHintModal: () => ({
        DE: 'Hinweise zur Migration',
        EN: 'Hints on migration',
      }),
      accept: () => ({
        DE: 'Akzeptieren',
        EN: 'Accept',
      }),
      cancelMigrationOrderEntry: () => ({
        DE: 'Migrationsprozess abbrechen',
        EN: 'Cancel Migration Process',
      }),
      locationsSubtitle: () => ({
        DE: 'Hier finden Sie die gespeicherten Standorte.',
        EN: 'Here you can find your sites.',
      }),
      newSiteHeading: () => ({
        DE: 'Standort erfassen',
        EN: 'Add Site',
      }),
      editSiteHeading: () => ({
        DE: 'Standort bearbeiten',
        EN: 'Edit Site',
      }),
      deleteSiteHeading: () => ({
        DE: 'Standort entfernen',
        EN: 'Delete Site',
      }),
      deleteSiteText: () => ({
        DE: 'Sind Sie sicher, dass Sie diesen Standort entfernen möchten?',
        EN: 'Are you sure you want to delete this site?',
      }),
      locationsTableHeadingName: () => ({
        DE: 'Firmenname',
        EN: 'Company name',
      }),
      locationsTableHeadingStreet: () => ({
        DE: 'Adresse',
        EN: 'Address',
      }),
      locationsTableHeadingAddress: () => ({
        DE: 'Adresse',
        EN: 'Address',
      }),
      locationsTableHeadingPostalCode: () => ({
        DE: 'PLZ',
        EN: 'Postal code',
      }),
      locationsTableHeadingCity: () => ({
        DE: 'Ort',
        EN: 'City',
      }),
      locationsTableHeadingCountry: () => ({
        DE: 'Land',
        EN: 'Country',
      }),
      productSelection: (optionalPrefix: string) => ({
        DE: `Produktauswahl${optionalPrefix}`,
        EN: `Product Selection${optionalPrefix}`,
      }),
      productSelectionText: () => ({
        DE: 'Wählen Sie ein Produkt.',
        EN: 'Please choose a product.',
      }),
      shoppingCart: () => ({
        DE: 'Warenkorb',
        EN: 'Shopping cart',
      }),
      totalNet: () => ({
        DE: 'Gesamt (Netto)',
        EN: 'Total (net)',
      }),
      oneTimeNet: () => ({
        DE: 'Einmalig (netto)',
        EN: 'One-time (net)',
      }),
      monthlyNet: () => ({
        DE: 'Monatlich (netto)',
        EN: 'Monthly (net)',
      }),
      product: () => ({
        DE: 'Produkt',
        EN: 'Product',
      }),
      quantity: () => ({
        DE: 'Menge',
        EN: 'Quantity',
      }),
      oneTime: () => ({
        DE: 'Einmalig',
        EN: 'One-time',
      }),
      monthly: () => ({
        DE: 'Monatlich',
        EN: 'Monthly',
      }),
      perInvoice: () => ({
        DE: 'pro Rechnung',
        EN: 'per invoice',
      }),
      usageBased: () => ({
        DE: 'Usage-based',
        EN: 'Usage-based',
      }),
      productConfiguration: () => ({
        DE: 'Produkt konfigurieren',
        EN: 'Product Configuration',
      }),
      telephoneConnectionConfiguration: () => ({
        DE: 'Telefonanschluss konfigurieren',
        EN: 'Telephone-connection Configuration',
      }),
      productConfigurationSubTitle: () => ({
        DE: 'Bitte konfigurieren Sie Ihre Anschlüsse und Telefonanlage.',
        EN: 'Please configure your connections and telephone system.',
      }),
      activationHeading: () => ({
        DE: 'Aktivierung',
        EN: 'Activation',
      }),
      activationDateText: () => ({
        DE: 'Aktivierungsdatum',
        EN: 'Activation Date',
      }),
      activationDateImmediatelyText: () => ({
        DE: 'Sofort',
        EN: 'Immediately',
      }),
      activationDatePlannedText: () => ({
        DE: 'Geplant',
        EN: 'Planned',
      }),
      dateValidationText: () => ({
        DE: 'Das Datum kann nicht in der Vergangenheit liegen.',
        EN: 'The date cannot be in the past.',
      }),
      dateValidationMonthly: (exampleDate: string) => ({
        DE: `Das Produkt kann nur zum Ersten eines Monats geändert werden. (z.B. ${exampleDate})`,
        EN: `Product can only be changed on the first of the month. (e.g. ${exampleDate})`,
      }),
      addSipTrunkText: () => ({
        DE: 'SIP Trunk hinzufügen',
        EN: 'Add SIP Trunk',
      }),
      configureText: () => ({
        DE: 'Konfigurieren',
        EN: 'Configure',
      }),
      finaliseConfiguration: () => ({
        DE: 'Konfiguration abschließen',
        EN: 'Finalise configuration',
      }),
      goToContractDocuments: () => ({
        DE: 'Weiter zu Vertragsdokumente',
        EN: 'Next to contract documents',
      }),
      selectPackageText: () => ({
        DE: 'Wählen Sie ein Paket',
        EN: 'Select a package'
      }),
      quantityErrorMessage: (min: string, max: string) => ({
        DE: `Die Anzahl muss zwischen ${min} und ${max} liegen.`,
        EN: `The quantity must be between ${min} and ${max}.`,
      }),
      maxLengthErrorMessage: (max: string) => ({
        DE: `Die Zeichenlänge darf nicht länger als ${max} sein.`,
        EN: `The character length cannot be more than ${max}.`,
      }),
      mandatoryFieldsText: () => ({
        DE: 'Pflichtfelder',
        EN: 'Mandatory fields'
      }),
      includedServicesText: () => ({
        DE: 'Inkludierte Services',
        EN: 'Included Services'
      }),
      telephoneConnections: () => ({
        DE: 'Anschlüsse',
        EN: 'Connections',
      }),
      quantityText: () => ({
        DE: 'Anzahl',
        EN: 'Quantity',
      }),
      MSTeamsTXTRecordText: () => ({
        DE: 'MS Teams TXT Record',
        EN: 'MS Teams TXT Record',
      }),
      callNumberBlockModalSubTitle: () => ({
        DE: 'Bitte wählen Sie den Anschluss und Standort aus.',
        EN: 'Please select the connection and location.',
      }),
      site: () => ({
        DE: 'Standort',
        EN: 'Site',
      }),
      addBlock: () => ({
        DE: 'Weiteren Block hinzufügen',
        EN: 'Add another Block',
      }),
      invalidAreaCodeValidation: () => ({
        DE: 'Ungültige Vorwahl.',
        EN: 'Invalid area code.',
      }),
      invalidLocalAreaCodeValidation: () => ({
        DE: 'Ungültige Ortskennzahl.',
        EN: 'Invalid local area code.',
      }),
      invalidCallNumberValidation: () => ({
        DE: 'Ungültige Rufnummer.',
        EN: 'Invalid call number.',
      }),

      invalidCurrentProvider: () => ({
        DE: 'Ungültige Aktueller Anbieter.',
        EN: 'Invalid Current Provider.',
      }),

      multipleSubscriberNumbers: () => ({
        DE: 'Einzelrufnummer (MSN)',
        EN: 'Multiple Subscriber Numbers (MSN)',
      }),
      numberBlock: () => ({
        DE: 'Rufnummernblock',
        EN: 'Number block',
      }),
      localAreaCode: () => ({
        DE: 'Ortskennzahl',
        EN: 'Local area code',
      }),
      extensionNumber: () => ({
        DE: 'Durchwahl-Rufnummer',
        EN: 'extension number',
      }),
      console: () => ({
        DE: 'Abfragestelle',
        EN: 'console',
      }),
      consoleTip: () => ({
        DE: 'Die Abfragestelle ist die erste wählbare Rufnummer aus dem Block',
        EN: 'Die Abfragestelle ist die erste wählbare Rufnummer aus dem Block',
      }),
      from: () => ({
        DE: 'von',
        EN: 'from',
      }),
      to: () => ({
        DE: 'bis',
        EN: 'to',
      }),
      shortenOnConsole: () => ({
        DE: 'Verkürzung auf Abfragestelle',
        EN: 'Shorten On Console',
      }),
      portAllNumbers: () => ({
        DE: 'Alle Nummern',
        EN: 'Port all numbers',
      }),
      callNumber: () => ({
        DE: 'Rufnummer',
        EN: 'Call Number',
      }),
      maxCallNumberText: () => ({
        DE: 'Sie haben die maximale Anzahl für die Mitnahme von Einzelrufnummern erreicht.',
        EN: 'You have reached the maximum number for porting numbers.',
      }),
      documents: () => ({
        DE: 'Vertragsdokumente',
        EN: 'Documents',
      }),
      documentsEmptyStateSubTitle: () => ({
        DE: 'Fügen Sie Vertragsdokumente hinzu',
        EN: 'Add Documents',
      }),
      subtitleOfDocumentsStep: () => ({
        DE: 'Laden Sie hier die Vertragdokumente hoch',
        EN: 'Upload the contract documents here.',
      }),
      titleOfDocumentsStep: () => ({
        DE: 'Dokumente',
        EN: 'Documents',
      }),
      documentsTableHeadingFileName: () => ({
        DE: 'Datei',
        EN: 'File',
      }),
      documentsTypeContract: () => ({
        DE: 'Vertragsunterlagen',
        EN: 'Contract documents',
      }),
      documentsTypeSEPA: () => ({
        DE: 'SEPA-Mandat',
        EN: 'SEPA-Mandate',
      }),
      documentsTypeSite: () => ({
        DE: 'Standortnachweis',
        EN: 'Location evidence',
      }),
      documentsUploadFile: () => ({
        DE: 'Datei hochladen',
        EN: 'Upload file',
      }),
      proofOfSiteModalTitle: () => ({
        DE: 'Standortnachweis hinzufügen',
        EN: 'Add location evidence',
      }),
      proofOfSiteModalSubtitle: () => ({
        DE: 'Bitte wählen Sie den Standort und Nachweistypen aus.',
        EN: 'Please select the site and proof type.',
      }),
      proofType: () => ({
        DE: 'Nachweistyp',
        EN: 'Proof type',
      }),
      proofTypeBusinessRegistration: () => ({
        DE: 'Gewerbeanmeldung',
        EN: 'Business registration',
      }),
      proofTypeCommercialRegister: () => ({
        DE: 'Handeslregisterauszug',
        EN: 'Commercial register extract',
      }),
      proofTypeSalesTaxId: () => ({
        DE: 'Umsatzsteuer-ID',
        EN: 'Sales tax ID',
      }),
      proofTypeIdentityCard: () => ({
        DE: 'Personalausweis',
        EN: 'Identity card',
      }),
      proofTypePowerBill: () => ({
        DE: 'Strom-Rechnung',
        EN: 'Power bill',
      }),
      proofTypeAssociationRegister: () => ({
        DE: 'Vereinsregister-Auszug',
        EN: 'Association register extract',
      }),
      orderSummary: () => ({
        DE: 'Bestellübersicht',
        EN: 'Order Summary',
      }),
      orderSummarySubTitle: () => ({
        DE: 'Überprüfen Sie die Angaben zur Bestellung und schließen die Neukundenbestellung ab.',
        EN: 'Please check the order details to complete the order.',
      }),
      customerDetails: () => ({
        DE: 'Kundendaten',
        EN: 'Customer Details',
      }),
      contactPerson: () => ({
        DE: 'Ansprechpartner',
        EN: 'Contact Person',
      }),
      contactDetails: () => ({
        DE: 'Kontaktdaten',
        EN: 'Contact Details',
      }),
      payment: () => ({
        DE: 'Zahlung',
        EN: 'Payment',
      }),
      technicalContactPerson: () => ({
        DE: 'Technischer Ansprechpartner',
        EN: 'Technical contact person',
      }),
      businessContactPerson: () => ({
        DE: 'Kaufmännischer Ansprechpartner',
        EN: 'Commercial contact person',
      }),
      order: () => ({
        DE: 'Kostenpflichtig bestellen',
        EN: 'Order now',
      }),
      createQuote: () => ({
        DE: 'Angebot erstellen',
        EN: 'Create quote',
      }),
      infoPrices: () => ({
        DE: 'Die Preise können nach dem Erstellen des Angebots angepasst werden.',
        EN: 'The prices can be adjusted after the quote has been created.',
      }),
      technicalContactPersonUser: () => ({
        DE: 'Technischer Ansprechpartner (Admin Portal User)',
        EN: 'Technical contact (Admin Portal User)',
      }),
      businessContactPersonUser: () => ({
        DE: 'Kaufmännischer Portal-User',
        EN: 'Commercial portal user',
      }),
      login: () => ({
        DE: 'E-Mail',
        EN: 'E-Mail',
      }),
      firstName: () => ({
        DE: 'Vorname',
        EN: 'First name',
      }),
      lastName: () => ({
        DE: 'Nachname',
        EN: 'Last name',
      }),
      documentInfoMessage: () => ({
        DE: 'Bitte beachten Sie, dass im Laufe der Bestellung die Vertragsdokumente und Standortnachweise benötigt werden. Halten Sie diese bereit um die Bestellung abschließen zu können.',
        EN: 'Please note that contract documents and proof of location are mandatory during the order process.',
      }),
      migrationInfoMessage: () => ({
        DE: 'Das Produkt wird aktuell nicht für die Migration unterstützt und muss manuell bestellt werden.',
        EN: 'Please note that the product is not available for Migration and the order must be processed manually.',
      }),
      cancel: () => ({
        DE: 'Abrechen',
        EN: 'Cancel',
      }),
      apply: () => ({
        DE: 'Anwenden',
        EN: 'Apply',
      }),
      callNumberExtension: () => ({
        DE: 'Durchwahl-Rufnummer',
        EN: 'Extension number',
      }),
      blockFrom: () => ({
        DE: 'Blockbeginn',
        EN: 'Block from',
      }),
      blockTo: () => ({
        DE: 'Blockende',
        EN: 'Block to',
      }),
      blockSizeError: (blockTo: string) => ({
        DE: `Blockende muss ${blockTo} sein.`,
        EN: `Block to must be ${blockTo}.`,
      }),
      blockToError: () => ({
        DE: 'Blockende muss mit einer 9 enden.',
        EN: 'Block to must end with a 9.',
      }),
      blockFromError: () => ({
        DE: 'Blockbeginn muss mit einer 0 enden.',
        EN: 'Block from must end with a 0.',
      }),
      numberError: (name:string) => ({
        DE: `${name} kann nur aus Zahlen bestehen und max 10 Ziffern lang sein.`,
        EN: `${name} must only contain digits and must not be longer than 10 digits.`,
      }),
      plus20Days: () => ({
        DE: '+20 AT',
        EN: '+20 WD',
      }),
      migrationConfirmation: () => ({
        DE: 'Sind Sie sicher, dass Sie den Vertrag dieses Kunden migrieren möchten?',
        EN: 'Are you sure you want to migrate this customer\'s contract?',
      }),
    }
  },
  portingNumbersModal: {
    portingSubLine: () =>({
      DE: 'Bitte wählen Sie den Standort.',
      EN: 'Please select the location.',
    }),
    sites: () => ({
      DE: 'Standorte',
      EN: 'Locations',
    }),
    providerTo: () => ({
      DE: 'Anbieterwechselauftrag von',
      EN: 'Provider Change Order',
    }),
    confirmDeletePortingEntries: () => ({
      DE: 'Sind Sie sicher, dass Sie alle Portierungen aus dem Warenkorb löschen möchten?',
      EN: 'Are you sure you want to delete all portings from the basket?',
    }),
    uploadPortingDoc: () => ({
      DE: 'Es muss ein Portierungsformular hochgeladen werden.',
      EN: 'A porting form must be uploaded.',
    }),
    connection: () => ({
      DE: 'Anschluss',
      EN: 'Connection',
    }),
    switchContent: () => ({
      DE: 'Hiermit wird der Anschluss zu den unten angegebenen Daten zum nächstmöglichen Zeitpunkt gekündigt.',
      EN: 'The connection is hereby terminated as of the next possible date on the dates given below.',
    }),
    currentProvider: () => ({
      DE: 'Aktueller Anbieter',
      EN: 'Current Provider',
    }),
    customerNr: () => ({
      DE: 'Kundennr. (alt)',
      EN: 'CustomerNr (old)',
    }),
    resourceCheckBox: () => ({
      DE: 'Ressourcenübernahme',
      EN: 'Resource acquisition',
    }),
    addressHeadline: () => ({
      DE: 'Anschlussadresse',
      EN: 'Connection Address',
    }),
    connectionSubtitle: () => ({
      DE: 'Bitte überprüfen Sie die Daten anhand der Telefonrechnung des alten Anbieters.',
      EN: 'Please check the data against the phone bill from the old provider.',
    }),
    callNumberTitle:() => ({
      DE: 'Rufnummern',
      EN: 'Call numbers',
    }),
    areaCode:() => ({
      DE: 'Ortsnetzkennzahl',
      EN: 'Area code',

    }),
    AllNumbers: () => ({
      DE: 'Alle Nummern',
      EN: 'All numbers',

    }),
    phoneNumber: () => ({
      DE: 'Rufnummer',
      EN: 'phone number',

    }),
    add: () => ({
      DE: 'Hinzufügen',
      EN: 'Add',
    }),
    select: () => ({
      DE: 'Auswählen',
      EN: 'Select',
    }),
    document: () => ({
      DE: 'Dokument',
      EN: 'Document',
    }),
    documents: () => ({
      DE: 'Dokumente',
      EN: 'Documents',
    }),
    portingForm: () => ({
      DE: 'Portierungsformular',
      EN: 'Porting Form',
    }),

  }
};
