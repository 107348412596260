import React, { useEffect, useState } from "react";
import { CpxAddress } from "../../../../../core/components/address.component";
import { ReactComponent as Card } from "../../../../assets/icons/card.svg";
import { ReactComponent as Envelope } from "../../../../assets/icons/envelope.svg";
import { ReactComponent as Note } from "../../../../assets/icons/note.svg";
import { useSelectAccounts, useSelectFeatures, useSelectUserRole } from "../../../../redux/store";
import { TRANSLATIONS } from "../../../../constants/transitions/uiTranslations";
import { useTranslations } from "../../../../utils/helper/utils";
import "./billAccount.scss";
import { FEATURES_FLAG, ICONS, ID_PAYMENTMODE_BANKTRANSFER, ID_PAYMENTMODE_SEPA, USER_CUSTOMER, USER_CUSTOMER_TELEPHONY, USER_PARTNER, } from "../../../../constants/configs/config.constants";
import { useTheme } from "../../../../../core/utility/themeContext";
import cs from "classnames";
import { CpxButton } from "../../../../../core/components/button.component";
import { CpxIcon } from "../../../../../core/components/icon.component";
import { EditBillAccountModalComponent } from "./editBillAccountModal.component";
import { apiCallAction } from "../../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../../constants/action.constants";
import { useDispatch } from "react-redux";
import Cleave from "cleave.js/react";

export const NfoBillAccount = () => {
  const translations = useTranslations(TRANSLATIONS.myData);
  const internalClassName = "billAccount";
  const theme = useTheme();
  const userRole = useSelectUserRole();
  const billingAccounts: any = useSelectAccounts();
  const creditorAccount = billingAccounts?.find((account: any) => !account?.debit);
  const debitorAccount = billingAccounts?.find((account: any) => account?.debit);
  const features = useSelectFeatures();
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [editAccountObject, setEditAccountObject] = useState<any>({});

  const handleEditClick = (editObj: Object) => {
    setEditAccountObject(editObj);
    setShowModal(prevState => !prevState);
  }

  useEffect(() => {
    if (userRole === USER_CUSTOMER) {
      dispatch(apiCallAction(ACTION_CONST.API_PORTAL_FEATURE, { featureName: FEATURES_FLAG.PORTAL_ACCOUNT_DATA_CHANGE_CUSTOMER }))
    } else if (userRole === USER_PARTNER) {
      dispatch(apiCallAction(ACTION_CONST.API_PORTAL_FEATURE, { featureName: FEATURES_FLAG.PORTAL_ACCOUNT_DATA_CHANGE_PARTNER }))
    }
  }, [])

  return (
    <>
    { showModal && <EditBillAccountModalComponent setShowModal={setShowModal} billingAccount={editAccountObject}/>}
    {/* CUSTOMER */}
    {(userRole === USER_CUSTOMER || userRole === USER_CUSTOMER_TELEPHONY) && (Array.isArray(billingAccounts) && billingAccounts.length > 0 && (
      billingAccounts.map(option => (
        <article className={cs(internalClassName, `${internalClassName}-le--${theme}`)}>
          <div className={"columnContainer"}>
            <h4 className={cs('componentTitle', `componentTitle-le--${theme}`)}>
              {`${translations.account()} ${option.id}`}
            </h4>
          </div>
          <div className={"columnContainer"}>
            <div className={"column"}>
              <h5 className={`columnTitle columnTitle-le--${theme}`}>
                <Card />
                {translations.payment()}
                {features.PORTAL_ACCOUNT_DATA_CHANGE_CUSTOMER && <CpxButton className={"iconOnly"} type={'button'} onClick={() => handleEditClick(option)}><CpxIcon icon={ICONS.EDIT} /></CpxButton>}
              </h5>
              {option?.paymentMode.id + "" === ID_PAYMENTMODE_SEPA && <p className={`columnText-le--${theme}`}>{option?.paymentMean?.bankAccount?.accountHolder}</p>}
              {option?.paymentMode.id + "" === ID_PAYMENTMODE_SEPA && (
                <>
                  <p className={`columnText-le--${theme}`}>
                    <Cleave
                      className={'formattedIban'}
                      type={'text'}
                      disabled
                      options={{blocks: [4,4,4,4,4,4,4,4], delimiter: ' ', numericOnly: false}}
                      value={option?.paymentMean?.bankAccount?.iban}
                    >
                    </Cleave>
                  </p>
                  <p className={`columnText-le--${theme}`}>{option?.paymentMean?.bankAccount?.bic}</p>
                </>
              )}
              {option?.paymentMode.id + "" ===
                ID_PAYMENTMODE_BANKTRANSFER && <p>{translations.banktransfer()}</p>}
            </div>
            <div className={"column"}>
              <h5 className={`columnTitle columnTitle-le--${theme}`}>
                <Envelope />
                {translations.billAddress()}
              </h5>
              <CpxAddress className={`columnText-le--${theme}`} address={{ ...option?.billToAddress, email: option?.email }} />
            </div>
            <div className={"column"}>
              <div className={"column-item"}>
                <h5 className={`columnTitle columnTitle-le--${theme}`}>
                  <Note />
                  {translations.accountType()}
                </h5>
                <p className={`columnText-le--${theme}`}>
                  {option.debit ? translations.debitAccount() : translations.creditAccount()}
                </p>
              </div>
              <div className={"column-item"}>
                <h5 className={`columnTitle columnTitle-le--${theme}`}>
                  <Note />
                  {translations.paymentTarget()}
                </h5>
                <p className={`columnText-le--${theme}`}>
                  {option.paymentTarget?.description}
                </p>
              </div>
            </div>
          </div>
        </article>
      ))
    ))}


    {/* PARTNER */}
    {userRole === USER_PARTNER && <article className={cs(internalClassName, `${internalClassName}-le--${theme}`)}>
      <div className={"columnContainer"}>
        <h4 className={cs('componentTitle', `componentTitle-le--${theme}`)}>
          {translations.account()}
        </h4>
      </div>
      <div className={"columnContainer"}>
        <div className={"column"}>
          <h5 className={`columnTitle columnTitle-le--${theme}`}>
            <Card/>
            {translations.partnerCreditAccount()}
            {features.PORTAL_ACCOUNT_DATA_CHANGE_PARTNER && (creditorAccount && <CpxButton className={"iconOnly"} type={'button'} onClick={() => handleEditClick(creditorAccount)}><CpxIcon icon={ICONS.EDIT}/></CpxButton>)}
          </h5>
          {creditorAccount?.paymentMode.id + "" === ID_PAYMENTMODE_SEPA && <p className={`columnText-le--${theme}`}>{creditorAccount?.paymentMean?.bankAccount?.accountHolder}</p>}
          {creditorAccount?.paymentMode.id + "" === ID_PAYMENTMODE_SEPA && (
            <>
              <p className={`columnText-le--${theme}`}>{creditorAccount?.paymentMean?.bankAccount?.iban}</p>
              <p className={`columnText-le--${theme}`}>{creditorAccount?.paymentMean?.bankAccount?.bic}</p>
            </>
          )}
          {billingAccounts[0]?.paymentMode.id + "" ===
            ID_PAYMENTMODE_BANKTRANSFER && <p>{translations.banktransfer()}</p>}
          {!creditorAccount && <p>{translations.notAvailable()}</p>}
          <div className={"billAddress"}>
            <h5 className={`columnTitle columnTitle-le--${theme}`}>{translations.billAddress()}</h5>
            <CpxAddress className={`columnText-le--${theme}`} address={creditorAccount?.billToAddress}/>
          </div>
        </div>
        <div className={"column"}>
          <h5 className={`columnTitle columnTitle-le--${theme}`}>
            <Card/>
            {translations.partnerDebitAccount()}
            {features.PORTAL_ACCOUNT_DATA_CHANGE_PARTNER && (debitorAccount && <CpxButton className={"iconOnly"} type={'button'} onClick={() => handleEditClick(debitorAccount)}><CpxIcon icon={ICONS.EDIT}/></CpxButton>)}
          </h5>
          {debitorAccount?.paymentMode.id + "" === ID_PAYMENTMODE_SEPA && <p className={`columnText-le--${theme}`}>{debitorAccount?.paymentMean?.bankAccount?.accountHolder}</p>}
          {debitorAccount?.paymentMode.id + "" === ID_PAYMENTMODE_SEPA && (
            <>
              <p className={`columnText-le--${theme}`}>{debitorAccount?.paymentMean?.bankAccount?.iban}</p>
              <p className={`columnText-le--${theme}`}>{debitorAccount?.paymentMean?.bankAccount?.bic}</p>
            </>
          )}
          {billingAccounts[0]?.paymentMode.id + "" ===
            ID_PAYMENTMODE_BANKTRANSFER && <p>{translations.banktransfer()}</p>}
          {!debitorAccount && <p>{translations.notAvailable()}</p>}
          <div className={"billAddress"}>
            <h5 className={`columnTitle columnTitle-le--${theme}`}>{translations.billAddress()}</h5>
            <CpxAddress className={`columnText-le--${theme}`} address={debitorAccount?.billToAddress}/>
          </div>
        </div>
        <div className={"column"}></div>
      </div>
    </article>}
    </>
  );
};
