import classNames from "classnames";
import cs from "classnames";
import { useTheme } from "../../../../../core/utility/themeContext";
import './stepper.scss'
import React from "react";
import { NfoStepperFooterComponent } from "../footer/footer.component";

export type StepObj = {
  stepNumber: number,
  stepTitle: string
}
type Props = {
  active: number,
  steps: StepObj[]
};

export const NfoStepperComponent = ({ active, steps }: Props) => {
  const theme = useTheme();

  return (
    <>
      <div className={cs('stepper', `stepper-le--${theme}`)}>
        {steps.map((step: StepObj) => {

          let classNameItem = classNames(
            {
              'stepper-item': true,
              'stepper-current': step.stepNumber == 5 ? [5,6,7].includes(active) : step.stepNumber === active,
              'stepper-item-active': step.stepNumber == 5 ? active > 7 : step.stepNumber < active,
            })

          return ([6,7].includes(step.stepNumber) ?
              <>
              {[6,7].includes(active) && <div key={step.stepNumber} className={cs(classNameItem, 'item', `item-le--${theme}`)}>
                <div className={'line'}></div>
                <div className={'text'}>{step.stepTitle}</div>
              </div> }
              </>
              :
              <div key={step.stepNumber} className={cs(classNameItem, 'item', `item-le--${theme}`)}>
              <div className={'circle'}></div>
              <div className={'text'}>{step.stepTitle}</div>
            </div>
          )
        })}
      </div>
      <NfoStepperFooterComponent steps={steps}/>
    </>
  )
}
