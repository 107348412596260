import cs from "classnames";
import { CpxModal } from "../../../../../core/components/modal.component";
import React from "react";
import { useTheme } from "../../../../../core/utility/themeContext";
import { CpxPaginatedTable } from "../../../../../core/components/paginatedTable.component";
import { ID_DATA_ENTITY, ID_STATUS_SERVICES } from "../../../../constants/configs/config.constants";
import { CpxButton } from "../../../../../core/components/button.component";
import { PortalContractData } from "compax-api";
import { NfoStatus } from "../../../../../core/components/status.component";
import { setSelectedContract } from "../../../../redux/actions/config.action";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { useHistory } from "react-router-dom";
import { routes } from "../../../../constants/routes/routes";
import { useTranslations } from "../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../constants/transitions/uiTranslations";

type Props = {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  lockedContracts: any;
}

type AppDispatch = ThunkDispatch<any, any, AnyAction>;

export const SelectLockedContractModal = ({ setShowModal, lockedContracts }: Props) => {
  const internalClassName = "selectLockedContractModal"
  const theme = useTheme()
  const history = useHistory()
  const dispatch : AppDispatch = useDispatch();
  const translations = useTranslations(TRANSLATIONS.myContracts);

  const handleUnlockButtonClick = (contract: PortalContractData) => {
    setShowModal(false);
    dispatch(setSelectedContract(contract));
    history.push(routes.customerSecuritySettings.path)
  }

  const tableRowData = (mainContract: PortalContractData) => ({
    id: '' + mainContract.contractId,
    cellData: [
      <p>
        {mainContract.knumber}
      </p>,
      <div>
        {mainContract.contractName}
      </div>,
      <div>
        <NfoStatus
          entity={{ id: ID_DATA_ENTITY.SERVICE }}
          status={{ id: ID_STATUS_SERVICES.BARRED_INSTALLATION }}
        />
      </div>,
      <div>
        <CpxButton type={'button'} onClick={() => handleUnlockButtonClick(mainContract)}>{translations.unlock()}</CpxButton>
      </div>
    ],
  });

  return <CpxModal
    onCancel={() => setShowModal(false)}
    hideConfirmButton={true}
    hideCancelButton={true}
    size={"large"}
  >
    <>
      <h3 className={cs('modalTitle', `modalTitle-le--${theme}`)}>
        {translations.unlockInstallationsTitle()}
      </h3>
      <p className={`${internalClassName}-modalSubTitle`}>
        {translations.unlockInstallationsText()}
      </p>
      <CpxPaginatedTable
        className={cs(`${internalClassName}-table`)}
        id={internalClassName}
        tableHeader={[
          translations.kNumber(),
          translations.productName(),
          translations.status(),
          "",
        ]}
        tableData={lockedContracts?.map(tableRowData)}
      />
    </>
  </CpxModal>
}