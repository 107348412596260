import { CpxInputWithLabel } from "./inputWithLabel.component";
import { CpxPhoneInputWithLabel } from "./phoneInputWithLabel.component";
import React from "react";
import { useTranslations } from "../../portal/utils/helper/utils";
import { TRANSLATIONS } from "../../portal/constants/transitions/uiTranslations";
import './portalUserInputGroup.scss';
import cs from "classnames";
import { useTheme } from "../utility/themeContext";
import { CpxSwitch } from "./switch.component";
import { SelectOption } from "../../portal/constants/types/types.constants";
import { CpxDropdownWithLabel } from "./dropdown.component";


export const CommercialPortalUserInputGroup = ({ values, handleChange, touched, errors, isDifferentContactPerson, setIsDifferentContactPerson, salutationOptions, contactLanguageOptions}: any) => {
  const translation = useTranslations(TRANSLATIONS.stepper.newCustomer);
  const theme = useTheme();
  const internalClassName = "commercialPortalUserInputGroup"
  const translationsCommon = useTranslations(TRANSLATIONS.common);
  const DEFAULT_SELECT_OPTION: SelectOption = {
    id: '',
    name: translationsCommon.defaultSelect(),
  }

  values = values?.contactPersons?.commercialContactPerson
  touched = touched?.contactPersons?.commercialContactPerson && touched?.contactPersons?.commercialContactPerson
  errors = errors?.contactPersons?.commercialContactPerson && errors?.contactPersons?.commercialContactPerson

  return (
    <div className="admin-portal-user">
       <div className="line-grey"/>
       <h5 className={cs(`sub-title sub-title-le--${theme}`)}>{translation.businessContactPersonUser()} *</h5>
        <p className={`${internalClassName}-switch--wrapper`}>
          <CpxSwitch
            className={`${internalClassName}-switch`}
            onChange={() => setIsDifferentContactPerson(!isDifferentContactPerson)}
            checked={isDifferentContactPerson}
          />
          {translation.differentContactPerson()}
        </p>
      {isDifferentContactPerson && <div>
        <CpxInputWithLabel
          id="contactPersons.commercialContactPerson.email"
          type="text"
          onChange={handleChange}
          value={values?.email.trimStart()}
          error={touched?.email && errors?.email}
        >
          {translation.login() + " *"}
        </CpxInputWithLabel>
        <CpxDropdownWithLabel
          id="contactPersons.commercialContactPerson.salutation.id"
          name="contactPersons.commercialContactPerson.salutation.id"
          required={true}
          options={salutationOptions}
          defaultOption={DEFAULT_SELECT_OPTION}
          onChange={handleChange}
          value={values?.salutation?.id}
          error={errors?.salutation?.id}
        >
          {translation.salutation()}
        </CpxDropdownWithLabel>
        <CpxInputWithLabel
          id="contactPersons.commercialContactPerson.firstName"
          type="text"
          onChange={handleChange}
          value={values?.firstName.trimStart()}
          error={touched?.firstName && errors?.firstName}
        >
          {translation.firstName() + " *"}
        </CpxInputWithLabel>
        <CpxInputWithLabel
          id="contactPersons.commercialContactPerson.lastName"
          type="text"
          onChange={handleChange}
          value={values?.lastName.trimStart()}
          error={touched?.lastName && errors?.lastName}
        >
          {translation.lastName() + " *"}
        </CpxInputWithLabel>
        <CpxPhoneInputWithLabel
          fields={[
            { name: 'contactPersons.commercialContactPerson.mobile.countryCode', value: values?.mobile?.countryCode, id: 'countryCode', placeholder: translation.countryCode() },
            { name: 'contactPersons.commercialContactPerson.mobile.areaCode', value: values?.mobile?.areaCode, id: 'areaCode', placeholder: translation.areaCode() },
            { name: 'contactPersons.commercialContactPerson.mobile.cli', value: values?.mobile?.cli, id: 'cli', placeholder: translation.cli() }
          ]}
          onChange={handleChange}
          errors={errors}
          touched={touched}
          label={translation.mobileNumber()}
          phoneType={values.mobile.phoneType.id}
        >
        </CpxPhoneInputWithLabel>
        <CpxPhoneInputWithLabel
          fields={[{ name: 'contactPersons.commercialContactPerson.phone.countryCode', value: values?.phone?.countryCode, id: 'countryCode', placeholder: translation.countryCode() },
            { name: 'contactPersons.commercialContactPerson.phone.areaCode', value: values?.phone?.areaCode, id: 'areaCode', placeholder: translation.areaCode() },
            { name: 'contactPersons.commercialContactPerson.phone.cli', value: values?.phone?.cli, id: 'cli', placeholder: translation.cli() }
          ]}
          onChange={handleChange}
          errors={errors}
          touched={touched}
          label={translation.phoneNumber()}
          phoneType={values.phone.phoneType.id}
        >
        </CpxPhoneInputWithLabel>
        <CpxDropdownWithLabel
          id="contactPersons.commercialContactPerson.language.id"
          name="contactPersons.commercialContactPerson.language.id"
          className={'customerContactLanguage'}
          onChange={handleChange}
          defaultOption={DEFAULT_SELECT_OPTION}
          value={values?.language?.id}
          options={contactLanguageOptions}
          error={errors?.language?.id}
        >
          {translation.contactLanguage() + " *"}
        </CpxDropdownWithLabel>
      </div>}
     </div>
    )
  };
