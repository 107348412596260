import cs from "classnames";
import { CpxDropdownWithLabel } from "../../../../../core/components/dropdown.component";
import { CpxInputWithLabel } from "../../../../../core/components/inputWithLabel.component";
import { CpxAddressInputGroup } from "../../../../../core/components/addressInputGroup.component";
import { CpxModal } from "../../../../../core/components/modal.component";
import React, { useEffect, useState } from "react";
import update from 'immutability-helper';
import { useCurrentOrderType, useSelectCountries, useSelectError, useSelectLang, useSelectSalutations, useStep1Data, useStep2Data } from "../../../../redux/store";
import { PopupDirections, SelectOption } from "../../../../constants/types/types.constants";
import { trimStringProperties, useTranslations } from "../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../constants/transitions/uiTranslations";
import { useTheme } from "../../../../../core/utility/themeContext";
import "./siteModal.scss";
import { useFormik } from "formik";
import { COUNTRY_IDS, ID_ADDRESSES, ID_SALUTATIONS, INPUT_MAX_LENGTHS, ORDER_TYPE } from "../../../../constants/configs/config.constants";
import { createValidatorSchema, MaxLengthCheck, requiredCheck } from "../../../../utils/validation";
import { CpxFormikForm } from "../../../../../core/components/formikForm.component";
import { apiCallAction } from "../../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../../constants/action.constants";
import { useDispatch } from "react-redux";
import { selectCurrentLanguage } from "../../../../../core/uiLanguage/lang.slice";
import { clearError } from "../../../../redux/actions/error.action";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { Alert } from "../../../common/Alert/Alert.component";
import { NfoGenerateFakeDataComponent } from "../generateFakeData/generateFakeData.component";
import { saveStep2Data } from "../../../../redux/actions/step.action";

type AppDispatch = ThunkDispatch<any, any, AnyAction>;

export const SiteModal = ({ setShowModal, locations, setLocations, edit, setEdit }: any) => {

  const theme = useTheme();
  const dispatch: AppDispatch = useDispatch();
  const currentLang = selectCurrentLanguage(useSelectLang());
  const translation = useTranslations(TRANSLATIONS.stepper.newCustomer);
  const translationsCommon = useTranslations(TRANSLATIONS.common);
  const dataOfStep1 = useStep1Data();
  const dataOfStep2 = useStep2Data();
  const ErrorsOfBE = useSelectError();
  const salutations = useSelectSalutations();
  const countries = useSelectCountries();
  const orderType = useCurrentOrderType();

  const [selectedCountry, setSelectedCountry] = useState<number>(+dataOfStep2?.siteAddresses[edit?.index-1]?.country?.id || NaN);
  const [selectedSalutation, setSelectedSalutation] = useState<number>(+dataOfStep2?.siteAddresses[edit?.index-1]?.salutation?.id || ID_SALUTATIONS.COMPANY);

  useEffect(() => {
    !edit?.isActive && setSelectedCountry(+dataOfStep2?.formData?.country?.id);
  }, [dataOfStep2])

  useEffect(() => {
    dispatch(apiCallAction(ACTION_CONST.API_GET_COUNTRY));
    dispatch(apiCallAction(ACTION_CONST.API_GET_SALUTATIONS));
  }, [currentLang]);

  const DEFAULT_SELECT_OPTION: SelectOption = {
    id: '',
    name: translationsCommon.defaultSelect(),
  }

  const countryOptions = Array.isArray(countries) ? countries.map(country => {
    return { name: country.description, id: country.id }
  }) : [];

  const salutationOptions = Array.isArray(salutations) ? salutations.map(salutation => {
    return { name: salutation.description, id: salutation.id }
  }) : [];

  const editSite = (values: any, index: number) => {
    setLocations(update(locations, {
      $splice: [[index, 1, values]]
    }));
  }

  const validateAddress = (finalValues: any, then: any) => {
    dispatch(apiCallAction(ACTION_CONST.API_VALIDATE_CUSTOMER_DATA, { ...finalValues, isQuote: orderType === ORDER_TYPE.ORDER_NEW_CUSTOMER_QUOTE }))
      .then(then);
  }

  useEffect(() => {
    // reset modal inputs on load
    dispatch(saveStep2Data({...dataOfStep2, formData: {}}));
  }, [])

  const requiredMsg = translation.fieldRequired();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      siteAddress: {
        country: {
          id: dataOfStep2?.formData?.country?.id || (edit && locations[edit.index]?.country?.id) || countryOptions[-1]?.id,
        },
        street: dataOfStep2?.formData?.street ||(edit && locations[edit.index]?.street) || '',
        houseNumber: dataOfStep2?.formData?.houseNumber || (edit && locations[edit.index]?.houseNumber) || '',
        additionalAddress: dataOfStep2?.formData?.additionalAddress || (edit && locations[edit.index]?.additionalAddress) || '',
        zip: dataOfStep2?.formData?.zip || (edit && locations[edit.index]?.zip) || '',
        county: dataOfStep2?.formData?.county || (edit && locations[edit.index]?.county) || '',
        city: dataOfStep2?.formData?.city || (edit && locations[edit.index]?.city) || '',
        city2: dataOfStep2?.formData?.city2 || (edit && locations[edit.index]?.city2) || '',
        city3: dataOfStep2?.formData?.city3 || (edit && locations[edit.index]?.city3) || '',
        district: dataOfStep2?.formData?.district || (edit && locations[edit.index]?.district) || '',
        province: dataOfStep2?.formData?.province || (edit && locations[edit.index]?.province) || '',
        salutation: {
          id: dataOfStep2?.formData?.salutation?.id || (edit && locations[edit.index]?.salutation?.id) || salutationOptions[2]?.id
        },
        companyName1: dataOfStep2?.formData?.companyName1 || (edit && locations[edit.index]?.companyName1) || '',
        companyName2: dataOfStep2?.formData?.companyName2 || (edit && locations[edit.index]?.companyName2) || '',
        addressType: {
          id: ID_ADDRESSES.CONNECTION,
        },
      },
    },
    validationSchema: createValidatorSchema({
      siteAddress: createValidatorSchema({
        street: MaxLengthCheck(INPUT_MAX_LENGTHS.address.street, requiredCheck(requiredMsg)),
        houseNumber: MaxLengthCheck(INPUT_MAX_LENGTHS.address.houseNumber,
          (selectedCountry === COUNTRY_IDS.DE ||
          selectedCountry === COUNTRY_IDS.AT ||
          selectedCountry === COUNTRY_IDS.FR ||
          selectedCountry === COUNTRY_IDS.IT ||
          selectedCountry === COUNTRY_IDS.PL ||
          selectedCountry === COUNTRY_IDS.CH ||
          selectedCountry === COUNTRY_IDS.ES ||
          selectedCountry === COUNTRY_IDS.GB) && requiredCheck(requiredMsg)),
        zip: MaxLengthCheck(INPUT_MAX_LENGTHS.address.zip, requiredCheck(requiredMsg)),
        city: MaxLengthCheck(INPUT_MAX_LENGTHS.address.city, requiredCheck(requiredMsg)),

        city2: MaxLengthCheck(INPUT_MAX_LENGTHS.address.city2, selectedCountry === COUNTRY_IDS.PL && requiredCheck(requiredMsg)),
        city3: MaxLengthCheck(INPUT_MAX_LENGTHS.address.city3, selectedCountry === COUNTRY_IDS.PL && requiredCheck(requiredMsg)),
        district: MaxLengthCheck(INPUT_MAX_LENGTHS.address.district, selectedCountry === COUNTRY_IDS.PL && requiredCheck(requiredMsg)),


        additionalAddress: MaxLengthCheck(INPUT_MAX_LENGTHS.address.additionalAddress),
        province: MaxLengthCheck(INPUT_MAX_LENGTHS.address.province, selectedCountry === COUNTRY_IDS.ES && requiredCheck(requiredMsg)),
        county: MaxLengthCheck(INPUT_MAX_LENGTHS.address.county),
        country: createValidatorSchema({
          id:  requiredCheck(requiredMsg),
        }),
        companyName1: MaxLengthCheck(selectedSalutation !== ID_SALUTATIONS.COMPANY ? INPUT_MAX_LENGTHS.customerData.firstName : INPUT_MAX_LENGTHS.customerData.companyName1, requiredCheck(requiredMsg)),
        companyName2: MaxLengthCheck(selectedSalutation !== ID_SALUTATIONS.COMPANY ? INPUT_MAX_LENGTHS.customerData.lastName : INPUT_MAX_LENGTHS.customerData.companyName2,selectedSalutation !== ID_SALUTATIONS.COMPANY && requiredCheck(requiredMsg)),
        salutation: createValidatorSchema({
          id:  requiredCheck(requiredMsg),
        })
      })
    }),
    onSubmit: async (values: any, { resetForm }: any) => {
      dispatch(clearError());

      const finalValues = {
        ...dataOfStep1,
        customDataPortal: {
          siteAddresses: [values.siteAddress],
        },
        stepper: "SITE_ADDRESSES"
      }

      trimStringProperties(finalValues);

      if (edit?.isActive) {
        validateAddress(finalValues, () => {
          editSite(values.siteAddress, edit?.index);
          setShowModal(false);
          setEdit({isActive: false, index: NaN});
          dispatch(clearError());
        });
      } else {
        validateAddress(finalValues, () => {
          setLocations([...locations, values.siteAddress]);
          setShowModal(false);
          resetForm();
          dispatch(clearError());
        });
      }

    }
  });


  const handleSalutationDropdownChange = (e: any) => {
    formik.handleChange(e);
    setSalutation(+e.target.value);
  }

  const setSalutation = (val : any)=>{
    setSelectedSalutation(val);
  }

  const inputNames = {
    country: "siteAddress.country.id",
    street: "siteAddress.street",
    houseNumber: "siteAddress.houseNumber",
    additionalAddress: "siteAddress.additionalAddress",
    zip: "siteAddress.zip",
    county: "siteAddress.county",
    city: "siteAddress.city",
    city2: "siteAddress.city2",
    city3: "siteAddress.city3",
    district: "siteAddress.district",
    province: "siteAddress.province",
    salutation: "siteAddress.salutation.id",
    companyName1: "siteAddress.companyName1",
    companyName2: "siteAddress.companyName2",
  }

  const onCancelModal = () => {
    setShowModal(false);
    setEdit({ isActive: false, index: NaN });
    dispatch(saveStep2Data({...dataOfStep2, formData: {}}));
    dispatch(clearError());
  }

  return (
    <CpxFormikForm handleSubmit={formik.handleSubmit}
                   initialValues={formik.initialValues}
                   id={'site-address-data-form'}
    >
      {edit?.isActive ? (
        <CpxModal
          className={'add-site-modal'}
          onCancel={() => onCancelModal()}
          confirmText={translationsCommon.edit()}
          formId={'site-address-data-form'}
        >
          <h3 className={cs('modalTitle', `modalTitle-le--${theme}`)}>
            {translation.editSiteHeading()}
          </h3>
          {ErrorsOfBE.errorData && ErrorsOfBE.errorData.length > 0 &&
            ErrorsOfBE?.requestData?.currentData?.stepper === "SITE_ADDRESSES" && <Alert errors={ErrorsOfBE.errorData}/>}
          <CpxDropdownWithLabel
            id="siteAddress.salutation.id"
            name="siteAddress.salutation.id"
            required={true}
            options={salutationOptions}
            defaultOption={DEFAULT_SELECT_OPTION}
            onChange={handleSalutationDropdownChange}
            value={formik.values?.siteAddress?.salutation?.id}
            error={formik.errors?.siteAddress?.salutation?.id}
          >
            {translation.salutation()}
          </CpxDropdownWithLabel>
          <CpxInputWithLabel
            id="siteAddress.companyName1"
            type="text"
            onChange={formik.handleChange}
            value={formik.values?.siteAddress?.companyName1.trimStart()}
            error={formik.touched?.siteAddress?.companyName1 && formik.errors?.siteAddress?.companyName1}
          >
            {formik.values?.siteAddress?.salutation?.id &&
            (parseInt(formik.values?.siteAddress?.salutation?.id) === ID_SALUTATIONS.COMPANY) ?
                translation.companyName1() + " *" : translation.firstName() + " *"}
          </CpxInputWithLabel>
          <CpxInputWithLabel
            id="siteAddress.companyName2"
            type="text"
            onChange={formik.handleChange}
            value={formik.values?.siteAddress?.companyName2.trimStart()}
            error={formik.touched?.siteAddress?.companyName2 && formik.errors?.siteAddress?.companyName2}
          >
            {formik.values?.siteAddress?.salutation?.id &&
            (parseInt(formik.values?.siteAddress?.salutation?.id) === ID_SALUTATIONS.COMPANY) ?
                translation.companyName2() : translation.lastName() + " *"}
          </CpxInputWithLabel>
          <CpxAddressInputGroup
            values={formik.values?.siteAddress} touched={formik.touched?.siteAddress} errors={formik.errors?.siteAddress}
            inputNames={inputNames}
            countryOptions={countryOptions}
            handleChange={formik.handleChange}
            selectedCountry={selectedCountry}
            popupDirection={PopupDirections.RIGHT}
            setSelectedCountry={setSelectedCountry}
          />
        </CpxModal>
      ) : (
        <CpxModal
          className={'add-site-modal'}
          onCancel={() => onCancelModal()}
          confirmText={translationsCommon.add()}
          formId={'site-address-data-form'}
        >
          <h3 className={cs('modalTitle', `modalTitle-le--${theme}`)}>
            {translation.newSiteHeading()}
          </h3>
          <NfoGenerateFakeDataComponent salutationOptions={salutationOptions} countryOptions={countryOptions}/>
          <CpxDropdownWithLabel
            id="siteAddress.salutation.id"
            name="siteAddress.salutation.id"
            required={true}
            options={salutationOptions}
            defaultOption={DEFAULT_SELECT_OPTION}
            onChange={handleSalutationDropdownChange}
            value={formik.values?.siteAddress?.salutation?.id}
            error={formik.errors?.siteAddress?.salutation?.id}
          >
            {translation.salutation()}
          </CpxDropdownWithLabel>
          <CpxInputWithLabel
            id="siteAddress.companyName1"
            type="text"
            onChange={formik.handleChange}
            value={formik.values?.siteAddress?.companyName1.trimStart()}
            error={formik.touched?.siteAddress?.companyName1 && formik.errors?.siteAddress?.companyName1}
          >
            {formik.values?.siteAddress?.salutation?.id &&
            (parseInt(formik.values?.siteAddress?.salutation?.id) === ID_SALUTATIONS.COMPANY) ?
                translation.companyName1() + " *" : translation.firstName() + " *"}
          </CpxInputWithLabel>
          <CpxInputWithLabel
            id="siteAddress.companyName2"
            type="text"
            onChange={formik.handleChange}
            value={formik.values?.siteAddress?.companyName2.trimStart()}
            error={formik.touched?.siteAddress?.companyName2 && formik.errors?.siteAddress?.companyName2}
          >
            {formik.values?.siteAddress?.salutation?.id &&
            (parseInt(formik.values?.siteAddress?.salutation?.id) === ID_SALUTATIONS.COMPANY) ?
                translation.companyName2() : translation.lastName() + " *"}
          </CpxInputWithLabel>
          <CpxAddressInputGroup
            values={formik.values?.siteAddress} touched={formik.touched?.siteAddress} errors={formik.errors?.siteAddress}
            inputNames={inputNames}
            countryOptions={countryOptions}
            handleChange={formik.handleChange}
            popupDirection={PopupDirections.RIGHT}
            selectedCountry={selectedCountry}
            setSelectedCountry={setSelectedCountry}
          />
          {ErrorsOfBE.errorData && ErrorsOfBE.errorData.length > 0 &&
            ErrorsOfBE?.requestData?.currentData?.stepper === "SITE_ADDRESSES" && <Alert errors={ErrorsOfBE.errorData}/>}
        </CpxModal>
      )}
    </CpxFormikForm>
  )
}
