import "./checkboxGroup.scss";
import { CpxCheckboxWithIndeterminate } from "./checkboxWithIndeterminate.component";
import cs from "classnames";

export type Option = {
  name: string;
  title?: string;
  checked: boolean | undefined;
};

type Props = {
  name: string;
  options: Option[];
  onChange?: (options: Option[]) => void;
  label?: string;
  className?: string;
};

export const CpxCheckboxGroup = ({
                                   options,
                                   name,
                                   onChange,
                                   label,
                                   className,
                                 }: Props) => {
  const handleChange = (checked: boolean, key: number) => {
    const newOptions = [...options];
    newOptions[key] = { ...newOptions[key], checked };

    if (onChange) {
      onChange(newOptions);
    }
  };

  return (
    <fieldset className={cs('checkboxGroup', className)}>
      {label && <label>{label}</label>}
      <ul>
        {options.map((option, key) => (
          <li key={option.name}>
            <CpxCheckboxWithIndeterminate
              type="checkbox"
              name={name}
              id={`${name}_${option.name}`}
              onChange={checked => handleChange(checked, key)}
              checked={option.checked}
            />
            {option.title && (
              <label htmlFor={`${name}_${option.name}`}><span dangerouslySetInnerHTML={{__html: option.title}}/> </label>
            )}
          </li>
        ))}
      </ul>
    </fieldset>
  );
};
