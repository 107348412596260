import { useTranslations } from "../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../constants/transitions/uiTranslations";
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { deleteStep4, isProductSelected, saveCurrentStep, saveStep1Data, saveStep3Data } from "../../../../redux/actions/step.action";
import { NfoCustomerWrapperComponent } from "../wrapper/newCustomerWrapper.component";
import { useFormik } from "formik";
import { CpxFormikForm } from "../../../../../core/components/formikForm.component";
import { useCurrentOrderType, useCurrentStep, useOEProducts, useOrderEntryBasket, useSelectLang, useSelectPartners, useSelectUser, useStep1Data } from "../../../../redux/store";
import { CpxSelectBox } from "../../../../../core/components/selectBox.component";
import { apiCallAction } from "../../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../../constants/action.constants";
import { selectCurrentLanguage } from "../../../../../core/uiLanguage/lang.slice";
import { Basket, Product } from "compax-api";
import { ReactComponent as Check } from "../../../../assets/icons/check.svg";
import { ReactComponent as CheckDts } from "../../../../assets/icons/check_dts.svg";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { useTheme } from "../../../../../core/utility/themeContext";
import { ORDER_TYPE, PRODUCT_IDS } from "../../../../constants/configs/config.constants";
import { InfoWarningBox } from "../wrapper/infoWarningBox/infoWarningBox.component";

type AppDispatch = ThunkDispatch<any, any, AnyAction>;

export const NfoCustomerProductSelectionStep = () => {
  const translationsStepper = useTranslations(TRANSLATIONS.stepper.newCustomer);
  const dispatch: AppDispatch = useDispatch();
  const currentStep = useCurrentStep();
  const basket = useOrderEntryBasket();
  const currentLang = selectCurrentLanguage(useSelectLang());
  const legalEntityId = useSelectUser()?.legalEntity?.id;
  const partnerId = useSelectPartners()[0]?.id;
  const products = useOEProducts() as Array<Product>;
  const [oEProducts, setOEProducts] = useState<Array<Product>>([]);
  const theme = useTheme();
  const step1Data = useStep1Data();
  const orderType = useCurrentOrderType();
  const [migrationProductUnAvailable, setMigrationProductUnAvailable] = useState<boolean>(false);

  useEffect(() => {

    if (step1Data.migrationCustomerId && step1Data.partnerVoNumber && Object.values(basket?.entries).length == 0) {
      dispatch(apiCallAction(ACTION_CONST.API_GET_OE_MIGRATION_CUSTOMER_PRODUCT, {
        customerId: step1Data.migrationCustomerId,
        partnerVoNumber: step1Data.partnerVoNumber
      })).then((res) => {
        dispatch(saveStep1Data({...step1Data, migrationServiceId: res.service_id}))
        setMigrationProductUnAvailable(res?.product_type !== 'trunk' || !products.some((p:any) => p?.id === PRODUCT_IDS.SIP_TRUNK_FLEXX_DTS));
        let updatedProducts = products.map((p: any) => {
          if(res?.product_type === 'trunk' && p?.id === PRODUCT_IDS.SIP_TRUNK_FLEXX_DTS) {
            dispatch(isProductSelected(true));
          }
          return { ...p, active: res?.product_type === 'trunk' && p?.id === PRODUCT_IDS.SIP_TRUNK_FLEXX_DTS }
        });
        setOEProducts(updatedProducts);
      });
    } else {
      let updatedProducts = products.map((p: any) => {
        return { ...p, active: false }
      });
      setOEProducts(updatedProducts);
    }

  }, [products])

  useEffect(() => {
    dispatch(deleteStep4())
    const actionString = orderType === ORDER_TYPE.ORDER_NEW_CUSTOMER_QUOTE ? ACTION_CONST.API_GET_QUOTE_PRODUCTS : ACTION_CONST.API_GET_OE_PRODUCTS;
    dispatch(apiCallAction(actionString, { legalEntityId, partnerId }));
  }, [currentLang]);

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {},
    onSubmit: async () => {
      const activeProduct = oEProducts.find((oEProduct: any) => oEProduct.active)
      if (activeProduct) {
        dispatch(apiCallAction(ACTION_CONST.API_BASKET_PUT_PRODUCT, { productId: activeProduct?.id, basketId: basket?.id }))
          .then((basket: Basket) => {
            dispatch(saveStep3Data({ activeProduct: activeProduct, activeEntryUuid: basket.entries[Math.max(...Object.keys(basket?.entries).map(e => +e))]?.uuid, activeIndex: Math.max(...Object.keys(basket?.entries).map(e => +e)) }))
            dispatch(saveCurrentStep(currentStep + 1))
          })
      } else if(Object.keys(basket?.entries).length > 0) {
        if(orderType === ORDER_TYPE.ORDER_NEW_CUSTOMER_QUOTE){
          dispatch(saveCurrentStep(9))
        } else {
          dispatch(saveCurrentStep(8))
        }
      }
    }
  })

  useEffect(() => {
    dispatch(saveCurrentStep(5));
    dispatch(isProductSelected(false));
  }, []);

  const selectProduct = (id: any) => {
    setOEProducts((prevState: any) => prevState.map((p: any) => {
      if (p.id === id) {
        dispatch(isProductSelected(!p.active))
        return { ...p, active: !p.active };
      } else {
        return { ...p, active: false }
      }
    }))
  }

  return (
    <>
      <NfoCustomerWrapperComponent
        title={translationsStepper.productSelection('')}
        subtitle={translationsStepper.productSelectionText()}
      >
        <>
          {migrationProductUnAvailable && <InfoWarningBox
              text={translationsStepper.migrationInfoMessage()}
            />}
            <CpxFormikForm handleSubmit={formik.handleSubmit}
                           initialValues={formik.initialValues}
                           id={'new-customer-form'}
            >
              <div className={`boxes`}>
                {oEProducts
                  //.filter((p: any) => p.status?.id !== ID_PRODUCT_STATUS.NOT_RELEASED)
                  .map((p: any, index: any) => (
                    <CpxSelectBox
                      key={index}
                      tabIndex={index}
                      product={p.product}
                      title={p.product}
                      active={p.active}
                      onClick={() => selectProduct(p.id)}
                    >
                      {p?.keyFeatures?.split('|').map((keyFeature: any, index: number) => (
                          <div key={index} className="box-item">{theme === 'nfon' ? <Check/> : <CheckDts/>}
                            <li>{keyFeature}</li>
                          </div>
                        )
                      )}
                    </CpxSelectBox>
                  ))}
              </div>
            </CpxFormikForm>
          </>
      </NfoCustomerWrapperComponent>
    </>
);
};
