import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { saveBillingInformationSelection, saveCurrentStep, saveStep1Data } from "../../../../redux/actions/step.action";
import { useSelectBillMedia, useSelectCountries, useSelectError, useSelectLang, useSelectPaymentMode, useSelectSalutations, useStep1Data, useStepperBillingInformationSelection, } from "../../../../redux/store";
import { trimStringProperties, useTranslations } from "../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../constants/transitions/uiTranslations";
import { NfoCustomerWrapperComponent } from "../wrapper/newCustomerWrapper.component";
import "./step.scss";
import { useFormik } from "formik";
import { bicCheck, createValidatorSchema, emailCheck, ibanCheck, MaxLengthCheck, requiredCheck } from "../../../../utils/validation";
import { CpxFormikForm } from "../../../../../core/components/formikForm.component";
import { apiCallAction } from "../../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../../constants/action.constants";
import { selectCurrentLanguage } from "../../../../../core/uiLanguage/lang.slice";
import { BillingInformation } from "./forms/BillingInformation";
import { Alert } from "../../../common/Alert/Alert.component";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { clearError } from "../../../../redux/actions/error.action";
import { COUNTRY_IDS, ID_ADDRESSES, ID_SALUTATIONS, INPUT_MAX_LENGTHS } from "../../../../constants/configs/config.constants";
import { NfoGenerateFakeDataComponent } from "../generateFakeData/generateFakeData.component";

type AppDispatch = ThunkDispatch<any, any, AnyAction>;


export const NfoBillingAndPaymenttep = () => {
  const translation = useTranslations(TRANSLATIONS.stepper.newCustomer);
  const dispatch: AppDispatch = useDispatch();
  const dataOfStep1 = useStep1Data();
  const ErrorsOfBE = useSelectError();

  const salutations = useSelectSalutations();
  const currentLang = selectCurrentLanguage(useSelectLang());
  const countries = useSelectCountries();
  const billMedia = useSelectBillMedia();
  const paymentModes = useSelectPaymentMode();
  const [billingAddressSelectedCountry, setBillingAddressSelectedCountry] = useState<number>(+dataOfStep1?.account?.billAddress?.country?.id || NaN);
  const [selectedAccountSalutation, setAccountSelectedSalutation] = useState<number>(+dataOfStep1?.account?.billAddress?.salutation?.id || NaN);

  const billingInformationSelection = useStepperBillingInformationSelection();

  const [isSEPA, setIsSEPA] = useState(billingInformationSelection?.isSEPA);
  const [isDifferentBillingAddress, setIsDifferentBillingAddress] = useState(billingInformationSelection?.isDifferentBillingAddress || +dataOfStep1?.account?.billAddress?.country?.id);

  useEffect(() => {
    setBillingAddressSelectedCountry(+dataOfStep1?.account?.billAddress?.country?.id);

    // check if IBAN doesn't exist
    if (dataOfStep1.hasOwnProperty("migrationCustomerId")) {
      if (dataOfStep1.account.iban === "" || dataOfStep1.account.iban === undefined) {
        // set paymentMethod to "Überweisung"
        setIsSEPA(false)
      }
    }

  }, [dataOfStep1])

  const salutationOptions = Array.isArray(salutations) ? salutations.map(salutation => {
    return { name: salutation.description, id: salutation.id }
  }) : [];

  const countryOptions = Array.isArray(countries) ? countries.map(country => {
    return { name: country.description, id: country.id }
  }) : [];

  const paymentOptions = Array.isArray(paymentModes) ? paymentModes
      /*.filter(paymentMode => paymentMode.id === 1)*/
      .map(paymentMode => {
          return { name: paymentMode.description, id: paymentMode.id }
        })
    : [];

  const billMediaOptions = Array.isArray(billMedia) ? billMedia.map(billMedia => {
    return { name: billMedia.description, id: billMedia.id }
  }) : [];

  useEffect(() => {
    dispatch(apiCallAction(ACTION_CONST.API_GET_BILL_MEDIA));
    dispatch(apiCallAction(ACTION_CONST.API_GET_PAYMENT_MODE));
  }, [currentLang]);

  const goForward = () => {
    dispatch(saveCurrentStep(4));
  }

  const requiredMsg = translation.fieldRequired();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      /* BillingInformation */
      account: {
        accountName: dataOfStep1?.account?.accountName || '',
        iban: dataOfStep1?.account?.iban || '',
        bic: dataOfStep1?.account?.bic || '',
        paymentMode: {
          id: !isSEPA && 2 || dataOfStep1?.account?.paymentMode.id || paymentOptions[0]?.id,
        },
        paymentMedia: {
          id: dataOfStep1?.account?.paymentMedia.id || billMediaOptions[-1]?.id,
        },
        billAddress: {
          country: {
            id: dataOfStep1?.account?.billAddress?.country?.id || countryOptions[-1]?.id,
          },
          street: dataOfStep1?.account?.billAddress?.street || '',
          houseNumber: dataOfStep1?.account?.billAddress?.houseNumber || '',
          additionalAddress: dataOfStep1?.account?.billAddress?.additionalAddress || '',
          zip: dataOfStep1?.account?.billAddress?.zip || '',
          county: dataOfStep1?.account?.billAddress?.county || '',
          city: dataOfStep1?.account?.billAddress?.city || '',
          city2: dataOfStep1?.account?.billAddress?.city2 || '',
          city3: dataOfStep1?.account?.billAddress?.city3 || '',
          district: dataOfStep1?.account?.billAddress?.district || '',
          province: dataOfStep1?.account?.billAddress?.province || '',
          salutation: {
            id: dataOfStep1?.account?.billAddress?.salutation?.id || salutationOptions[-1]?.id
          },
          companyName1: dataOfStep1?.account?.billAddress?.companyName1 || '',
          companyName2: dataOfStep1?.account?.billAddress?.companyName2 || '',
          email: dataOfStep1?.account?.billAddress?.email || '',
          addressType: {
            id: ID_ADDRESSES.BILLING,
          },
        },

      },

    },
    validationSchema: createValidatorSchema({
      /* BillingInformation */
      account: createValidatorSchema({
        paymentMedia: createValidatorSchema({
          id: requiredCheck(requiredMsg),
        }),
        paymentMode: createValidatorSchema({
          id: requiredCheck(requiredMsg),
        }),
        accountName: MaxLengthCheck(INPUT_MAX_LENGTHS.account.accountName, isSEPA && requiredCheck(requiredMsg)),
        iban: isSEPA && ibanCheck(requiredMsg, translation.ibanSyntax(), translation.validEUSepa()),
        bic: isSEPA && bicCheck(requiredMsg, translation.bicSyntax()),
        billAddress: createValidatorSchema({
          street: MaxLengthCheck(INPUT_MAX_LENGTHS.address.street, isDifferentBillingAddress && requiredCheck(requiredMsg)),
          houseNumber: MaxLengthCheck(INPUT_MAX_LENGTHS.address.houseNumber,
            (isDifferentBillingAddress &&
              (billingAddressSelectedCountry === COUNTRY_IDS.DE ||
                billingAddressSelectedCountry === COUNTRY_IDS.AT ||
                billingAddressSelectedCountry === COUNTRY_IDS.FR ||
                billingAddressSelectedCountry === COUNTRY_IDS.IT ||
                billingAddressSelectedCountry === COUNTRY_IDS.PL ||
                billingAddressSelectedCountry === COUNTRY_IDS.CH ||
                billingAddressSelectedCountry === COUNTRY_IDS.ES ||
                billingAddressSelectedCountry === COUNTRY_IDS.GB)) && requiredCheck(requiredMsg)),
          zip: MaxLengthCheck(INPUT_MAX_LENGTHS.address.zip, isDifferentBillingAddress && requiredCheck(requiredMsg)),
          city: MaxLengthCheck(INPUT_MAX_LENGTHS.address.city, isDifferentBillingAddress && requiredCheck(requiredMsg)),
          city2: MaxLengthCheck(INPUT_MAX_LENGTHS.address.city2, billingAddressSelectedCountry === COUNTRY_IDS.PL && requiredCheck(requiredMsg)),
          city3: MaxLengthCheck(INPUT_MAX_LENGTHS.address.city3, billingAddressSelectedCountry === COUNTRY_IDS.PL && requiredCheck(requiredMsg)),
          district: MaxLengthCheck(INPUT_MAX_LENGTHS.address.district, billingAddressSelectedCountry === COUNTRY_IDS.PL && requiredCheck(requiredMsg)),
          additionalAddress: MaxLengthCheck(INPUT_MAX_LENGTHS.address.additionalAddress),
          province: MaxLengthCheck(INPUT_MAX_LENGTHS.address.province, (isDifferentBillingAddress && billingAddressSelectedCountry === COUNTRY_IDS.ES) && requiredCheck(requiredMsg)),
          county: MaxLengthCheck(INPUT_MAX_LENGTHS.address.county),
          companyName1: MaxLengthCheck(selectedAccountSalutation !== ID_SALUTATIONS.COMPANY ? INPUT_MAX_LENGTHS.customerData.firstName : INPUT_MAX_LENGTHS.customerData.companyName1, isDifferentBillingAddress && requiredCheck(requiredMsg)),
          companyName2: MaxLengthCheck(selectedAccountSalutation !== ID_SALUTATIONS.COMPANY ? INPUT_MAX_LENGTHS.customerData.lastName : INPUT_MAX_LENGTHS.customerData.companyName2,
            (isDifferentBillingAddress && selectedAccountSalutation !== ID_SALUTATIONS.COMPANY) && requiredCheck(requiredMsg)),
          email: MaxLengthCheck(INPUT_MAX_LENGTHS.contact.email, emailCheck(translation.emailSyntax())),
          country: createValidatorSchema({
            id: isDifferentBillingAddress && requiredCheck(requiredMsg),
          }),
          salutation: createValidatorSchema({
            id: isDifferentBillingAddress && requiredCheck(requiredMsg),
          })
        }),

      }),
    }),
    onSubmit: async (values: any) => {



      const finalValues = {
        ...dataOfStep1,
        ...values,
        account: { ...values.account, iban: values?.account?.iban.replaceAll(' ', '') },
        stepper: "PAYMENT"
      }
      if (!isSEPA) {
        finalValues.account.accountName = null;
        finalValues.account.iban = null;
        finalValues.account.bic = null;
        finalValues.account.email = null;
      }

      if (!isDifferentBillingAddress) {
        finalValues.account.billAddress = null;
      }

      trimStringProperties(finalValues);

      const tempValues = {
        customer: finalValues.customer,
        mainAddress: finalValues.mainAddress,
        contactPersons: finalValues.contactPersons,
        account: { ...finalValues.account},
        stepper: "PAYMENT"
      }

      dispatch(apiCallAction(ACTION_CONST.API_VALIDATE_CUSTOMER_DATA, { ...tempValues, isQuote: false }))
       .then(() => {
          dispatch(clearError())
          dispatch(apiCallAction(ACTION_CONST.API_BASKET_CREATE_ORDER_ENTRY)).then(
            (basket) => {
              dispatch(apiCallAction(ACTION_CONST.API_BASKET_PREPARE_ORDER_ENTRY, {
                basketId: basket.id,
                addressCountryId: finalValues?.account?.billAddress?.country?.id ? finalValues?.account?.billAddress?.country?.id : finalValues?.mainAddress?.country?.id
              }))
            }
          )
          dispatch(saveStep1Data(finalValues));
          dispatch(saveBillingInformationSelection({ isSEPA, isDifferentBillingAddress }));
          goForward();
       })
       .catch(() => window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' }))
    }

  });

  const handleAccountSalutationDropdownChange = (e: any) => {
    formik.handleChange(e);
    setAccountSalutation(+e.target.value);

  }

  const setAccountSalutation = (val: any) => {
    setAccountSelectedSalutation(val);
  }

  return (
    <>
      <NfoCustomerWrapperComponent
        title={translation.billingAndPayment()}
        subtitle={translation.customerDataSubTitle()}
      >
        <>
          <CpxFormikForm handleSubmit={formik.handleSubmit}
                         initialValues={formik.initialValues}
                         id={'new-customer-form'}
          >
            <NfoGenerateFakeDataComponent salutationOptions={salutationOptions} countryOptions={countryOptions}/>
            <BillingInformation values={formik.values} handleChange={formik.handleChange}
                                touched={formik.touched} errors={formik.errors}
                                paymentOptions={paymentOptions} billingMediumOptions={billMediaOptions}
                                salutationOptions={salutationOptions}
                                countryOptions={countryOptions}
                                selectedCountry={billingAddressSelectedCountry}
                                setSelectedCountry={setBillingAddressSelectedCountry}
                                handleSalutation={handleAccountSalutationDropdownChange}
                                isSEPA={isSEPA}
                                setIsSEPA={setIsSEPA}
                                isDifferentBillingAddress={isDifferentBillingAddress}
                                setIsDifferentBillingAddress={setIsDifferentBillingAddress}
            />
          </CpxFormikForm>
          {ErrorsOfBE.errorData && ErrorsOfBE.errorData.length > 0 &&
            ErrorsOfBE?.requestData?.currentData?.stepper === "PAYMENT" && <Alert errors={ErrorsOfBE.errorData}/>}
        </>
      </NfoCustomerWrapperComponent>
    </>
  );
};
