import { useDispatch } from 'react-redux';
import { HashRouter, Redirect, Switch, useLocation, useParams } from 'react-router-dom';
import { useSelectApiCallStatus, useSelectLoader, useSelectUser } from './portal/redux/store';
import Cookie from 'cookies-js'
import React, { useEffect } from 'react';
import {
  DEFAULT_LEGAL_ENTITY,
  LANGUAGES_ID,
  LEGAL_ENTITIES,
  LOCALSTORAGE_ACCESSTOKEN,
  LOCALSTORAGE_REFRESHTOKEN,
  ROLE_CUSTOMER,
  ROLE_PARTNER,
  ROLE_TELEPHONY_CUSTOMER,
  ROLE_WHOLESALER,
  ROLE_WHOLESALER_EVN,
  USER_CUSTOMER,
  USER_CUSTOMER_TELEPHONY,
  USER_PARTNER,
  USER_WHOLESALER,
  USER_WHOLESALER_EVN
} from './portal/constants/configs/config.constants';
import { NfoFooter } from './portal/components/common/footer/footer.component';
import { NfoHeader } from './portal/components/common/header/header.component';
import jwt_decode from 'jwt-decode';
import { setUserRole, setWholesaleModel } from './portal/redux/actions/config.action';
import { useGetTitle } from './portal/utils/helper/utils';
import { ThemeProvider } from './core/utility/themeContext';
import './App.scss';
import { NfoPageLoader } from './portal/components/common/pageLoader/pageLoader.component';
import cs from 'classnames';
import PrivateRoute from './portal/routing/PrivateRoute';
import PublicRoute from './portal/routing/PublicRoute';
import { SeoComponent } from './core/components/seo/seo.component';
import { routes } from "./portal/constants/routes/routes";
import { switchLanguage } from "./core/uiLanguage/langStore";

interface LegalEntity {
  entity: string;
}

const App = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelectApiCallStatus().loggedIn && localStorage.getItem(LOCALSTORAGE_REFRESHTOKEN)
  const accessToken = Cookie.get(LOCALSTORAGE_ACCESSTOKEN)
  const userValueTyp = useGetTitle();
  const loader = useSelectLoader();
  const legalEntity = useParams<LegalEntity>();
  const internalClassName = 'app';
  const location = useLocation();
  const routesArray = Object.values(routes);
  const user = useSelectUser();

  if (legalEntity.entity === undefined || !LEGAL_ENTITIES.hasOwnProperty(legalEntity.entity.toUpperCase())) {
    legalEntity.entity = DEFAULT_LEGAL_ENTITY;
  } else {
    legalEntity.entity = legalEntity.entity.toLowerCase();
  }

  // handles user language on load
  useEffect(() => {
    let userLang = user?.language?.id;

    const key: any = Object.keys(LANGUAGES_ID).find((key) => {
      if (Object.values(LANGUAGES_ID).find(value => value === userLang) === undefined) {
        userLang = LANGUAGES_ID['EN'];
      }
      // @ts-ignore
      return LANGUAGES_ID[key] === userLang;
    });
    dispatch(switchLanguage({ language: key }));
    localStorage.setItem("lang", key.toLowerCase());
  }, [user])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    document.title = userValueTyp;
  }, [userValueTyp]);


  useEffect(() => {
    const jwtDecoded: any = accessToken && jwt_decode(accessToken);
    if (jwtDecoded && jwtDecoded.authorities.includes(ROLE_TELEPHONY_CUSTOMER)) {
      dispatch(setUserRole(USER_CUSTOMER_TELEPHONY));
    } else if (jwtDecoded && jwtDecoded.authorities.includes(ROLE_WHOLESALER_EVN)) {
      dispatch(setUserRole(USER_WHOLESALER_EVN));
    } else if (jwtDecoded && jwtDecoded.authorities.includes(ROLE_WHOLESALER)) {
      dispatch(setUserRole(USER_WHOLESALER));
      let model = jwtDecoded.authorities.find((role: string) => role.startsWith('ROLE_WHOLESALE_MODEL_'));
      if (model) {
        dispatch(setWholesaleModel(model))
      }
    } else if (jwtDecoded && jwtDecoded.authorities.includes(ROLE_PARTNER)) {
      dispatch(setUserRole(USER_PARTNER));
    } else if (jwtDecoded && jwtDecoded.authorities.includes(ROLE_CUSTOMER)) {
      dispatch(setUserRole(USER_CUSTOMER));
    }
  }, [accessToken, dispatch]);


  const subRoute = (route: any) => {
    let RouteType
    if (route.needsAuthorization) {
      RouteType = PrivateRoute
    } else if (!route.needsAuthorization) {
      RouteType = PublicRoute
    } else {
      return (<Redirect to={`/`}/>)


    }

    return (
      <RouteType
        loggedIn={isLoggedIn}
        exact={route?.exact} path={route.path} key={route.path} legalEntity={legalEntity.entity} {...route}>
        {<route.page/>}
      </RouteType>
    )
  }

  return (
    <>
      <HashRouter basename={legalEntity.entity}>
        <ThemeProvider legalEntity={legalEntity.entity}>
          <SeoComponent/>
          <div
            className={cs(
              internalClassName,
              `${internalClassName}-le--${legalEntity.entity}`
            )}
          >
            <div>
              <NfoHeader/>
              <NfoPageLoader showLoader={loader}>
                <Switch>
                  {routesArray.map(currentRoute => subRoute(currentRoute))}
                </Switch>
              </NfoPageLoader>
            </div>
            <div>
              <NfoFooter/>
            </div>
          </div>
        </ThemeProvider>
      </HashRouter>
    </>
  );
};

export default App;
