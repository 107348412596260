import { IAction } from "../../constants/types/types.constants";
import { ACTION_CONST } from "../../constants/action.constants";

const initialState = {
  PORTAL_ORDER_ENTRY: {},
  PORTAL_ORDER_ENTRY_MIGRATION: {},
  PORTAL_ORDER_ENTRY_GENERATOR: {},
  PORTAL_PARTNER_EXTENSIONS_ORDER: {},
  PORTAL_CUSTOMER_EXTENSIONS_ORDER: {},
  PORTAL_PARTNER_CUSTOM_INFO: {},
  PORTAL_ACCOUNT_DATA_CHANGE_CUSTOMER: {},
  PORTAL_ACCOUNT_DATA_CHANGE_PARTNER: {},
  PORTAL_PARTNER_CUSTOMER_ADMINSTRATION_MENU: {},
  ENABLE_REQUEST_DATE: {},
  PORTAL_PRODUCT_CHANGE: {},
  PORTAL_LICENSE_EDITABLE: {},
  PORTAL_ORDER_NEW_MAIN_CONTRACT: {},
  PORTAL_QUOTE_CUSTOMER: {},
};

function featuresReducer(state = initialState, action: IAction) {
  switch (action.type) {
    case   ACTION_CONST.API_PORTAL_FEATURE:
      let theFeature = Object.keys(action.data)[0];
      let theValue = Object.values(action.data)[0];
      return {
        ...state,
        [theFeature]: theValue
      };
    default:
      return state;
  }
}

export default featuresReducer;
