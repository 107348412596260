import cs from "classnames";
import { NfoConfigurationBox } from "./configurationBox/configurationBox.component";
import React, { useEffect, useState } from "react";
import { useTranslations } from "../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../constants/transitions/uiTranslations";
import { useTheme } from "../../../../../core/utility/themeContext";
import { onValidateProductOption, saveOEOptionsOfOption, selectProductOption } from "../../../../redux/actions/step.action";
import { Basket, OptionGroup } from "compax-api";
import { useDispatch } from "react-redux";
import { useCurrentOrderType, useOEOptionsOfOptions, useOEProductOptions, useOrderEntryBasket, useSelectError, useSelectLang, useSelectUser, useStep1Data, useStep2Data, useStep3Data } from "../../../../redux/store";
import { NfoEditBox } from "./editBox/editBox.component";
import { selectCurrentLanguage } from "../../../../../core/uiLanguage/lang.slice";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { Alert } from "../../../common/Alert/Alert.component";
import { ID_CONTRACT_TELEPHONE_CONNECTION, PRODUCT_IDS } from "../../../../constants/configs/config.constants";
import { apiCallAction } from "../../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../../constants/action.constants";
import { CpxModal } from "../../../../../core/components/modal.component";
import { useToast } from "../../../common/header/toast/toast.component";
import { ToastTypes } from "../../../../constants/types/types.constants";

type Props = {
  telephoneConnectionsObject: OptionGroup[];
}
type AppDispatch = ThunkDispatch<any, any, AnyAction>;


export const NfoOrderEntryTelephoneConnections = ({ telephoneConnectionsObject }: Props) => {
  const internalClassName= "orderEntryTelephoneConnections"
  const translationsStepper = useTranslations(TRANSLATIONS.stepper.newCustomer);
  const theme = useTheme();
  const dispatch: AppDispatch = useDispatch();
  const [telephoneConnections, setTelephoneConnections] = useState<Array<OptionGroup> & any>([]);
  const oEOptionsOfOptions = useOEOptionsOfOptions();
  const basket: Basket = useOrderEntryBasket();
  const currentLang = selectCurrentLanguage(useSelectLang());
  const stepOneData = useStep1Data();
  const legalEntity = useSelectUser()?.legalEntity;
  const error = useSelectError();
  const optionsGroups = useOEProductOptions();
  const step3Data = useStep3Data();
  const step2Data = useStep2Data();
  const [isMigrationLoading, setMigrationLoading] = useState(false);
  const toast = useToast();
  const orderType = useCurrentOrderType();

  useEffect(() => {
    const options = basket?.entries[step3Data?.activeIndex]?.options
    if (basket && Array.isArray(options) && options.length > 0) {
      options?.map(option => dispatch(onValidateProductOption(option, basket, stepOneData, step3Data?.activeIndex, orderType)))
    }
  }, [])

  useEffect(() => {
    setTelephoneConnections(telephoneConnectionsObject);
  }, [telephoneConnectionsObject])


  const selectOptionOfOption = async (uuid: string, migrationCustomerId:string, partnerVoNumber: string, migrationServiceId: string) => {

    let tempBasket:any = await dispatch(apiCallAction(ACTION_CONST.API_POST_OE_MIGRATION_CUSTOMER_ADD_CALLNUMBERS_TO_BASKET, {basketId: basket.id, customerId: migrationCustomerId, entryUuid: uuid, partnerId: partnerVoNumber, serviceId: migrationServiceId}))
    let newBasket: Basket = tempBasket;
    const res: any = await dispatch(apiCallAction(ACTION_CONST.API_GET_OE_OPTIONS_OF_OPTION_FOR_MIGRATION,
      {
        basketId: basket?.id,
        uuid: newBasket?.entries[step3Data?.activeIndex]?.options?.find((o: any) => o.uuid === uuid)?.uuid,
        language: currentLang
      },
      true))
    dispatch(saveOEOptionsOfOption({[uuid]: res?.optionGroups}));
    return newBasket;
  }

  const productIdOfTelephoneConnection = optionsGroups.find((item: any) => item.basketOptions.find((option: any) => option?.product?.productType?.id === +ID_CONTRACT_TELEPHONE_CONNECTION)).basketOptions[0].product.id;
  const optionUuid = basket?.entries[step3Data?.activeIndex]?.options?.find((option: any) => option?.product?.id === productIdOfTelephoneConnection)?.uuid || '';




  return (
    <>
      {error?.errorData && error?.errorData?.length > 0 &&
        error?.requestData?.currentData?.stepper === "VALIDATE_BASKET_OPTION" && <Alert errors={error?.errorData}/>}
      <div className={cs(`connections`, `connections-le--${theme}`)}>
        <h5 className={cs(`connections-title`, `connections-title-le--${theme}`)}>{translationsStepper.telephoneConnections()}</h5>
        {
          telephoneConnectionsObject && telephoneConnectionsObject.map((p: any, index: number) => {
              if (p?.validated) {
                return (<NfoEditBox key={index} name={p.product.displayValue} basket={basket} optionId={p?.product?.id} optionUuid={optionUuid}/>)
              }
              return (
                <>
                  {isMigrationLoading &&
                    <CpxModal onConfirmFlag={false}
                      className={internalClassName}
                    >
                      <h3>{translationsStepper.migrationWaitMsgTitle()}</h3>
                      <p>{translationsStepper.migrationWaitMsgText()}</p>
                    </CpxModal>
                  }
                <NfoConfigurationBox
                  key={index}
                  name={p.product.displayValue}
                  configurable={true}
                  parentOptionId={p?.product?.id}
                  optionsOfOptions={oEOptionsOfOptions}
                  onClick={() => {
                    dispatch(selectProductOption(p, basket, setTelephoneConnections, currentLang, step3Data?.activeEntryUuid, orderType, optionUuid)).then(res => {
                      if(stepOneData?.migrationCustomerId && stepOneData?.partnerVoNumber && basket.entries[step3Data?.activeIndex].product.id === PRODUCT_IDS.SIP_TRUNK_FLEXX_DTS) {
                        setMigrationLoading(true);
                        selectOptionOfOption(res.entries[step3Data?.activeIndex]?.options?.find((option: any) => option?.product?.id === productIdOfTelephoneConnection)?.uuid || '', stepOneData.migrationCustomerId, stepOneData?.partnerVoNumber, stepOneData?.migrationServiceId).then(() => {
                          setMigrationLoading(false);
                        }).catch(() => {

                          toast.showToast(ToastTypes.ERROR, translationsStepper.error(), translationsStepper.errorWhileLoadingCallNumbers());
                          setMigrationLoading(false);
                        });
                      }
                    });
                  }}
                  parentOptionUuid={optionUuid}
                  onApplyProductOption={() => dispatch(onValidateProductOption(p, basket, stepOneData, step3Data?.activeIndex, orderType))}
                />
                </>
              )
            }
          )
        }
      </div>
    </>
  )
}
