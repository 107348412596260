import { Action } from "@reduxjs/toolkit";
import { Dispatch, FormEvent } from "react";
import { apiCallAction } from "../portal/redux/actions/apiCall.action";
import { ACTION_CONST } from "../portal/constants/action.constants";
import { Address, AddressPortal, Basket, Charge, CustomerPortal } from "compax-api";
import { CURRENCY_MAPPING, ID_CHARGE_MODES, ID_CHARGE_TYPES } from "../portal/constants/configs/config.constants";

export const isActionOfType = <T extends Action>(
  ...actions: (Action | undefined)[]
) => (action: Action): action is T =>
  !!actions.find(a => !!a && a.type === action.type);

export const setStateFromEvent = (setter: (newValue: string) => void) => (
  event: FormEvent<HTMLInputElement>
) => {
  setter(event.currentTarget.value);
};

export const validate = (property: any) => {
  if (property !== undefined)
    return property;
};


export const preventDefault = <TEvent extends { preventDefault: () => any }>(
  fn?: (...args: any) => void,
  ...args: any
) => (event: TEvent) => {
  event.preventDefault();
  if (!!fn) {
    fn(...args);
  }
};

export const stringOrUndefined = (thing?: any) =>
  thing === undefined ? undefined : String(thing);

export const getApiBoolean = (apiBoolean: boolean | string) =>
  !(apiBoolean === false || apiBoolean === "false");

/**
 * Template method for creating a method for grouping an array of objects by a specified set of keys
 * Example usage:
 * const groupByName = groupBy(['name']);
 * let groupedArray = groupByName([...]);
 * @param keys: Keys to group a given array of objects by
 */
export const groupBy = (keys: string[]) => (array: any[]) => {
  return array.reduce((result: { [key: string]: any; }, obj: any) => {
    const value = keys.map((key: string) => obj[key]).join('-');
    result[value] = (result[value] || []).concat(obj);
    return result;
  }, {});
}

/**
 * Guarding function to check if input is a number
 * @param input
 */
export const isNumber = (input: any): input is number => {
  return typeof input === "number";
}

/**
 * Sort an array of object by one of its (given) string properties
 * @param array: an array of objects
 * @param property: a string-property of objects within the array
 */
export const sortByStringParameter = (array: any[], property: string) => {
  return array.sort(function (a, b) {
    let stringA = a[property] && a[property].toUpperCase();
    let stringB = b[property] && b[property].toUpperCase();
    if (stringA < stringB) {
      return -1;
    }
    if (stringA > stringB) {
      return 1;
    }
    return 0;
  });
}

/**
 * Removing the first option from a basket
 */
export const removeFirstOptionFromBasket = (basket: Basket, dispatch: Dispatch<any>) => {
  let entry;
  let option;
  if (typeof basket !== "undefined") {
    entry = basket.entries && basket.entries[0];
  }
  if (typeof entry !== "undefined") {
    option = entry.options && entry.options[0];
  }
  if (typeof basket !== "undefined"
    && typeof entry !== "undefined"
    && typeof option !== "undefined") {
    dispatch(
      apiCallAction(ACTION_CONST.API_OCN_DELETE_BASKET_REMOVE_OPTION, {
        basketId: basket.id,
        theParentUuid: entry.uuid,
        callNumberUuid: option.uuid
      }, true)
    );
  }
}


export const getCurrencySymbol = (currency?: string) => {
  let tempCurrency

  switch (currency) {
    case "EUR":
      tempCurrency = CURRENCY_MAPPING.EUR;
      break;
    case "Złoty":
      tempCurrency = CURRENCY_MAPPING.ZLOTY;
      break;
    case "£":
      tempCurrency = CURRENCY_MAPPING.POUND;
      break;
    default:
      tempCurrency = CURRENCY_MAPPING.EUR;
      break;
  }
  return tempCurrency
}
export const currency = (amount?: number, currency?: string, showZero: boolean = false, placeholder?: string) => {
  let tempCurrency = getCurrencySymbol(currency);


  const tempAmount = amount?.toLocaleString("de-DE", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
  return !!tempAmount
    ? `${tempAmount}\u00A0${tempCurrency}`
    : showZero ? `0,00\u00A0${tempCurrency}` : placeholder;
}

export const amount = (amount?: number) => {


  const tempAmount = amount?.toLocaleString("de-DE", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
  return !!tempAmount
    ? `${tempAmount}\u00A0` :
    `0,00\u00A0`;
}


export const CurrencyWithChargeModeWithAmountNet = (charges: Array<Charge>, parentOptionId: number, translations: any, migrationDiscount: boolean = false) => {


  charges = charges.filter((charge) => {
    if (typeof charge.productParent !== "undefined") {
      return charge?.productParent?.id === parentOptionId && charge.mandatory;
    }
    return charge.mandatory;
  });

  let chargesWithCurrencyAndChargeMode: any = [];

  charges.sort((charge1, charge2) => (charge1?.chargeMode?.id && charge2?.chargeMode?.id) ?  (charge1?.chargeMode?.id - charge2.chargeMode.id) : -1).map((charge) => {
    let currentCurrency = currency((migrationDiscount && charge?.chargeMode?.id === ID_CHARGE_MODES.ONE_TIME) ? 0 : charge?.amountNet ? (charge?.amountNet - (charge.amountNetDiscounts ? charge.amountNetDiscounts : 0)) : undefined , charge?.currency?.description);

    if (charge?.amountNet === 0) return;
    if (charge?.chargeType?.id === ID_CHARGE_TYPES.BASIC_FEE && charge?.chargeMode?.id === ID_CHARGE_MODES.ONE_TIME) return;

    if (charge?.chargeMode?.id === ID_CHARGE_MODES.ONE_TIME) {
      currentCurrency = currentCurrency?.toString() + translations.oneTime();
    } else if (charge?.chargeMode?.id === ID_CHARGE_MODES.MONTHLY) {
      currentCurrency = currentCurrency?.toString() + translations.monthly();
    } else if (charge?.chargeType?.id === ID_CHARGE_TYPES.BASIC_FEE && charge?.chargeMode?.id === ID_CHARGE_MODES.PER_INVOICE) {
      currentCurrency = currentCurrency?.toString() + translations.monthly();
    }
    chargesWithCurrencyAndChargeMode.push(currentCurrency);

  })

  return chargesWithCurrencyAndChargeMode;
}

export const formatDate = (date: Date, selectedLanguage: "DE" | "EN") => {
  let locale = "en-GB";

  switch (selectedLanguage) {
    case "DE":
      locale = "de-DE";
      break;
    default:
      locale = "en-GB";
      break;
  }

  return new Date(date as Date).toLocaleDateString(
    locale,
    {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }
  )

}

export const translateAddressToPortalAddress = (address: Address): AddressPortal => {

  return {
    salutation: address.salutation,
    companyName1: address.companyName || address.firstName,
    companyName2: address.companyName2 || address.lastName,
    country: address?.country,
    street: address?.street || '',
    houseNumber: address.houseNumber,
    id: address.id,
    additionalAddress: address.buildingPart,
    zip: address.zip || '',
    city: address.city,
    city2: address.city2,
    city3: address.city3,
    province: address.district,
    addressType: address.role,
    evidenceProvided: address?.evidenceProvided

  };
}

export const translateCustomerToCustomerPortal = (customer: any): CustomerPortal => {
  return {
    id: customer.id,
    legalForm: { id: 0, description: '' },
    salutation: customer.salutation,
    companyName1: customer?.companyName || customer?.customerName || customer?.firstName,
    companyName2: customer?.companyName2 || customer?.lastName,
    email: customer.mainContact.email.email,
    phone: {
      countryCode: customer.mainContact.phone.countryCode,
      areaCode: customer.mainContact.phone.areaCode,
      cli: customer.mainContact.phone.cli,
      phoneType: customer.mainContact.phone.phoneType
    },
    language: { id: 0, description: '' }
  };
}
