import cs from 'classnames';
import { CpxButton } from '../../../../core/components/button.component';
import { ReactComponent as Search } from '../../../assets/icons/search.svg';
import { useTranslations } from '../../../utils/helper/utils';
import { TRANSLATIONS } from '../../../constants/transitions/uiTranslations';
import { CpxInputWithLabel } from '../../../../core/components/inputWithLabel.component';
import './searchBox.scss';
import useSearch from '../../../utils/helper/useSearch';
import { ReactNode, useEffect } from 'react';
import { IPartnerCustomers } from '../../../constants/types/types.constants';

type Props = {
  theme: string;
  placeholder: string;
  id: string;
  data: IPartnerCustomers | any;
  getFilteredItems: any;
  shouldBeSearched: any;
  children?: ReactNode;
};

export const SearchBox = (props: Props) => {
  const { theme, placeholder, id, data, getFilteredItems, shouldBeSearched } =
    props;
  const translations = useTranslations(TRANSLATIONS.common);
  const attributes = shouldBeSearched;
  const [filteredItems, searchValue, setSearch] = useSearch(data, attributes);

  useEffect(() => {
    getFilteredItems(filteredItems);
  }, [filteredItems]);

  return (
    <div className={cs('filterBox', `filterBox-boxStyling-le--${theme}`)}>
      <div className={cs('titleField', `filterBox-title-le--${theme}`)}>
        <h4 className={cs('titleField', `filterBox-title-le--${theme}`)}>{translations.search()}</h4>
        {/* Sto1107: hidden because of not being implemented yet
        <Filter className={`${id}-search-icon-le--${theme}`}/>
        */}
      </div>
      <div className={'inputField'}>
        <CpxInputWithLabel
          inputClassName={cs('inputField', `inputField-le--${theme}`)}
          id={`${id}SearchInputField`}
          placeholder={placeholder}
          onChange={setSearch}
          value={searchValue}
        />
        <CpxButton className={cs(`iconButton iconButton-le--${theme}`)} id={`${id}SearchButton`}>
          <Search className={`${id}-icon-le--${theme}`}/>
        </CpxButton>
      </div>
      {props.children}
    </div>
  );
};
