import commonStyles from "../../../../../sass/base/common.module.scss";
import cs from "classnames";
import React from "react";
import { useTheme } from "../../../../../../core/utility/themeContext";
import { useTranslations } from "../../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../../constants/transitions/uiTranslations";
import { AdminPortalUserInputGroup } from "../../../../../../core/components/adminPortalUserInputGroup.component";
import { CommercialPortalUserInputGroup } from "../../../../../../core/components/commercialPortalUserInputGroup.component";

export const ContactPersonForm = (
  {
    values, handleChange, touched, errors, isDifferentContactPerson, setIsDifferentContactPerson, salutationOptions, contactLanguageOptions
  }: any) => {
  const theme = useTheme();
  const translation = useTranslations(TRANSLATIONS.stepper.newCustomer);
  const internalClassName = "contactPersonForm"

  return (
    <div className={commonStyles.columnContainer}>
      <div className={commonStyles.column}>
        <div className={cs('step', `step-le--${theme}`)}>
          <h4 className={`step-subtitle-le--${theme}`}>
            {translation.contactPersons()}
            <AdminPortalUserInputGroup values={values} handleChange={handleChange} touched={touched} errors={errors} salutationOptions={salutationOptions} contactLanguageOptions={contactLanguageOptions}/>
            <CommercialPortalUserInputGroup values={values} handleChange={handleChange} touched={touched} errors={errors} isDifferentContactPerson={isDifferentContactPerson} setIsDifferentContactPerson={setIsDifferentContactPerson} salutationOptions={salutationOptions} contactLanguageOptions={contactLanguageOptions}/>
          </h4>
        </div>
      </div>
    </div>
)
}