import cs from "classnames";
import { CpxModal } from "../../../../../../core/components/modal.component";
import React, { useState } from "react";
import { useTheme } from "../../../../../../core/utility/themeContext";
import { useTranslations } from "../../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../../constants/transitions/uiTranslations";
import { MigrationExceptionHint } from "compax-api";
import "./migrationHintModal.scss";
import { useFormik } from "formik";
import { checkRequiredHint, checkServiceId, createValidatorSchema, createValidatorSchemaArray } from "../../../../../utils/validation";
import { CpxFormikForm } from "../../../../../../core/components/formikForm.component";
import { useSelectError } from "../../../../../redux/store";
import { clearError } from "../../../../../redux/actions/error.action";
import { useDispatch } from "react-redux";
import { CpxCheckboxGroup, Option } from "../../../../../../core/components/checkboxGroup.component";
import { useHistory } from "react-router-dom";
import { routes } from "../../../../../constants/routes/routes";
import { deleteStepData } from "../../../../../redux/actions/step.action";

type Props = {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  hints: Array<MigrationExceptionHint>;
  completeAction: any;
}

export const MigrationHintModal = ({ setShowModal, hints, completeAction }: Props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const translation = useTranslations(TRANSLATIONS.stepper.newCustomer);
  const translationsCommon = useTranslations(TRANSLATIONS.common);
  const internalClassName = "textFieldForm";
  const ErrorsOfBE = useSelectError();
  const history = useHistory();
  const [initialDisabledState, setInitialDisabledState] = useState(hints.some(hint => hint.serviceId === undefined));
  const [hintsWithAccept, setHintsWithAccept]  = useState<Array<Option>>(hints.map(hint => {
    return {name: hint.serviceName || '', title: hint.hint || '', checked: false, serviceId: hint.serviceId}
  }));


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      hints: hintsWithAccept
    },
    validationSchema: createValidatorSchema({
      hints: createValidatorSchemaArray({
        checked: checkRequiredHint(),
        serviceId: checkServiceId(),
      })
    }),
    onSubmit: async (values: any) => {
      dispatch(clearError());
      completeAction(values.hints.filter((value:any) => value.serviceId && value.checked).map((value : any) => value.serviceId ), values.hints.some((hint:any) => hint.name === 'forking'))
      setShowModal(false);
    }
  });

  const handleChange = (newOpts: Array<Option>) => {
    setInitialDisabledState(false);
    setHintsWithAccept((oldHints: Option[]) => {
      return oldHints.map(oldHint => newOpts.find(newOpt => newOpt.name === oldHint.name) || oldHint)
    })
    formik.setFieldValue("hints", newOpts)
  }


  return (
    <CpxFormikForm handleSubmit={formik.handleSubmit}
                   initialValues={formik.initialValues}
                   id={'textField-modal-data-form'}>
      <CpxModal
        confirmText={translation.accept()}
        hideCancelButton={true}
        onCustomButtonClick={() => {
          history.push(routes.partnerCustomers.path)
          dispatch(deleteStepData())
        }}
        customButtonText={translation.cancelMigrationOrderEntry()}
        formId={'textField-modal-data-form'}
        disableButton={initialDisabledState || (!!formik.errors.hints && formik.errors.hints?.length && formik.errors.hints.length > 0) || false}
      >
        <h4 className={cs('modalTitle', `modalTitle-le--${theme}`)}>
          {translation.titleHintModal()}
        </h4>
          <>
            <CpxCheckboxGroup
              name="hints"
              options={hintsWithAccept}
              onChange={handleChange}
              className={`${internalClassName}-label-le--${theme} ${internalClassName}-checkbox ${internalClassName}-checkbox-le--${theme}`}
            />
          </>
      </CpxModal>
    </CpxFormikForm>
  )
}
