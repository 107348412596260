import { faker } from '@faker-js/faker';
import { CpxButton } from "../../../../../core/components/button.component";
import { useCurrentStep, useSelectFeatures, useSelectUserLegalEntity, useStep1Data, useStep2Data, useStepperBillingInformationSelection } from "../../../../redux/store";
import './generateFakeData.scss';
import { saveBillingInformationSelection, saveStep1Data, saveStep2Data } from "../../../../redux/actions/step.action";
import { useDispatch } from "react-redux";
import { CLIENTS_IDS, FEATURES_FLAG, ID_ADDRESSES } from "../../../../constants/configs/config.constants";
import { useEffect } from "react";
import { apiCallAction } from "../../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../../constants/action.constants";

type Props = {
  salutationOptions?: any;
  contactLanguageOptions?: any;
  countryOptions?: any;
  setSalutation?: Function;
}

export const NfoGenerateFakeDataComponent = ({ salutationOptions, countryOptions, setSalutation, contactLanguageOptions, ...props }: Props) => {
  const currentStep = useCurrentStep();
  const dataOfStep1 = useStep1Data();
  const dataOfStep2 = useStep2Data();
  const features = useSelectFeatures();
  const billingInformationSelection = useStepperBillingInformationSelection();
  const dispatch = useDispatch();
  const client = useSelectUserLegalEntity();

  const germanZips = ['01159', '01609', '03130', '06862', '92546', '80798', '75433', '60596', '55585', '48499'];

  function getMainAddress() {
    let countryId = countryOptions && countryOptions[faker.datatype.number(20)]?.id;
    let zip = countryId === 1 ? germanZips[faker.datatype.number(germanZips.length)]  : faker.phone.number('#####')

    return {
      country: {
        id: countryId,
      },
      street: faker.address.street(),
      houseNumber: faker.phone.number('###'),
      additionalAddress: faker.datatype.boolean() && faker.address.street(),
      zip: zip,
      county: faker.address.county(),
      city: faker.address.cityName(),
      province: faker.address.county(),
      addressType: {
        id: ID_ADDRESSES.MAIN,
      },
    };
  }

  function getSitesObj() {

    let countryId = countryOptions && countryOptions[faker.datatype.number(20)]?.id;
    let zip = countryId === 1 ? germanZips[faker.datatype.number(germanZips.length)]  : faker.phone.number('#####')

    return {
      country: {
        id: countryId,
      },
      street: faker.address.street(),
      houseNumber: faker.phone.number('###'),
      additionalAddress: faker.datatype.boolean() && faker.address.street(),
      zip: zip,
      county: faker.address.county(),
      city: faker.address.cityName(),
      province: faker.address.county(),
      salutation: {
        id: salutationOptions && salutationOptions[faker.datatype.number(3)]?.id,
      },
      companyName1: faker.datatype.boolean() ? faker.company.name() : faker.name.firstName(),
      companyName2: faker.datatype.boolean() ? faker.company.name() : faker.name.lastName(),
      addressType: {
        id: ID_ADDRESSES.CONNECTION,
      },
    };
  }

  const customerDataObj = {
    /* MainDataForm */
    customer: {
      legalForm: {
        id: faker.datatype.number({min: 1, max: 40}),
      },
      salutation: {
        id: salutationOptions && salutationOptions[faker.datatype.number(3)]?.id,
      },
      language: {
        id: client.id !== CLIENTS_IDS.DTS ? faker.datatype.number({min: 1, max: 6}) : faker.datatype.number({min: 1, max: 1}),
      },
      companyName1: faker.datatype.boolean() ? faker.company.name() : faker.name.firstName(),
      companyName2: faker.datatype.boolean() ? faker.company.name() : faker.name.lastName(),
      taxIdentificationNumber: faker.datatype.boolean() && faker.phone.number('DE#######'),
      email: faker.internet.email(),
      companyNumber: '',
      phone: {
        countryCode: '+49',
        areaCode: faker.phone.number('###'),
        cli: faker.phone.number('######'),
        phoneType: {
          id: 2
        },
      },
    },
    /* RegisteredAddressForm */
    mainAddress: getMainAddress(),
  };

  const contactPersons = [
    {
      salutation: {
        id: salutationOptions && salutationOptions[faker.datatype.number(3)]?.id,
      },
      firstName: faker.name.firstName(),
      lastName: faker.name.lastName(),
      email: faker.internet.email(),
      //login: faker.internet.email(),
      phone: {
        countryCode: '+49',
        areaCode: faker.phone.number('###'),
        cli: faker.phone.number('######'),
        phoneType: {
          id: 2
        },
      },
      mobile: {
        countryCode: '+49',
        areaCode: faker.phone.number('###'),
        cli: faker.phone.number('######'),
        phoneType: {
          id: 2
        },
      },
      language: {
        id: client.id !== CLIENTS_IDS.DTS ? faker.datatype.number({min: 1, max: 6}) : faker.datatype.number({min: 1, max: 1}),
      },
      role: {
        id: '134'
      }
    },
    {
      salutation: {
        id: salutationOptions && salutationOptions[faker.datatype.number(3)]?.id,
      },
      firstName: faker.name.firstName(),
      lastName: faker.name.lastName(),
      email: faker.internet.email(),
      phone: {
        countryCode: '+49',
        areaCode: faker.phone.number('###'),
        cli: faker.phone.number('######'),
        phoneType: {
          id: 2
        },
      },
      mobile: {
        countryCode: '+49',
        areaCode: faker.phone.number('###'),
        cli: faker.phone.number('######'),
        phoneType: {
          id: 2
        },
      },
      language: {
        id: client.id !== CLIENTS_IDS.DTS ? faker.datatype.number({min: 1, max: 6}) : faker.datatype.number({min: 1, max: 1}),
      },
      role: {
        id: '113'
      }
    }
  ];

  const account = {
    accountName: `${faker.name.firstName()} ${faker.name.lastName()}`,
      iban: faker.finance.iban(false, 'DE'),
      bic: faker.finance.bic(),
      paymentMode: {
      id: 1,
    },
    paymentMedia: {
      id: 4,
    },
    billAddress: {
      country: {
        id: countryOptions && countryOptions[faker.datatype.number(20)]?.id,
      },
      street: faker.address.street(),
        houseNumber: faker.phone.number('###'),
        additionalAddress: faker.datatype.boolean() && faker.address.street(),
        zip: faker.phone.number('#####'),
        county: faker.address.county(),
        city: faker.address.cityName(),
        province: faker.address.county(),
        salutation: {
          id: salutationOptions && salutationOptions[faker.datatype.number(3)]?.id,
        },
      companyName1: faker.datatype.boolean() ? faker.company.name() : faker.name.firstName(),
        companyName2: faker.datatype.boolean() ? faker.company.name() : faker.name.lastName(),
        email: faker.datatype.boolean() && faker.internet.email(),
        addressType: {
        id: ID_ADDRESSES.BILLING,
      },
    },
  }



  const sitesObj = getSitesObj();

  useEffect(() => {
    dispatch(apiCallAction(ACTION_CONST.API_PORTAL_FEATURE, { featureName: FEATURES_FLAG.PORTAL_ORDER_ENTRY_GENERATOR }))
  }, [])

  const generateData = () => {
    switch (currentStep) {
      case 1:
        dispatch(saveStep1Data(customerDataObj));
        setSalutation && setSalutation(customerDataObj.customer.salutation.id)
        break;
      case 2:
        dispatch(saveStep1Data({...dataOfStep1, contactPersons}));
        break;
      case 3:
        dispatch(saveStep1Data({...dataOfStep1, account}));
        dispatch(saveBillingInformationSelection({ ...billingInformationSelection, isSEPA: true }))
        break;
      case 4:
        dispatch(saveStep2Data({...dataOfStep2, formData: sitesObj}));
        break;
      default:
        break;
    }
  }

  return (
      <>
        {features.PORTAL_ORDER_ENTRY_GENERATOR && !dataOfStep1?.migrationCustomerId && !dataOfStep1?.partnerVoNumber && (
          <div className={'generate-fake-data'}>
            <CpxButton
              onClick={() => generateData()}
              type='button'
            >
              Generate Fake Data
            </CpxButton>
          </div>
        )}
      </>
  )
}