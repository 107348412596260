import cs from "classnames";
import { CpxModal } from "../../../../../../../core/components/modal.component";
import React, { useEffect, useState } from "react";
import { useTheme } from "../../../../../../../core/utility/themeContext";
import { useTranslations } from "../../../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../../../constants/transitions/uiTranslations";
import { Address, AddressPortal, LookupValue, SelectableBasketOption, TelcoData } from "compax-api";
import { CpxIcon } from "../../../../../../../core/components/icon.component";
import { COUNTRY_IDS, ICONS, ID_SALUTATIONS, PRODUCT_CATEGORY, PRODUCT_CATEGORY_CALL_NUMBER_BLOCKS_MIGRATION, SERVICE_PROPERTIES } from "../../../../../../constants/configs/config.constants";
import { useFormik } from "formik";
import { blockFromCheck, blockToCheck, cliMigrationCheck, createValidatorSchema, createValidatorSchemaArray, extensionNumberCheck, orderMaxLengthNumberCheck, requiredCheck } from "../../../../../../utils/validation";
import { CpxFormikForm } from "../../../../../../../core/components/formikForm.component";
import { useOrderEntryBasket, useSelectCountries, useStep1Data, useStep2Data } from "../../../../../../redux/store";
import "./numbersForm.scss"
import { AreaCodeItem } from "./areaCodeItem.component";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { clearError } from "../../../../../../redux/actions/error.action";
import { AreaCodeError, ProductOptionModalType } from "../../../../../../constants/types/types.constants";
import { apiCallAction } from "../../../../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../../../../constants/action.constants";
import update from "immutability-helper";
import { MigrationPhoneNumberItem } from "./migrationPhoneNumberItem.component";

type Props = {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  option: SelectableBasketOption;
  selectOptionOfOption: Function;
  type?: ProductOptionModalType;
  getBasketDataByProductId?: any;
  edit?: any;
  setEdit?: any;
  onClickSelectBox?: any;
  addNumber?: boolean;
}

type AppDispatch = ThunkDispatch<any, any, AnyAction>;

export const NumbersForm = ({ setShowModal, option, selectOptionOfOption, getBasketDataByProductId, edit, addNumber = false }: Props) => {
  const theme = useTheme();
  const dispatch: AppDispatch = useDispatch();
  const translation = useTranslations(TRANSLATIONS.stepper.newCustomer);
  const translationsCommon = useTranslations(TRANSLATIONS.common);
  const countries = useSelectCountries();
  const { mainAddress, customer } = useStep1Data();
  const dataOfStep2 = useStep2Data();
  const requiredMsg = translation.fieldRequired();
  useOrderEntryBasket();
  useEffect(() => {
    dispatch(clearError());
  }, []);
  const internalClassName = "numbers-modal";

  const getCountryById = (id: number) => {
    return countries?.filter((country: any) => country.id === id)[0]?.description;
  }

  const getAddressById = (id: number) => {
    return locations.filter((location, index) => id === index).map(filteredLocation => filteredLocation)[0];
  }

  const [locations] = useState([{ ...mainAddress, ...customer }, ...dataOfStep2?.siteAddresses]);
  const siteAddressOptions = Array.isArray(locations) ? locations.map((location, index: number) => {

    const fullName = location.companyName2 ? `${location.companyName1} ${location.companyName2}` : `${location.companyName1}`;

    return {
      id: index,
      name: `${fullName}, ${location.street} ${location.houseNumber}, ${location.zip} ${location.city}, ${getCountryById(+location.country.id)}`,
      data: location,
    }

  }) : [];

  const numbersDefaultFormFieldsObject = {
    selectedAddress: {
      id: siteAddressOptions[0]?.id,
      data: getAddressById(1),
    },
    areaCode: '',
    cli: '',
    extensionNumber: '',
    blockFrom: '',
    blockTo: '',
  };

  const findIndexOfLocation = (siteAddress: Address) => {

    return locations.findIndex((location: any) => {

      const { country, zip, city, street, houseNumber, companyName1, companyName2, salutation } = location;

      if(!siteAddress) {
        return -1;
      }


      return (+country.id === siteAddress.country.id
        && zip === siteAddress.zip
        && city === siteAddress.city
        && street === siteAddress.street
        && houseNumber === siteAddress.houseNumber
        && (companyName1 === siteAddress.firstName || companyName1 === siteAddress.companyName)
        && (companyName2 === siteAddress.lastName || companyName2 === siteAddress.companyName2)
        && +salutation.id === siteAddress.salutation?.id);
    });
  }

  const editNumbersObject = getBasketDataByProductId && getBasketDataByProductId(edit?.productId).map((configuration: any) => (
    {
      selectedAddress: {
        id: findIndexOfLocation(configuration.telcoData.siteAddress),
      },
      areaCode: configuration.telcoData?.newPhoneNumberBlock?.areaCode || configuration.telcoData?.newPhoneNumber?.areaCode || configuration.telcoData?.newLnpPhoneNumberBlock?.areaCode,
      cli: configuration.telcoData?.newPhoneNumber?.cli,
      extensionNumber: configuration.telcoData?.newLnpPhoneNumberBlock?.extensionNumber,
      blockFrom: configuration.telcoData?.newLnpPhoneNumberBlock?.blockFrom,
      blockTo: configuration.telcoData?.newLnpPhoneNumberBlock?.blockTo,
      softSwitch: configuration.telcoData?.softSwitch,
      numberProvider: configuration.telcoData?.numberProvider,
      inboundValue: configuration.telcoData?.inboundValue,
      outboundValue: configuration.telcoData?.outboundValue,
    }
  ))

  const [numberConfigurations, setNumberConfigurations] = useState(edit?.isActive ? (addNumber ? [...editNumbersObject, numbersDefaultFormFieldsObject] : editNumbersObject) : [numbersDefaultFormFieldsObject]);
  const [errorsArray, setErrorsArray] = useState<Array<AreaCodeError | false>>([]);
  const [hasBeenAdded, setHasBeenAdded] = useState(false);

  const addBlock = () => {
    formik.setFieldValue(`configurations[${numberConfigurations.length}]`, numbersDefaultFormFieldsObject);
    setNumberConfigurations((prevState: any) => [...prevState, numbersDefaultFormFieldsObject]);
    formik.setTouched({});
  }

  const deleteBlock = (index: number) => {
    const filtered = formik.values.configurations.filter((configuration: any, configurationIndex: number) => configurationIndex !== index)
    formik.setFieldValue(`configurations`, filtered);
    setNumberConfigurations((prevState: any) => prevState.filter((configuration: any, i: number) => i !== index));
  }

  const createCallNumberObject = (areaCode: string, cli: string, extensionNumber: string, blockFrom: string, blockTo: string, inboundValue: boolean, outboundValue: boolean, softSwitch: LookupValue, numberProvider: LookupValue) => {
    const telcoData: TelcoData = {};

    if (option?.product?.productCategory?.id === PRODUCT_CATEGORY.phoneNumber) {
      telcoData.newPhoneNumber = {
        areaCode,
        phoneType: { id: 0 },
      };
    } else if(option?.product?.productCategory?.id === PRODUCT_CATEGORY.phoneNumberBlock) {
      const serviceProperties = JSON.parse(JSON.stringify(option?.serviceProperties));

      telcoData.newPhoneNumberBlock = {
        areaCode,
        blockSize: Number(serviceProperties[SERVICE_PROPERTIES.ANZAHL_RUFNUMMERN]),
        shortenOnConsole: false,
      };
    } else if(option?.product?.productCategory?.id && PRODUCT_CATEGORY_CALL_NUMBER_BLOCKS_MIGRATION.includes(option?.product?.productCategory?.id)) {
      const serviceProperties = JSON.parse(JSON.stringify(option?.serviceProperties));
      const blockSize = serviceProperties[SERVICE_PROPERTIES.ANZAHL_RUFNUMMERN] ? Number(serviceProperties[SERVICE_PROPERTIES.ANZAHL_RUFNUMMERN]) : Number(blockTo) - Number(blockFrom) + 1;

      telcoData.newLnpPhoneNumberBlock = {
        areaCode,
        blockSize: Number(serviceProperties[SERVICE_PROPERTIES.ANZAHL_RUFNUMMERN]),
        extensionNumber,
        blockFrom,
        blockTo,
        numberType: { id: 8 },
        shortenOnConsole: false,
      };
      telcoData.softSwitch = softSwitch;
      telcoData.numberProvider = numberProvider;
      telcoData.inboundValue = inboundValue;
      telcoData.outboundValue = outboundValue;
    } else if(option?.product?.productCategory?.id === PRODUCT_CATEGORY.phoneNumberMigration) {
      telcoData.newPhoneNumber = {
        areaCode,
        phoneType: { id: 0 },
        cli
      };
      telcoData.softSwitch = softSwitch;
      telcoData.numberProvider = numberProvider;
      telcoData.inboundValue = inboundValue;
      telcoData.outboundValue = outboundValue;
    }

    return telcoData;
  }

  const createAddress = (selectedAddress: AddressPortal) => {

    let company = selectedAddress.salutation?.id && (parseInt(String(selectedAddress.salutation.id)) === ID_SALUTATIONS.COMPANY);

    let theAddress: any = {
      salutation:  selectedAddress.salutation,
      companyName: company ? selectedAddress.companyName1 : null,
      companyName2: company ? selectedAddress.companyName2 : null,
      firstName: !company ? selectedAddress.companyName1 : null,
      lastName: !company ? selectedAddress.companyName2 : null,
      country : selectedAddress.country,
      street : selectedAddress.street,
      houseNumber : selectedAddress.houseNumber,
      zip : selectedAddress.zip,
      city : selectedAddress.city,
      city2: selectedAddress.city2,
      city3: selectedAddress.city3,
      district: selectedAddress.district ? selectedAddress.district : selectedAddress.province ? selectedAddress.province : selectedAddress.county,
      buildingPart : selectedAddress.additionalAddress
    };

    return theAddress;
  }

  const initErrorArray = () => {
    setErrorsArray([]);
    numberConfigurations.forEach(() => {
      setErrorsArray((prevState: any) => [...prevState, false])
    })
  }

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      configurations: numberConfigurations.map((configuration: any) => configuration)
    },
    validationSchema: createValidatorSchema({
      configurations: createValidatorSchemaArray(
        {
          selectedAddress: createValidatorSchema({
            id: requiredCheck(requiredMsg),
          }),
          areaCode: orderMaxLengthNumberCheck(requiredMsg, translation.invalidAreaCodeValidation(), 10),
          cli: option?.product?.productCategory?.id && PRODUCT_CATEGORY_CALL_NUMBER_BLOCKS_MIGRATION.includes(option?.product?.productCategory?.id) && cliMigrationCheck(requiredMsg, translation.numberError(translation.phoneNumber()), option),
          extensionNumber: option?.product?.productCategory?.id && PRODUCT_CATEGORY_CALL_NUMBER_BLOCKS_MIGRATION.includes(option?.product?.productCategory?.id) && extensionNumberCheck(requiredMsg, translation.numberError(translation.callNumberExtension()), option),
          blockFrom: option?.product?.productCategory?.id && PRODUCT_CATEGORY_CALL_NUMBER_BLOCKS_MIGRATION.includes(option?.product?.productCategory?.id) && blockFromCheck(requiredMsg, translation.blockFromError),
          blockTo: option?.product?.productCategory?.id && PRODUCT_CATEGORY_CALL_NUMBER_BLOCKS_MIGRATION.includes(option?.product?.productCategory?.id) && blockToCheck(requiredMsg, translation.blockToError(),  translation.blockSizeError, option),
        }
      )
    }),
    onSubmit: async ({ configurations }: any) => {

      const callNumberObjectsArray: Array<TelcoData> = [];

      configurations.map((configuration: any) => {
        const numberConfigObject: TelcoData = createCallNumberObject(configuration.areaCode, configuration.cli, configuration.extensionNumber, configuration.blockFrom, configuration.blockTo, configuration.inboundValue, configuration.outboundValue, configuration.softSwitch, configuration.numberProvider);
        numberConfigObject.siteAddress = createAddress(getAddressById(+configuration.selectedAddress.id));

        callNumberObjectsArray.push(numberConfigObject);
      })

      if (errorsArray.filter((item): boolean => typeof item === 'object').length === 0) {

        if (edit?.isActive) {
          selectOptionOfOption(option, ProductOptionModalType.EDIT_TELCO_DATA, callNumberObjectsArray)
            .then(() => {
              dispatch(clearError());
              setShowModal(false);
            });
        } else {
          selectOptionOfOption(option, ProductOptionModalType.TELCO_DATA, callNumberObjectsArray);
          dispatch(clearError());
        }
      }
    }
  });

  const handleAreaCodeValidation = () => {
    initErrorArray();

    Promise.all(formik.values.configurations.map((configuration: any, index: number) => validateNumbers(configuration, index))).then((data) => {
      setHasBeenAdded(true);
      formik.submitForm();
    })
  }

  const validateNumbers = (configuration:any, index: number) =>  {
    return new Promise<string>((resolve, reject) => {

      // areaCode Validation
      if (configuration.areaCode !== "" && +getAddressById(+configuration.selectedAddress.id).country.id === COUNTRY_IDS.DE) {
        dispatch(apiCallAction(ACTION_CONST.API_VALIDATE_AREA_CODE, {
          addressZip: getAddressById(+configuration.selectedAddress.id).zip + '',
          areaCode: configuration.areaCode,
        })).then(() => {
          resolve("done");
        })
          .catch((error) => {
            // updating errorsArray
            setErrorsArray((prevState) => update(prevState, {
              $splice: [[index, 1, { index: index, message: error }]]
            }));
            resolve("reject");
          })
      }else {
        resolve("nothing");
      }
    })
  }

  useEffect(() => {
    initErrorArray();
    setHasBeenAdded(false);
  }, [formik.values])

  return (
    <CpxFormikForm handleSubmit={handleAreaCodeValidation}
                   initialValues={formik.initialValues}
                   id={'numbers-modal-data-form'}>‚‚‚‚‚‚
      <CpxModal
        onConfirm={handleAreaCodeValidation}
        confirmText={edit?.isActive ? translationsCommon.confirm() : translationsCommon.add()}
        onCancel={() => setShowModal(false)}
        onCustomButtonClick={() => addBlock()}
        disableButton={hasBeenAdded}
        customButtonText={
          <div id={'add-block-wrapper'}>
            <CpxIcon icon={ICONS.ADD}/>
            {translation.addBlock()}
          </div>
        }
      >
        <h3 className={cs('modalTitle', `modalTitle-le--${theme}`)}>
          {option.product.displayValue}
        </h3>
        <p>
          {translation.callNumberBlockModalSubTitle()}
        </p>
        {
          numberConfigurations.map((item: any, index: number) => (
            <div className={`${internalClassName}-container`}>
              <AreaCodeItem key={index} formik={formik} siteAddressOptions={siteAddressOptions} index={index} deleteBlock={deleteBlock} errorsArray={errorsArray}/>
              <MigrationPhoneNumberItem option={option} formik={formik} index={index}/>
            </div>
          ))
        }
      </CpxModal>
    </CpxFormikForm>
  )
}
