import { TRANSLATIONS } from "../../../constants/transitions/uiTranslations";
import { useTranslations } from "../../../utils/helper/utils";
import { CpxTitle } from "../../../../core/components/title/title.component";
import "./quoteDetail.scss";
import React, { useEffect, useState } from "react";
import { CpxSubtitle } from "../../../../core/components/title/subtitle.component";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import cs from "classnames";
import { useSelectedQuote, useSelectError } from "../../../redux/store";
import { QuoteItem, QuoteItemCharge } from "compax-api";
import { useTheme } from "../../../../core/utility/themeContext";
import { CpxInputWithLabel } from "../../../../core/components/inputWithLabel.component";
import { CpxIcon } from "../../../../core/components/icon.component";
import { ICONS, ID_STATUS_QUOTES } from "../../../constants/configs/config.constants";
import { CpxButton } from "../../../../core/components/button.component";
import { apiCallAction } from "../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../constants/action.constants";
import { saveOverridePrice } from "../../../utils/helper/requestBodyHelper";
import { Alert } from "../../../components/common/Alert/Alert.component";
import { clearError } from "../../../redux/actions/error.action";
import { amount } from "../../../../core/utils";

type AppDispatch = ThunkDispatch<any, any, AnyAction>;

export const NfoQuoteDetailPage = () => {
  const dispatch: AppDispatch = useDispatch();
  const translations = useTranslations(TRANSLATIONS.createQuote);
  const internalClassName = "quoteDetail";
  const theme = useTheme();
  const selectedQuote = useSelectedQuote();
  const ErrorsOfBE = useSelectError();


  const getAllCharges = (item: any) => {
    const optionCharges = item.options.flatMap((option:any) => getAllCharges(option)) || []
    return [...item.charges, ...optionCharges ]
  }

  const [editCharges, setEditCharges] =
    useState(new Map<number, boolean>(selectedQuote?.segments?.flatMap(segment => segment.items?.flatMap(item => getAllCharges(item))).map(charge => [Number(charge.id), false])));
  const [editedChargesValue, setEditedChargesValue] =
    useState(new Map<number, number>(selectedQuote?.segments?.flatMap(segment => segment.items?.flatMap(item => getAllCharges(item))).map(charge => [Number(charge.id), charge.amountNetOverride !== undefined ? charge.amountNetOverride : charge?.amountNet])));
  const getOneTimeCharge = (item: QuoteItem): QuoteItemCharge | undefined => {
    return item.charges && item.charges.find(charge => charge.charge?.chargeMode?.id === 1)
  }

  const getMonthlyCharge = (item: QuoteItem): QuoteItemCharge | undefined => {
    return item.charges && item.charges.find(charge => charge.charge?.chargeMode?.id === 2 || charge.charge?.chargeMode?.id === 4)
  }

  const updateChargeEdit = (key:number, value:boolean) => {
    setEditCharges((prevMap) => {
      const tmpChargeNotes = new Map(prevMap);
      tmpChargeNotes.set(key, value);
      return tmpChargeNotes;
    })
  }

  const updateChargesValue = (key:number, value:number) => {
    setEditedChargesValue((prevMap) => {
      const tmpChargesValue = new Map(prevMap);
      tmpChargesValue.set(key, value);
      return tmpChargesValue;
    })
  }

  useEffect(() => {
    dispatch(apiCallAction(ACTION_CONST.API_GET_PARTNER_QUOTE, {quoteId: selectedQuote?.id}))
  }, []);

  const saveValue = (key: number | undefined) => {
    dispatch(clearError())
    if(key !== undefined){
      dispatch(apiCallAction(ACTION_CONST.API_POST_QUOTE_CHARGE, { ...saveOverridePrice(editedChargesValue.get(Number(key))), quoteId: selectedQuote?.id, chargeId: key }))
        .then(() => {
          dispatch(apiCallAction(ACTION_CONST.API_GET_PARTNER_QUOTE, {quoteId: selectedQuote?.id})).then((res) => {
            updateChargeEdit(Number(key), false);
          })
        }).catch((e) => {

      })
    }
  }

  const renderChargeTableColumns = (charge: QuoteItemCharge | undefined) => {
    return (<>
        {charge ?
        editCharges.get(Number(charge?.id)) ?
          <td>
            <div className={cs(`${internalClassName}-charge-edit`)}>
              <CpxInputWithLabel
                id={"contractNotes"}
                name={"contractNotes"}
                value={editedChargesValue.get(Number(charge.id))}
                onChange={(event: any) => updateChargesValue(Number(charge?.id), event.target.value)}
                type="number"
                step="0.01"
                autoFocus
                onKeyDown={e => e.key === 'Enter' && saveValue(charge?.id)}
              />
              <CpxButton className={'iconOnly'} type={'button'} onClick={(event) =>  saveValue(charge?.id)}><CpxIcon icon={ICONS.CHECK}/></CpxButton>
              <CpxButton className={cs('iconOnly', `${internalClassName}-cross-btn ${internalClassName}-cross-btn-le--${theme}`)} type={'button'} onClick={() => updateChargeEdit(Number(charge?.id), false)}><CpxIcon icon={ICONS.CROSS}/></CpxButton>
            </div>
          </td> :
          <td>{amount(charge?.amountNetOverride !== undefined ? charge.amountNetOverride : charge?.amountNet)}{selectedQuote?.status?.id === ID_STATUS_QUOTES.NEW && <CpxButton className={"iconOnly"} type={'button'} onClick={() => updateChargeEdit(Number(charge?.id), true)}><CpxIcon icon={ICONS.EDIT}/></CpxButton>}</td> :
        <td/>}
        <td>{charge?.discount ? `${charge?.discount}%` : ''}</td>
      </>
    )
  }

  const renderQuoteHierarchy = (item: any, level: number) => {
    const oneTimeCharge = getOneTimeCharge(item);
    const monthlyCharge = getMonthlyCharge(item);

    return (
      <>
        <tr className={cs(
          `paginatedTable-firstRow-le--${theme}`,
          `paginatedTable-row-le--${theme}`)}>
          <td className={cs(`hierarchy-level-${level}`)}>{item.product?.displayValue}</td>
          <td>{item.quantity}</td>
          {renderChargeTableColumns(oneTimeCharge)}
          {renderChargeTableColumns(monthlyCharge)}

        </tr>
        {item?.options && item?.options.map((option: any) => renderQuoteHierarchy(option, level + 1))}
      </>

    )

  }


  return (
    <div className={internalClassName}>
      <div className={cs('quotesTitle')}>
        <CpxTitle
          title={selectedQuote?.name || translations.addQuote()}
          internalClassName={internalClassName}
        />
      </div>
      <div className={'subTitleBox'}>
        <CpxSubtitle
          subtitle={translations.subheading()}
          internalClassName={'internalClassName'}
        />
      </div>
      {ErrorsOfBE?.errorData && ErrorsOfBE?.errorData?.length > 0 && <Alert errors={ErrorsOfBE?.errorData}/>}

      {selectedQuote?.segments && selectedQuote?.segments.filter(segment => segment.items && segment.items.length > 0).map(segment =>
        (
          <article className={'article-box'}>
            <table className={'paginatedTable'}>
              <thead className={`paginatedTable-tableHeader-le--${theme}`}>
              <tr>
                <th className="product-name">{translations.product()}</th>
                <th className="product-quantity">{translations.quantity()}</th>
                <th className="product-price-onetime">{translations.oneTimeCharge()}</th>
                <th>{translations.discount()}</th>
                <th className="product-price-monthly">{translations.monthlyCharge()}</th>
                <th>{translations.discount()}</th>
              </tr>
              </thead>
              <tbody className={`paginatedTable-body-le--${theme}`}>
              {segment.items && segment.items.map(item => {
                return renderQuoteHierarchy(item, 0);
              })}
              </tbody>

            </table>
          </article>

        )
      )}

    </div>
  );
};
