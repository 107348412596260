import React, { ForwardedRef, forwardRef, PropsWithoutRef, ReactNode, useEffect } from 'react';
import './addressInputGroup.scss';
import { useTranslations } from "../../portal/utils/helper/utils";
import { TRANSLATIONS } from "../../portal/constants/transitions/uiTranslations";
import { CpxDropdownWithLabel } from "./dropdown.component";
import { CpxInputWithLabel } from "./inputWithLabel.component";
import { useSelectLang } from "../../portal/redux/store";
import { selectCurrentLanguage } from "../uiLanguage/lang.slice";
import { apiCallAction } from "../../portal/redux/actions/apiCall.action";
import { ACTION_CONST } from "../../portal/constants/action.constants";
import { useDispatch } from "react-redux";
import { CpxHelpBox } from "./helpBox.component";
import { PopupDirections, SelectOption } from "../../portal/constants/types/types.constants";
import { COUNTRY_IDS } from "../../portal/constants/configs/config.constants";
import cs from "classnames";

type Props = PropsWithoutRef<JSX.IntrinsicElements['input']> & {
  values: any;
  inputNames: any;
  countryOptions: any;
  handleChange: any;
  touched: any;
  errors: any;
  children?: ReactNode;
  labelClassName?: string;
  inputClassName?: string;
  required?: boolean;
  onPaste?: any;
  onGetPhoneNumber?: any;
  popupDirection?: PopupDirections;
  selectedCountry: number;
  setSelectedCountry: React.Dispatch<React.SetStateAction<number>>;
};

export const CpxAddressInputGroup = forwardRef(
  (
    {
      values,
      inputNames,
      countryOptions,
      handleChange,
      touched,
      errors,
      children,
      required,
      labelClassName,
      inputClassName,
      className,
      onPaste,
      popupDirection,
      selectedCountry,
      setSelectedCountry,
      ...props
    }: Props,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const dispatch = useDispatch();
    const translation = useTranslations(TRANSLATIONS.stepper.newCustomer);
    const translationsCommon = useTranslations(TRANSLATIONS.common);
    const currentLang = selectCurrentLanguage(useSelectLang());


    useEffect(() => {
      dispatch(apiCallAction(ACTION_CONST.API_GET_COUNTRY));
    }, [currentLang]);

    const DEFAULT_SELECT_OPTION: SelectOption = {
      id: '',
      name: translationsCommon.defaultSelect(),
    }

    const handleCountryDropdownChange = (e: any) => {
      handleChange(e);
      setSelectedCountry(+e.target.value);
    }

    return (
      <>
        <div className={'addressInputGroup-container'}>
          <CpxDropdownWithLabel
            id={inputNames.country}
            name={inputNames.country}
            required={true}
            defaultOption={DEFAULT_SELECT_OPTION}
            options={countryOptions}
            value={values?.country?.id}
            onChange={(e: any) => handleCountryDropdownChange(e)}
            error={errors?.country?.id}
          >
            {translation.country()}
          </CpxDropdownWithLabel>

          {selectedCountry === COUNTRY_IDS.GB ? (<>
            <CpxInputWithLabel
              id={inputNames.houseNumber}
              type="text"
              required={selectedCountry === COUNTRY_IDS.GB}
              inputClassName={'input-block'}
              labelClassName={selectedCountry === COUNTRY_IDS.DE ? "label-houseNumber": ""}
              value={values?.houseNumber.trimStart()}
              onChange={handleChange}
              error={touched?.houseNumber && errors?.houseNumber}
            >
              {translation.houseNumberOrName()}
            </CpxInputWithLabel>
            <CpxInputWithLabel
              id={inputNames.street}
              type="text"
              required={true}
              inputClassName={'input-block'}
              value={values?.street.trimStart()}
              onChange={handleChange}
              error={touched?.street && errors?.street}
            >
              {translation.street()}
            </CpxInputWithLabel>
          </>) : (<div className={'addressInputGroup'}>
            <CpxInputWithLabel
              id={inputNames.street}
              type="text"
              required={true}
              labelClassName={cs("label-helpBox", selectedCountry === COUNTRY_IDS.DE ? "label-houseNumber" : "")}
              value={values?.street.trimStart()}
              onChange={handleChange}
              error={touched?.street && errors?.street}
            >
              <div className={'input-helpBox'}>
                {selectedCountry === COUNTRY_IDS.DE && <CpxHelpBox className={"helpBox"} popupDirection={popupDirection}>
                  {translation.streetInfoBoxText()}
                </CpxHelpBox>}
                {translation.street()}
              </div>
            </CpxInputWithLabel>
            <CpxInputWithLabel
              id={inputNames.houseNumber}
              type="text"
              required={(selectedCountry === COUNTRY_IDS.DE ||
                selectedCountry === COUNTRY_IDS.AT ||
                selectedCountry === COUNTRY_IDS.FR ||
                selectedCountry === COUNTRY_IDS.IT ||
                selectedCountry === COUNTRY_IDS.PL ||
                selectedCountry === COUNTRY_IDS.CH ||
                selectedCountry === COUNTRY_IDS.ES ||
                selectedCountry === COUNTRY_IDS.GB)}
              inputClassName={selectedCountry === COUNTRY_IDS.GB ? "input-block" : "housenumber"}
              labelClassName={selectedCountry === COUNTRY_IDS.DE ? "label-houseNumber" : ""}
              value={values?.houseNumber.trimStart()}
              onChange={handleChange}
              error={touched?.houseNumber && errors?.houseNumber}
            >
              {translation.houseNumber()}
            </CpxInputWithLabel>
          </div>)}
          {(selectedCountry !== COUNTRY_IDS.FR &&
            selectedCountry !== COUNTRY_IDS.GB &&
            selectedCountry !== COUNTRY_IDS.IT &&
            selectedCountry !== COUNTRY_IDS.PL &&
            selectedCountry !== COUNTRY_IDS.CH &&
            selectedCountry !== COUNTRY_IDS.AT) && <CpxInputWithLabel
              id={inputNames.additionalAddress}
              type="text"
              value={values?.additionalAddress.trimStart()}
              onChange={handleChange}
              error={touched?.additionalAddress && errors?.additionalAddress}
          >
            {translation.additionalAddress()}
          </CpxInputWithLabel>}
          <div className={'addressInputGroup'}>
            <div className={'addressInputGroup'}>
              <CpxInputWithLabel
                id={inputNames.zip}
                type="text"
                required={true}
                inputClassName={"postcode"}
                value={values?.zip.trimStart()}
                onChange={handleChange}
                error={touched?.zip && errors?.zip}
              >
                {translation.postcode()}
              </CpxInputWithLabel>
              <CpxInputWithLabel
                id={inputNames.city}
                type="text"
                required={true}
                inputClassName={"city"}
                value={values?.city.trimStart()}
                onChange={handleChange}
                error={touched?.city && errors?.city}
              >
                {translation.city()}
              </CpxInputWithLabel>
            </div>
          </div>

          {selectedCountry === COUNTRY_IDS.PL && <>
            <div className={'addressInputGroup'}>
              <div className={'addressInputGroup'}>
                <CpxInputWithLabel
                  id={inputNames.city2}
                  type="text"
                  required={true}
                  inputClassName={"city2"}
                  value={values?.city2.trimStart()}
                  onChange={handleChange}
                  error={touched?.city2 && errors?.city2}
                >
                  {translation.city2()}
                </CpxInputWithLabel>

                <CpxInputWithLabel
                  id={inputNames.district}
                  type="text"
                  required={true}
                  inputClassName={"district"}
                  value={values?.district.trimStart()}
                  onChange={handleChange}
                  error={touched?.district && errors?.district}
                >
                  {translation.district()}
                </CpxInputWithLabel>

              </div>
            </div>
            <CpxInputWithLabel
              id={inputNames.city3}
              type="text"
              required={true}
              inputClassName={"city3"}
              value={values?.city3.trimStart()}
              onChange={handleChange}
              error={touched?.city3 && errors?.city3}
            >
              {translation.city3()}
            </CpxInputWithLabel>
            </>
          }

          {(selectedCountry !== COUNTRY_IDS.FR &&
            selectedCountry !== COUNTRY_IDS.IT &&
            selectedCountry !== COUNTRY_IDS.PL &&
            selectedCountry !== COUNTRY_IDS.CH &&
            selectedCountry !== COUNTRY_IDS.ES &&
            selectedCountry !== COUNTRY_IDS.AT) && <CpxInputWithLabel
              id={inputNames.county}
              type="text"
              value={values?.county.trimStart()}
              onChange={handleChange}
              error={touched?.county && errors?.county}
          >
            {translation.county()}
          </CpxInputWithLabel>}
          {selectedCountry === COUNTRY_IDS.ES && <CpxInputWithLabel
            id={inputNames.province}
            type="text"
            required={selectedCountry === COUNTRY_IDS.ES}
            value={values?.province.trimStart()}
            onChange={handleChange}
            error={touched?.province && errors?.province}
          >
            {translation.province()}
          </CpxInputWithLabel>}
        </div>
      </>
    )
  }
);
