import { ReactComponentElement, ReactNode } from 'react';
import { TRANSLATIONS } from '../../portal/constants/transitions/uiTranslations';
import { useTranslations } from '../../portal/utils/helper/utils';
import { useTheme } from '../utility/themeContext';
import { CpxButton } from './button.component';
import './modal.scss';
import { ReactComponent as Close } from '../../portal/assets/icons/close.svg';
import cs from 'classnames';

type Props = {
  className?: any;
  children: ReactNode;
  onConfirm?: any;
  onCancel?: any;
  onBack?: any;
  onCustomButtonClick?: () => void;
  customButtonText?: string | ReactComponentElement<any>;
  disableButton?: boolean;
  bottomText?: string;
  confirmText?: string;
  cancelText?: string;
  formId?: string;
  size?: "large";
  onConfirmFlag?: boolean;
  hideConfirmButton?: boolean;
  hideCancelButton?: boolean;
};

export const CpxModal = ({
                           className,
                           children,
                           onConfirm,
                           onCancel,
                           onBack,
                           onCustomButtonClick,
                           customButtonText,
                           disableButton,
                           bottomText,
                           confirmText,
                           cancelText,
                           formId,
                           size,
                           onConfirmFlag = true,
                           hideConfirmButton = false,
                           hideCancelButton = false,
                         }: Props) => {
  const translations = useTranslations(TRANSLATIONS.common);
  const theme = useTheme();
  const internalClassname = 'modal';

  const backButtonClass = (onBack || onCustomButtonClick) ? 'threeButton' : 'twoButton';

  return (
    <>
      <div className={'backdrop'} onClick={onCancel}/>
      <div
        className={cs(
          internalClassname,
          `${internalClassname}-dialog`,
          `${internalClassname}-dialog-le--${theme}`,
          className,
          `${internalClassname}-${size}`,
        )}
        onClick={e => e.stopPropagation()}
      >
        {onCancel && (
          <button
            className={'close'}
            type={'button'}
            onClick={onCancel}
            aria-label="Close dialog"
          >
            <Close className={`close-le--${theme}`}/>
          </button>
        )}
        {children}
        {bottomText && (
          <div className={'bottomText'}>{bottomText}</div>
        )}
        <div className={backButtonClass}>
          {onBack && (
            <CpxButton
              id={'backButton'}
              type={'button'}
              onClick={onBack}
              buttonType="secondary"
              aria-label="back dialog"
              className={'boldFont'}
            >
              {translations.back()}
            </CpxButton>
          )}
          {onCustomButtonClick && (
            <CpxButton
              type={'button'}
              onClick={onCustomButtonClick}
              buttonType="secondary"
              aria-label="dialog"
              className={'customButton boldFont'}
            >
              {customButtonText}
            </CpxButton>
          )}
          <div>
            {!hideCancelButton && onCancel && (
              <CpxButton
                id={'cancelButton'}
                onClick={onCancel}
                type={'button'}
                aria-label="Close dialog"
                buttonType={'secondary'}
              >
                {cancelText ? cancelText : translations.cancel()}
              </CpxButton>
            )}
            {!hideConfirmButton && onConfirmFlag && (<CpxButton
              id={'confirmButton'}
              type={formId ? 'submit' : 'button'}
              form={formId}
              onClick={onConfirm}
              disabled={disableButton}
            >
              {confirmText ? confirmText : translations.confirm()}
            </CpxButton>)}
          </div>
        </div>
      </div>
    </>
  );
};
